import Table from "../components/table";
import { useNavigate } from "react-router-dom";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";

function SettingsTableCard({ settings, onClickEdit, onClickDelete }) {
  const navigate = useNavigate();

  const tableHeaders = ["key", "Value", "Action"];

  const records = settings.map((setting) => ({
    key: setting?.key,
    value: setting?.value,
    actions: (
      <div className="flex items-center gap-2">
        <MdOutlineEdit
          onClick={() => onClickEdit(setting.id)}
          className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
        />
        <MdOutlineDelete
          onClick={() => onClickDelete(setting.id)}
          className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
        />
      </div>
    ),
  }));

  return (
    <Table
      tableData={{
        headers: tableHeaders,
        records,
      }}
    />
  );
}

export default SettingsTableCard;
