import { getStates } from "actions/regions/regions.actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Pagination from "views/admin/components/pagination";
import CountryStatesTableCard from "./states-table-card";

const CountryStates = () => {
  const { countryCode } = useParams();
  const dispatch = useDispatch();
  const { states, statesCount } = useSelector((state) => state.region);

  useEffect(() => {
    dispatch(getStates(countryCode));
  }, []);

  return (
    <div className="h-full w-full">
      <CountryStatesTableCard states={states} />
    </div>
  );
};

export default CountryStates;
