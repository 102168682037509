import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

// Brand Devices
const getDevices = (page = 1, limit = 50) => {
  return RestClient.Get(`${API_URL.GET_DEVICES}?page=${page}&limit=${limit}`);
};

const getDevice = (deviceId) => {
  return RestClient.Get(`${API_URL.GET_DEVICE}/${deviceId}`);
};

const createDevice = (payload) => {
  return RestClient.Post(API_URL.CREATE_DEVICE, payload);
};

const updateDevice = (deviceId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_DEVICE}/${deviceId}`, payload);
};

const updateDeviceEnableFlag = (deviceId) => {
  return RestClient.Put(`${API_URL.UPDATE_DEVICE_ENABLE_FLAG}/${deviceId}`);
};

const updateDeviceIntegrationFlag = (deviceId) => {
  return RestClient.Put(
    `${API_URL.UPDATE_DEVICE_INTEGRATION_FLAG}/${deviceId}`
  );
};

const updateDevicePin = (deviceId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_DEVICE_PIN}/${deviceId}`, payload);
};

const deleteDevice = (deviceId) => {
  return RestClient.Delete(`${API_URL.DELETE_DEVICE}/${deviceId}`);
};

const exportedObject = {
  getDevices,
  getDevice,
  createDevice,
  updateDevice,
  updateDeviceEnableFlag,
  updateDeviceIntegrationFlag,
  updateDevicePin,
  deleteDevice,
};

export default exportedObject;
