import React, { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import ConfirmationModal from "../components/ConfirmationModal";
import Card from "components/card";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { deleteCurrency } from "actions/currency/currency.action";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";

const CurrencyTableCard = ({ currencies }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [currencyData, setCurrencyData] = useState({});

  const onClickDelete = (currency) => {
    setCurrencyData(currency);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteCurrency(currencyData.id));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
    setCurrencyData(null);
  };

  const tableHeaders = ["Name", "Code", "Point Rate", "Created By", "Actions"];
  const records = currencies.map((currency) => ({
    name: currency.name,
    code: currency.code,
    rate: currency.point_rate,
    created_by: currency?.created_by?.name ?? "",
    actions: (
      <div className="flex items-center gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.CURRENCY_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => navigate(`/currency/update/${currency.id}`)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.CURRENCY_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
            onClick={() => onClickDelete(currency)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));

  return (
    <div>
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the Currency with name "
              {currencyData.name}"
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={" w-full bg-white"}>
        <CardHeader
          label={"Currencies"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.CURRENCY_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                  {
                    text: "Add New",
                    onClick: () => navigate("/currency/create"),
                  variant: "primary",
                  },
                ]
              : null
          }
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
};

export default CurrencyTableCard;
