import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdFileUpload } from "react-icons/md";

const FormFileUploader = ({ image, handleUpload, size }) => {
  const [error, setError] = useState("");
  const onDrop = (acceptedFiles) => {
    setError("");
    if (!size?.width || !size?.height) return handleUpload(acceptedFiles);

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width === size.width && img.height === size.height) {
          handleUpload(acceptedFiles);
        } else {
          setError(`size must be ${size.width}x${size.height} pixels.`);
        }
      };
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: false,
    accept: {
      "image/jpeg": [".jpeg", ".png"],
    },
  });

  return (
    <div className="h-full w-full">
      <div
        {...getRootProps()}
        className="border-1 flex h-24 w-full cursor-pointer items-center justify-between rounded-xl border border-dashed border-green-400  p-2 hover:border-green-500"
      >
        <input {...getInputProps()} />
        <div className="flex justify-center">
          {image ? (
            <img
              className="h-20 w-20 rounded-lg object-cover"
              src={
                typeof image == "string"
                  ? image
                  : image instanceof File
                  ? URL.createObjectURL(image)
                  : ""
              }
              alt=""
            />
          ) : (
            <MdFileUpload className="mx-auto block h-16 w-16 max-w-[100px]" />
          )}
        </div>
        <div className="flex flex-auto flex-col items-center justify-center lg:flex-row lg:gap-4">
          <p className="whitespace-wrap text-center text-sm text-gray-900">
            Drag or click to upload{" "}
            {size ? `(${size.width}x${size.height})` : ""}
          </p>
          <p className="text-center text-xs text-gray-700">
            Formats .jpg and .png
          </p>
          {error ? (
            <p className="py-1 text-center text-xs text-red-600">{error}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FormFileUploader;
