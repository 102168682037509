import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FORM_TYPE } from "constants/app.constants";
import StoreLoyaltyForm from "./storeLoyaltyForm";
import { createStoreLoyalty } from "actions/storeLoyalties/storeLoyalties.action";

const CreateStoreLoyalty = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="h-full w-full">
      <StoreLoyaltyForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createStoreLoyalty(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateStoreLoyalty;
