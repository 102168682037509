import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../../actions/actionTypes";
import FaqsService from "../../services/faqs/FaqsService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_FAQ_LOADING,
    payload: loading,
  });
};

export const setFaqs = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_FAQS,
    payload: data,
  });
};

export const setFaq = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_FAQ,
    payload: data,
  });
};

export const editFaq = (slider) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_FAQ,
    payload: slider,
  });
};

export const removeFaq = (faqId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_FAQ,
    payload: faqId,
  });
};

export const resetFaqState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_FAQ_STATE,
  });
};

export const getFaqs = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await FaqsService.getFaqs(page, limit);
    if (response.data && response.status == 1) {
      dispatch(setFaqs(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load FAQs", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getFaq = (faqId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await FaqsService.getFaq(faqId);
    if (response.status == 1) {
      dispatch(setFaq(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load FAQ", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createFaq = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await FaqsService.createFaq(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "FAQ created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/faqs");
    }
    dispatch(
      setAlert(response.message ?? "failed to create FAQ", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateFaq = (faqId, payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await FaqsService.updateFaq(faqId, payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "FAQ updated successfully",
          AlertTypes.SUCCESS
        )
      );
      // dispatch(editSlider(response.data.updatedLang));
      if (!navigate) return;
      return navigate("/faqs");
    }
    dispatch(
      setAlert(response.message ?? "failed to update FAQ", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateMultiLangFaq =
  (faqId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await FaqsService.updateMultiLangFaq(faqId, payload);
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "FAQ localizationupdated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/faqs");
      }
      dispatch(
        setAlert(response.message ?? "failed to update FAQ", AlertTypes.ERROR)
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteFaq = (faqId, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await FaqsService.deleteFaq(faqId);
    if (response.status == 1) {
      dispatch(removeFaq(faqId));
      dispatch(
        setAlert(
          response.message ?? "FAQ deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/faqs");
    }
    dispatch(
      setAlert(response.message ?? "failed to delete FAQ", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
