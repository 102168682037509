import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const ConfirmationModal = (props) => {
  const { onBack, onConfirm, children, loading, confirmBtnText, confirmBtnType, customContainerCSS } = props;

  return (
    <Transition.Root show as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open
        onClose={onBack}
      >
        <div className="mt-48 flex min-h-screen items-start justify-center px-4 pb-20 pt-2 text-center sm:mt-0 sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`${
                customContainerCSS?.length
                  ? customContainerCSS
                  : "inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle md:min-w-fit "
              }`}
            >
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {children}
                  </Dialog.Title>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  style={{ marginTop: "auto" }}
                  disabled={loading}
                  className={`
                  border-transparent inline-flex w-full justify-center rounded-md border px-4 py-2 shadow-sm
                  ${loading && "cursor-default bg-gray-50 hover:bg-gray-300"}
                  bg-green-600 text-base font-medium  text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2
                  sm:col-start-2 sm:text-sm
                    ${
                      confirmBtnType == "success"
                        ? "bg-green-600 hover:bg-green-700"
                        : confirmBtnType == "warning"
                        ? "bg-yellow-500 hover:bg-yellow-600"
                        : confirmBtnType == "danger"
                        ? "bg-red-600 hover:bg-red-700"
                        : "bg-indigo-600 hover:bg-indigo-700"
                    }
                    `}
                  onClick={onConfirm}
                >
                  {loading
                    ? "Loading"
                    : confirmBtnText
                    ? confirmBtnText
                    : "Confirm"}
                </button>
                <button
                  type="button"
                  disabled={loading}
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-green-500 sm:col-start-1 sm:mt-0 sm:text-sm"
                  onClick={onBack}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationModal;
