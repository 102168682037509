import React, { useState, useEffect } from "react";
import ConfirmationModal from "../components/ConfirmationModal";
import { useSelector } from "react-redux";
import Loader from "components/loaderSpinner/Loader";

const SettingsForm = ({
  formType,
  initialFormData,
  handleSubmit,
  handleCancel,
}) => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const { loading } = useSelector((state) => state.setting);

  useEffect(() => {
    if (!initialFormData) return;
    if (initialFormData) {
      setKey(initialFormData.key);
      setValue(initialFormData.value);
    }
  }, [initialFormData]);

  const onConfirm = () => {
    setError("");
    if (!key || !value) return setError("key or Value both are required");
    handleSubmit({ key, value });
    handleCancel();
  };

  return (
    <div className="h-full w-full bg-white p-4">
      <ConfirmationModal
        onBack={handleCancel}
        onConfirm={onConfirm}
        confirmBtnText=""
      >
        {loading ? (
          <div className="flex h-full w-full items-center justify-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-4">
              <h1>{`${formType} Setting`}</h1>
            </div>
            <div className="flex flex-col justify-between gap-2">
              <div className="flex flex-auto flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Key
                </label>
                <input
                  type="text"
                  name="key"
                  placeholder="Type key"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
                />
              </div>
              <div className="flex flex-auto flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Value
                </label>
                <input
                  type="text"
                  name="value"
                  disabled={!key}
                  placeholder="Type value for key"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
                />
              </div>
              <div className="flex flex-auto flex-col items-start">
                {error ? <p className="text-red-500">{error}</p> : null}
              </div>
            </div>
          </>
        )}
      </ConfirmationModal>
    </div>
  );
};

export default SettingsForm;
