import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FORM_TYPE } from "constants/app.constants";
import CartRuleForm from "./cartRuleForm";
import { createRule } from "actions/cartRules/cartRules.action";

const CreateCartRule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="h-full w-full">
      <CartRuleForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createRule(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateCartRule;
