import Card from "components/card";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "../../../components/cardHeader";
import Table from "../../../components/table";

function CountryStatesTableCard({ states }) {
  const { countryCode } = useParams();
  const navigate = useNavigate();
  const tableHeaders = ["Name", "Country Code", "State Code", "Actions"];

  const records = states.map((state) => ({
    name: state.name,
    country_code: state.country_code,
    code: state.code,
    actions: (
      <div className="flex items-center justify-between gap-2">
        <button
          onClick={() =>
            navigate(`/countries/${countryCode}/states/${state.code}/cities`)
          }
          class={`
          cursor-pointer' : ''} flex h-8 items-center justify-center gap-1 rounded-lg border border-gray-300 bg-white px-3
           text-sm font-medium text-gray-500 hover:text-gray-700
          `}
        >
          <span>View&nbsp;Cities</span>
          <FaArrowRight className="h-3.5 w-3.5" />
        </button>
      </div>
    ),
  }));

  return (
    <div>
      <Card extra={"bg-white"}>
        <CardHeader
          label={"States"}
          buttons={[{
            text: "back",
            onClick: () => navigate(`/countries`),
          }]}
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default CountryStatesTableCard;
