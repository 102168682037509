import { setAlert } from "actions/alert/alert.actions";
import ProfileIcon from "assets/img/profile-icon.png";
import Card from "components/card";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { AlertTypes } from "reducers/alert/alert.reducer";
import DashboardService from "services/dashboard/DashboardService";
import tableDataCheck from "../variables/tableDataCheck.json";

const TopMerchantsTable = () => {
  const dispatch = useDispatch()
  const { new_order_event } = useSelector((state) => state.events);
  const [loading, setLoading] = useState(false)
  const [columns, setColumns] = useState([
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "Orders",
      accessor: "total_orders",
    },
    {
      Header: "Amount",
      accessor: "total_amount",
    }
  ]);
  const [data, setData] = useState(tableDataCheck);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;


  const getTopMerchantsData = async () => {
    try {
      setLoading(true);
      const response = await DashboardService.getTopMerchants();
      if (response.status == 1) {
        setData(response.data.topMerchants.map(merchant => ({
          ...merchant,
          name: <div className="flex items-center gap-2">
            <div className="flex h-10 w-10 items-center justify-center">
              <img
                className="h-9 w-9 rounded-full"
                src={merchant?.logo ?? ProfileIcon}
                alt=""
              />
            </div>
            {merchant.name}
          </div>
        })))
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load dashboard stats",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) return
    getTopMerchantsData()
  }, [new_order_event.id])

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Top Merchants
        </div>

        {/* <CardMenu /> */}
      </header>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table
          {...getTableProps()}
          className="w-full"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pr-16 pb-[10px] text-start dark:!border-navy-700"
                    key={index}
                  >
                    {column.Header === "NAME" ?
                      <div className="flex items-center gap-2">
                        <div className="flex h-10 w-10 items-center justify-center" />
                        <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                          {column.render("Header")}
                        </div>
                      </div>
                      :
                      <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                        {column.render("Header")}
                      </div>
                    }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pt-[14px] pb-[16px] sm:text-[14px]"
                      >

                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default TopMerchantsTable;
