import { createMerchantPromotionTag } from "actions/merchantPromotionTags/merchantPromotionTags.action";
import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MerchantPromotionTagForm from "./merchantPromotionTagForm";

const CreateMerchantPromotionTag = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="h-full w-full">
      <MerchantPromotionTagForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(payload) =>
          dispatch(createMerchantPromotionTag(payload, navigate))
        }
      />
    </div>
  );
};

export default CreateMerchantPromotionTag;
