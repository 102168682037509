const createThumbnail = (file, fileType) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 200;
    canvas.height = 200;

    if (fileType === "image") {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          const thumbnailFile = new File([blob], `thumbnail-${file.name}`, {
            type: "image/jpeg",
            lastModified: Date.now(),
          });
          resolve(thumbnailFile);
        }, "image/jpeg");
      };
      img.onerror = (error) => reject(error);
    } else if (fileType === "video") {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      // Wait until video metadata is loaded
      video.onloadeddata = () => {
        // Seek to a position in the video where there's actual content
        video.currentTime = 1; // Adjust as needed (e.g., 1 second into the video)
      };

      video.onseeked = () => {
        // Now we can safely draw the frame from the video
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          const thumbnailFile = new File([blob], `thumbnail-${file.name}`, {
            type: "image/jpeg",
            lastModified: Date.now(),
          });
          resolve(thumbnailFile);
        }, "image/jpeg");
      };

      video.onerror = (error) => reject(error);
    }
  });
};

export default createThumbnail;
