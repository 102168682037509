import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import CustomerService from "../../services/customers/CustomerService";
import * as ActionTypes from "../../actions/actionTypes";
import { downloadFile } from "utils/App.utils";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CUSTOMER_LOADING,
    payload: loading,
  });
};

export const setCustomers = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CUSTOMERS,
    payload: data,
  });
};

export const setCustomer = (customer) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CUSTOMER,
    payload: customer,
  });
};

export const setUpdatedCustomer = (customer) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_UPDATE_CUSTOMER,
    payload: customer,
  });
};

export const resetCustomerState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_CUSTOMER_STATE,
  });
};

export const getCustomers =
  (page, limit, name, email, phoneNumber) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await CustomerService.getCustomers(
        page,
        limit,
        name,
        email,
        phoneNumber
      );
      if (response.status == 1 && response.data) {
        dispatch(setCustomers(response.data));
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load customers",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const exportCustomers =
  (name, email, phoneNumber) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await CustomerService.exportCustomers(
        name,
        email,
        phoneNumber
      );
      if (response.status == 0) {
        dispatch(
          setAlert(
            response.message ??
              "Something went wrong while downloading the CSV",
            AlertTypes.ERROR
          )
        );
        return;
      }
      downloadFile(response, "customers.csv");
    } catch (error) {
      dispatch(
        setAlert(
          "Something went wrong while downloading the CSV",
          AlertTypes.ERROR
        )
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateCustomer = (customerId, payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CustomerService.updateCustomer(customerId, payload);
    if (response.status == 1) {
      dispatch(setUpdatedCustomer({ customer: response.data }));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "Customer updated sucessfully",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getCustomerprofile = (customerId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CustomerService.getCustomerprofile(customerId);
    if (response.status == 1 && response.data) {
      dispatch(setCustomer(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load customers profile",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
