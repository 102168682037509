import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  transactionsCount: 0,
  transactions: [],
  transaction: null,
  loading: false,
};

export const TransactionReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_TRANSACTIONS:
      return {
        ...state,
        transactionsCount: payload?.meta?.total_documents || 0,
        transactions: payload.transactions,
        loading: false,
      };
    case ActionTypes.SET_TRANSACTION:
      return {
        ...state,
        transaction: payload,
        loading: false,
      };
    case ActionTypes.SET_TRANSACTION_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_TRANSACTION_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
