import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSignin } from "actions/auth/auth.actions";
import authImg from "../assets/img/auth/auth.png";
import { Link, redirect, useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, user, loading } = useSelector((state) => state.auth);
  const [credentials, setCredentials] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(userSignin(credentials, navigate));
  };

  return (
    <div className="flex min-h-full justify-between">
      {/* <div className='hidden md:flex min-h-full w-full'>
        <img
          src='./assets/login-bg.png'
          alt=''
        />
      </div> */}
      <div className="flex min-h-full w-full flex-col justify-center px-6 py-12 md:w-1/2 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-6 text-center text-2xl leading-9 tracking-tight text-gray-900">
            Please, sign in to continue
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  required
                  id="email"
                  name="email"
                  type="email"
                  value={credentials.email}
                  onChange={handleChange}
                  autoComplete="email"
                  className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  required
                  id="password"
                  name="password"
                  type="password"
                  value={credentials.password}
                  onChange={handleChange}
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="flex w-full justify-center rounded-md bg-brand-200 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {loading ? "signing in..." : "Sign in"}
              </button>
            </div>

            <div className="flex items-center justify-center text-sm">
              <Link
                to="/forgot-password"
                className="text-indigo-400 hover:text-indigo-500"
              >
                Forgot password?
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className="absolute right-0 hidden h-full min-h-screen w-1/2 md:flex">
        <div
          className="absolute flex h-full w-full items-end justify-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
          style={{ backgroundImage: `url(${authImg})` }}
        />
      </div>
    </div>
  );
}
