import { getDashboardStats } from "actions/dashboard/dashboard.action";
import Widget from "components/widget/Widget";
import { Fragment, useEffect } from "react";
import { AiFillShop } from "react-icons/ai";
import { BsFillPhoneFill, BsPersonWorkspace } from "react-icons/bs";
import {
  FaArrowDown,
  FaArrowUp,
  FaDollarSign,
  FaImages,
  FaTablet,
} from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { TbArrowsUpDown, TbCurrencySolana } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "utils/App.utils";

const WidgetCards = () => {
  const dispatch = useDispatch();
  const { stats, loading } = useSelector((state) => state.dashboard);
  const { active_users_event, new_order_event, new_transaction_event, new_customer_event, new_merchant_event, new_membership_event } = useSelector((state) => state.events);

  useEffect(() => {
    if (loading) return
    dispatch(getDashboardStats());
  }, [new_order_event.id, new_transaction_event.id, new_customer_event.id, new_merchant_event.id, new_membership_event.id]);

  return (
    <Fragment>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4">
        <Widget
          icon={<IoPerson className="h-7 w-7" />}
          title={"Total Customers"}
          subtitle={stats?.totalCustomers || 0}
          extra={
            <div className="flex w-full justify-between gap-5">
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Verified
                </p>
                <h4 className="flex items-center text-xs font-bold text-navy-700  dark:text-white">
                  <div className={`rounded-full `}>
                    <MdCheckCircle className="text-green-500" />
                  </div>
                  {stats?.verifiedCustomers}
                </h4>
              </div>
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Not Verified
                </p>
                <h4 className="flex items-center text-xs font-bold text-navy-700 dark:text-white">
                  <div className={`rounded-full `}>
                    <MdCancel className="text-red-500" />
                  </div>
                  {stats?.nonVerifiedCustomers}
                </h4>
              </div>
            </div>
          }
        />
        <Widget
          icon={<BsFillPhoneFill className="h-7 w-7" />}
          title={"Active Customers"}
          subtitle={active_users_event.data?.mobile_app_users?.length || 0}
        />
        <Widget
          icon={<AiFillShop className="h-7 w-7" />}
          title={"Total Merchants"}
          subtitle={stats?.totalMerchants || 0}
        />
        <Widget
          icon={<AiFillShop className="h-7 w-7" />}
          title={"Active Merchants"}
          subtitle={active_users_event.data?.merchant_console_users?.length || 0}
        />
        <Widget
          icon={<BsPersonWorkspace className="h-7 w-7" />}
          title={"Active Admins"}
          subtitle={active_users_event.data?.admin_console_users?.length || 0}
        />
        <Widget
          icon={<FaTablet className="h-7 w-7" />}
          title={"Active Venders"}
          subtitle={active_users_event.data?.vendor_app_users?.length || 0}
        />
        <Widget
          icon={<FaImages className="h-7 w-7" />}
          title={"Total NFTs Mint"}
          subtitle={stats?.totalNftMints || 0}
        />
        <Widget
          icon={<TbCurrencySolana className="h-7 w-7" />}
          title={"Total Minting Fee"}
          subtitle={`${stats?.totalMintFees || 0} Solana`}
        />
        <Widget
          icon={<TbArrowsUpDown className="h-7 w-7" />}
          title={"Total Transactions"}
          subtitle={formatNumber(stats?.totalTransacrions || 0, 0)}
        />
        <Widget
          icon={<FaDollarSign className="h-7 w-7" />}
          title={"Total Customers Spending"}
          subtitle={"$" + formatNumber(stats?.totalCustomersSpending || 0, 3)}
        />
        <Widget
          icon={<FaArrowDown className="h-7 w-7" />}
          title={"Total Earnings"}
          subtitle={"$" + formatNumber(stats?.earnings?.total_points || 0, 2)}
          extra={
            <div className="flex w-full justify-between gap-5">
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Network earning
                </p>
                <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                  {"$" + formatNumber(stats?.earnings?.earning_network || 0, 2)}
                </h4>
              </div>
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Merchant earning
                </p>
                <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                  {"$" +
                    formatNumber(stats?.earnings?.earning_merchant || 0, 2)}
                </h4>
              </div>
            </div>
          }
        />
        <Widget
          icon={<FaArrowUp className="h-7 w-7" />}
          title={"Total Redemptions"}
          subtitle={
            "$" + formatNumber(stats?.redemptions?.total_points || 0, 2)
          }
          extra={
            <div className="flex w-full justify-between gap-5">
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Network redeem
                </p>
                <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                  {"$" +
                    formatNumber(
                      stats?.redemptions?.redemption_network || 0,
                      2
                    )}
                </h4>
              </div>
              <div className=" flex w-auto flex-col justify-center">
                <p className="font-dm text-xs font-medium text-gray-600">
                  Merchant redeem
                </p>
                <h4 className="text-xs font-bold text-navy-700 dark:text-white">
                  {"$" +
                    formatNumber(
                      stats?.redemptions?.redemption_merchant || 0,
                      2
                    )}
                </h4>
              </div>
            </div>
          }
        />
      </div>
    </Fragment>
  );
};

export default WidgetCards;
