import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../components/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { getCustomerClassifications } from "actions/customerClassification/customerClassification.actions";
import ClassificationTableCard from "./classification-table-card";
import { deleteClassification } from "actions/customerClassification/customerClassification.actions";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const CustomerClassification = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customerClassificationCount, customerClassifications, loading } =
    useSelector((state) => state.customerClassification);
  const [showDelModal, setShowDelModal] = useState(false);
  const [delClassification, setDelClassification] = useState(null);

  useEffect(() => {
    dispatch(getCustomerClassifications(page, limit));
  }, [page]);

  const handleCancel = () => {
    setShowDelModal(false);
    setDelClassification(null);
  };

  const onClickDelete = (lang) => {
    setDelClassification(lang);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteClassification(delClassification.id));
    handleCancel();
  };

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the Classification with name "
              {delClassification?.name ?? ""}"
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <ClassificationTableCard
        customerClassifications={customerClassifications}
        onClickAddNew={() => navigate("/customer-classifications/create")}
        onClickEdit={(ClassificationId) =>
          navigate(`/customer-classifications/update/${ClassificationId}`)
        }
        onClickDelete={onClickDelete}
      />{" "}
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={customerClassificationCount}
      />
    </div>
  );
};

export default CustomerClassification;
