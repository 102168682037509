import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  deviceCount: 0,
  devices: [],
  device: null,
  loading: false,
};

export const devicesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_DEVICES:
      return {
        ...state,
        deviceCount: payload?.meta?.total_documents || 0,
        devices: payload.devices,
        loading: false,
      };
    case ActionTypes.SET_DEVICE:
      return {
        ...state,
        device: payload,
        loading: false,
      };
    case ActionTypes.UPDATE_DEVICE_ENABLE_FLAG:
      return {
        ...state,
        devices: state.devices.map((device) =>
          device.id === payload.id
            ? { ...device, enable: payload.enable }
            : device
        ),
      };
    case ActionTypes.UPDATE_DEVICE_INTEGRATION_FLAG:
      return {
        ...state,
        devices: state.devices.map((device) =>
          device.id === payload.id
            ? { ...device, is_not_integrated: payload.is_not_integrated }
            : device
        ),
      };
    case ActionTypes.DELETE_DEVICE:
      return {
        ...state,
        deviceCount: state.deviceCount - 1,
        devices: state.devices.filter((device) => device.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_DEVICE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_DEVICE_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
