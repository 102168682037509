import Card from "components/card";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import ConfirmationModal from "../components/ConfirmationModal";
import { deleteUser } from "actions/users/users.actions";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";




const getStatusClasses = (role) => {
  switch (role) {
    case "Super-Admin":
      return "bg-yellow-200 text-yellow-800";
    case "User-Manager":
      return "bg-orange-200 text-orange-800";
    case "Merchant-Manager":
      return "bg-green-200 text-green-800";
    case "Store-Loyalty-Manager":
      return "bg-blue-200 text-blue-800";
    case "Language-Manager":
      return "bg-pink-200 text-pink-800";
    case "Slider-Manager":
      return "bg-teal-200 text-teal-800";
    case "Category-Manager":
      return "bg-amber-200 text-amber-800";
    case "Region-Manager":
      return "bg-lime-200 text-lime-800";
    case "Classification-Manager":
      return "bg-purple-200 text-purple-800";
    case "Currency-Manager":
      return "bg-indigo-200 text-indigo-800";
    case "Transaction-Manager":
      return "bg-cyan-200 text-cyan-800";
    case "Content-Manager":
      return "bg-lime-200 text-lime-800";
    case "Customer-Support-Admin":
      return "bg-gray-200 text-gray-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

function UsersTableCard({ users }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [userData, setUserData] = useState(null);

  const onClickDelete = (user) => {
    setUserData(user);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteUser(userData.id));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
    setUserData(null);
  };

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className=" flex h-10 w-10 items-center justify-center " />
      {"Name"}
    </div>,
    "Email",
    "Phone",
    "Roles",
    "Created By",
    "Actions",
  ];

  const records = users.map((user) => ({
    1: (
      <div className="flex items-center gap-2">
        <div className="flex h-10 w-10 items-center justify-center">
          <img className="h-9 w-9 rounded-full" src={ProfileIcon} alt="" />
        </div>
        {user.name}
      </div>
    ),
    email: user.email,
    phone_number: `${user.phone_number.code} ${user.phone_number.number}`,
    roles: (
      <div className="flex w-full  flex-wrap gap-1">
        {user.roles.map((role) => (
          <span
            className={`w-fit text-nowrap rounded-full px-2 py-1 text-xs font-semibold ${getStatusClasses(
              role.name
            )}`}
          >
            {role.name.replace(/-/g, " ")}
          </span>
        ))}
      </div>
    ),
    created_by: user?.created_by?.name ?? "",
    actions: (
      <div className="flex items-center  gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.USER_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => navigate(`/users/update/${user.id}`)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.USER_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
            onClick={() => onClickDelete(user)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));

  return (
    <div>
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the "{userData.name}" user</h1>
          </div>
        </ConfirmationModal>
      ) : null}

      <Card extra={" bg-white"}>
        <CardHeader
          label={"Users"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.USER_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                  {
                    text: "Add New",
                    onClick: () => navigate("/users/create"),
                  variant: "primary",
                  },
                ]
              : null
          }
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default UsersTableCard;
