import { Fragment } from "react";
import { useSelector } from "react-redux";
import HeadlessSelectObject from "views/admin/components/headless/selectObject.headless";

const BasicInformation = ({ merchantData, onChangeFormData, lang_id }) => {
  const { brands, loading } = useSelector((state) => state.brand);
  return (
    <Fragment>
      <div className="flex flex-col gap-3">
        {/* Name and Type */}
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Name
            </label>
            <input
              required
              type="text"
              name="name"
              value={merchantData.name}
              onChange={(e) => onChangeFormData(e.target)}
              className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
            />
          </div>
          {!lang_id ? (
            <div className="w-full">
              <HeadlessSelectObject
                label="Brand Name"
                disable={!brands.length || loading}
                title={brands.length ? "select brand" : "No brand found"}
                options={[
                  ...brands.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={merchantData.brand_id}
                onSelect={(value) =>
                  onChangeFormData({ name: "brand_id", value })
                }
              />
            </div>
          ) : null}
        </div>

        {/* short_description */}
        <div className="w-full">
          <label
            for="content"
            className="mb-1 block text-base font-medium text-gray-800"
          >
            Short Description
          </label>
          <textarea
            required
            name="short_description"
            value={merchantData.short_description}
            onChange={(e) => onChangeFormData(e.target)}
            className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
            rows="2"
          />
        </div>

        {/* Description */}
        <div className="w-full">
          <label
            for="content"
            className="mb-1 block text-base font-medium text-gray-800"
          >
            Description
          </label>
          <textarea
            required
            name="description"
            value={merchantData.description}
            onChange={(e) => onChangeFormData(e.target)}
            className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
            rows="3"
          />
        </div>

        {lang_id ? (
          <>
            {/* Address */}
            <div className="flex flex-col justify-between gap-2 md:flex-row">
              <div className="w-full">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Address
                </label>
                <input
                  onChange={(e) => onChangeFormData(e.target)}
                  required
                  type="text"
                  name="address"
                  value={merchantData.address}
                  className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                />
              </div>
            </div>
            {/* phone */}

            <div className="w-full">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Phone
              </label>
              <input
                required
                type="tel"
                name="phone"
                value={merchantData.phone}
                onChange={(e) => onChangeFormData(e.target)}
                className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
              />
            </div>
          </>
        ) : null}
      </div>
    </Fragment>
  );
};

export default BasicInformation;
