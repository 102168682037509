import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import CurencyForm from "./currencyForm";
import { createCryptoCurrency } from "actions/cryptoCurrency/cryptoCurrency.action";

const CreateCryptoCurrency = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <CurencyForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createCryptoCurrency(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateCryptoCurrency;
