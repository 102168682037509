import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  merchantsCount: 0,
  merchants: [],
  merchant: null,
  merchnatMultiLang: {},
  merchantTagsCount: 0,
  tags: [],
  tag: null,
  merchnatTagMultiLang: {},
  merchantTypesCount: 0,
  types: [],
  type: null,
  merchnatTypeMultiLang: {},
  vibesCount: 0,
  vibes: [],
  vibe: null,
  likedBy: [],
  seenBy: [],
  loading: false,
};

export const merchantsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_MERCHANTS:
      return {
        ...state,
        merchantsCount: payload?.meta?.total_documents || 0,
        merchants: payload.merchants,
        loading: false,
      };
    case ActionTypes.UPDATE_MERCHANT_PUBLISH_FLAG:
      return {
        ...state,
        merchants: state.merchants.map((merchant) =>
          merchant.id === payload.merchant.id
            ? { ...merchant, is_published: payload.merchant.is_published }
            : merchant
        ),
        loading: false,
      };
    case ActionTypes.SET_MERCHANT:
      return {
        ...state,
        merchant: payload.merchant,
        merchnatMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_MERCHANT:
      return {
        ...state,
        merchantsCount: state.merchantsCount - 1,
        merchants: state.merchants.filter(
          (merchant) => merchant.id !== payload
        ),
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_TAGS:
      return {
        ...state,
        merchantTagsCount: payload?.meta?.total_documents || 0,
        tags: payload.tags,
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_TAG:
      return {
        ...state,
        tag: payload.merchantTag,
        merchnatTagMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.ADD_MERCHANT_TAG:
      return {
        ...state,
        tags: [...state.tags, payload],
        loading: false,
      };
    case ActionTypes.UPDATE_MERCHANT_TAG:
      return {
        ...state,
        tags: state.tags.map((tag) => (tag.id == payload.id ? payload : tag)),
        loading: false,
      };
    case ActionTypes.DELETE_MERCHANT_TAG:
      return {
        ...state,
        tags: state.tags.filter((tag) => tag.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_TYPES:
      return {
        ...state,
        merchantTypesCount: payload?.meta?.total_documents || 0,
        types: payload.merchantTypes,
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_TYPE:
      return {
        ...state,
        type: payload.merchantType,
        merchnatTypeMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.ADD_MERCHANT_TYPE:
      return {
        ...state,
        types: [...state.types, payload],
        loading: false,
      };
    case ActionTypes.UPDATE_MERCHANT_TYPE:
      return {
        ...state,
        types: state.types.map((type) =>
          type.id == payload.id ? payload : type
        ),
        loading: false,
      };
    case ActionTypes.DELETE_MERCHANT_TYPE:
      return {
        ...state,
        types: state.types.filter((type) => type.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_VIBES:
      return {
        ...state,
        vibesCount: payload?.meta?.total_documents || 0,
        vibes: payload.vibes,
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_VIBE:
      return {
        ...state,
        vibe: payload.vibe,
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_VIBE_LIKED_BY:
      return {
        ...state,
        likedBy: payload.liked_by,
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_VIBE_SEEN_BY:
      return {
        ...state,
        seenBy: payload.seen_by,
        loading: false,
      };
    case ActionTypes.DELETE_VIBE:
      return {
        ...state,
        vibes: state.vibes.filter((vibe) => vibe.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_MERCHANT_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
