import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCryptoCurrencies } from "actions/cryptoCurrency/cryptoCurrency.action";
import CryptoCurrencyTableCard from "./currency-table-card";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const CryptoCurrencies = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { currencyCount, currencies, loading } = useSelector(
    (state) => state.crypto
  );

  useEffect(() => {
    dispatch(getCryptoCurrencies(page, limit));
  }, [page]);

  if (loading) return;
  return (
    <div className="h-full w-full">
      <CryptoCurrencyTableCard currencies={currencies} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={currencyCount}
      />
    </div>
  );
};

export default CryptoCurrencies;
