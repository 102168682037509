import {
  FaArrowLeft,
  FaEdit,
  FaEye,
  FaPlus,
  FaSave,
  FaTrash,
} from "react-icons/fa";
import { TbPasswordFingerprint } from "react-icons/tb";

const buttonStyles = {
  primary:
    "bg-brand-400 text-white hover:bg-green-500 dark:bg-brand-700 dark:hover:bg-brand-800",
  success:
    "bg-brand-400 text-white hover:bg-green-500 dark:bg-brand-700 dark:hover:bg-brand-800",
  secondary:
    "bg-lightPrimary text-brand-500 hover:bg-gray-100 dark:bg-white/5 dark:hover:bg-white/10",
  link: "bg-blue-400 text-white hover:bg-blue-500 dark:bg-blue-700 dark:hover:bg-blue-800",
  warning:
    "bg-yellow-400 text-white hover:bg-yellow-500 dark:bg-yellow-700 dark:hover:bg-yellow-800",
  danger:
    "bg-red-400 text-white hover:bg-red-500 dark:bg-red-700 dark:hover:bg-red-800",
  dark: "bg-gray-500 text-white hover:bg-gray-800 dark:bg-gray-700 dark:hover:bg-gray-800",
};

const buttonIcons = {
  "Add New": <FaPlus />,
  "Update Password": <TbPasswordFingerprint />,
  Back: <FaArrowLeft />,
  Save: <FaSave />,
  "Save & Continue Edit": <FaEdit />,
  Edit: <FaEdit />,
  Delete: <FaTrash />,
  "View Logs": <FaEye />,
};

function CardHeader({ label, buttons }) {
  const hideTextOnSmallScreens = buttons?.length > 2;
  return (
    <div className="flex h-fit w-full items-center justify-between rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">
      <h4 className="text-lg font-bold text-navy-700 dark:text-white">
        {label}
      </h4>
      <div className="flex flex-wrap justify-end gap-2 sm:gap-1">
        {buttons && buttons.length
          ? buttons.map((button) => (
              <button
                onClick={button.onClick}
                className={`linear flex items-center justify-center gap-1 rounded-md px-4 py-1.5 text-base font-medium  text-brand-500 transition duration-200 hover:bg-gray-100  dark:bg-white/5 dark:hover:bg-white/10  ${
                  button.styles ?? ""
                } ${buttonStyles[button.variant] ?? ""}`}
              >
                <span className="block">{buttonIcons[button.text]}</span>
                {/* {showTextOnSmallScreen && ( */}
                <span
                  className={`${
                    hideTextOnSmallScreens ? "hidden md:inline" : "inline"
                  }`}
                >
                  {button.text.split(" ").join("\u00A0")}
                </span>
                {/* )} */}
              </button>
            ))
          : null}
      </div>
    </div>
  );
}

export default CardHeader;
