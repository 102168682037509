import "react-phone-number-input/style.css";
import React, { useState, useEffect } from "react";
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";

const PhoneNumberInput = ({
  phone_number: { code, number },
  onPhoneNumberChange,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (code && number) {
      const tempNumber = `${code}${number}`;
      setPhoneNumber(tempNumber);
    }
  }, [code, number]);

  const handleNumberChange = (number) => {
    setPhoneNumber(number);

    // Validate phone number
    if (number) {
      const parsedNumber = parsePhoneNumber(number);
      const isValid = parsedNumber ? isValidPhoneNumber(number) : false;
      setIsValid(isValid);

      if (isValid) {
        const newCode = `+${parsedNumber?.countryCallingCode ?? "966"}`;
        onPhoneNumberChange({
          code: newCode,
          number: parsedNumber?.nationalNumber,
        });
      } else {
        // Handle invalid phone number, e.g., display an error message
        console.error("Invalid phone number");
      }
    } else {
      onPhoneNumberChange({
        code: "",
        number: "",
      });
    }
  };

  return (
    <div className="flex w-full items-center justify-center gap-1">
      <div className="flex flex-auto">
        <PhoneInput
          placeholder="Enter phone number"
          international
          countryCallingCodeEditable={false}
          defaultCountry="SA" // Set default country code
          value={phoneNumber}
          onChange={handleNumberChange}
          className={`block w-full rounded-md border border-gray-300 px-3 py-2 text-sm shadow-sm outline-none  ${
            !isValid ? "border-red-500" : ""
          }`}
          style={{  outline: 'none',}}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;
