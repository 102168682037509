import currencies from "./currencies.json";

export const MERCHANT_TYPE = {
  DEFAULT: "default",
  INTERNAL: "internal",
};

export const CURRENCY_OPTIONS = Object.values(currencies).map(
  (currency) => currency.code
);

export const DEFAULT_OPERATION_TIME = {
  isOff: false,
  opening_time: "09:00",
  closing_time: "18:00",
};
