import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  currencyCount: 0,
  currencies: [],
  currency: null,
  loading: false,
};

export const cryptoCurrencyReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_CRYPTO_CURRENCIES:
      return {
        ...state,
        currencyCount: payload?.meta?.total_documents || 0,
        currencies: payload.currencies,
        loading: false,
      };
    case ActionTypes.SET_CRYPTO_CURRENCY:
      return {
        ...state,
        currency: payload.currency,
        loading: false,
      };
    case ActionTypes.DELETE_CRYPTO_CURRENCY:
      return {
        ...state,
        currencyCount: state.currencyCount - 1,
        currencies: state.currencies.filter(
          (currency) => currency.id !== payload
        ),
        loading: false,
      };
    case ActionTypes.SET_CRYPTO_CURRENCY_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_CRYPTO_CURRENCY_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
