import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getStoreLoyalties = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_STORE_LOYALTIES}?page=${page}&limit=${limit}`
  );
};

const getStoreLoyalty = (storeloyalty_id) => {
  return RestClient.Get(`${API_URL.GET_STORE_LOYALTY}/${storeloyalty_id}`);
};

const createStoreLoyalty = (payload) => {
  return RestClient.Post(API_URL.CREATE_STORE_LOYALTY, payload);
};

const updateStoreLoyalty = (storeloyalty_id, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_STORE_LOYALTY}/${storeloyalty_id}`,
    payload
  );
};

const updateMultiLangStoreLoyalty = (storeloyalty_id, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MULTI_LANG_STORE_LOYALTY}/${storeloyalty_id}`,
    payload
  );
};

const deleteStoreLoyalty = (storeloyalty_id) => {
  return RestClient.Delete(
    `${API_URL.DELETE_STORE_LOYALTY}/${storeloyalty_id}`
  );
};

const exportedObject = {
  getStoreLoyalties,
  getStoreLoyalty,
  createStoreLoyalty,
  updateStoreLoyalty,
  updateMultiLangStoreLoyalty,
  deleteStoreLoyalty,
};

export default exportedObject;
