import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import FacilityService from "../../services/facility/FacilityService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_FACILITY_LOADING,
    payload: loading,
  });
};

export const setFacilities = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_FACILITIES,
    payload: data,
  });
};

export const setFacility = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_FACILITY,
    payload: data,
  });
};

export const editFacility = (brand) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_FACILITY,
    payload: brand,
  });
};

export const removeFacility = (facility_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_FACILITY,
    payload: facility_id,
  });
};

export const resetFacilityState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_FACILITY_STATE,
  });
};

export const getFacilities = (paeg,limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await FacilityService.getFacilities(paeg,limit);
    if (response.status == 1) {
      dispatch(setFacilities(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load facilities",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getFacility = (facility_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await FacilityService.getFacility(facility_id);
    if (response.data && response.status == 1) {
      dispatch(setFacility(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Facility", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createFacility = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await FacilityService.createFacility(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "brand created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/facility");
    }
    dispatch(
      setAlert(response.message ?? "failed to create brand", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateFacility =
  (facility_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await FacilityService.updateFacility(
        facility_id,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "brand updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/facility");
      }
      dispatch(
        setAlert(response.message ?? "failed to update brand", AlertTypes.ERROR)
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateMultiLangFacility =
  (facility_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await FacilityService.updateMultiLangFacility(
        facility_id,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "brand localization updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/facility");
      }
      dispatch(
        setAlert(response.message ?? "failed to update brand", AlertTypes.ERROR)
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteFacility = (facility_id, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await FacilityService.deleteFacility(facility_id);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "brand deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeFacility(facility_id));
      if (!navigate) return;
      return navigate("/facility");
    }
    dispatch(
      setAlert(response.message ?? "failed to delete brand", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
