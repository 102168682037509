import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  storesCount: 0,
  stores: [],
  campaigns: [],
  store: null,
  storeMultiLang: {},
  loading: false,
};

export const storeReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_STORES:
      return {
        ...state,
        storesCount: payload?.meta?.total_documents || 0,
        stores: payload.stores,
        loading: false,
      };
    case ActionTypes.SET_CAMPAIGNS:
      return {
        ...state,
        campaigns: payload?.results || [],
        loading: false,
      };
    case ActionTypes.SET_STORE:
      return {
        ...state,
        store: payload.store,
        storeMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_STORE:
      return {
        ...state,
        storesCount: state.storesCount - 1,
        stores: state.stores.filter((store) => store.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_STORE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_STORE_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
