import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getFacilities = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_FACILITIES}?page=${page}&limit=${limit}`
  );
};
const getFacility = (facility_id) => {
  return RestClient.Get(`${API_URL.GET_FACILITY}/${facility_id}`);
};

const createFacility = (payload) => {
  return RestClient.Post(API_URL.CREATE_FACILITY, payload);
};

const updateFacility = (facility_id, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_FACILITY}/${facility_id}`, payload);
};

const updateMultiLangFacility = (facility_id, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_LANGUAGE_FACILITY}/${facility_id}`,
    payload
  );
};

const deleteFacility = (facility_id) => {
  return RestClient.Delete(`${API_URL.DELETE_FACILITY}/${facility_id}`);
};

const exportedObject = {
  getFacilities,
  getFacility,
  createFacility,
  updateFacility,
  updateMultiLangFacility,
  deleteFacility,
};

export default exportedObject;
