import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import RatingService from "../../services/rating/RatingService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_TRANSACTION_LOADING,
    payload: loading,
  });
};

export const setRatings = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_RATINGS,
    payload: data,
  });
};
export const setRating = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_RATING,
    payload: data,
  });
};
export const updateRatingStatusFlag = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_SET_RATING,
    payload: data,
  });
};

export const resetTransactionState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_RATING_STATE,
  });
};

export const getRatings = (page, limit, rating_status) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await RatingService.getRatings(page, limit, rating_status);
    if (response.status == 1 && response.data) {
      dispatch(setRatings(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load transactions",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getRating = (rating_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await RatingService.getRating(rating_id);
    if (response.status == 1 && response.data) {
      dispatch(setRating(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load transactions",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateRating =
  (rating_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await RatingService.updateRating(rating_id, payload);
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "rating status updated successfully",
            AlertTypes.SUCCESS
          )
        );
        dispatch(updateRatingStatusFlag({ rating: response.data }));
        if (!navigate) return;
        return navigate("/rating");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update rating status",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };
