import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../../actions/actionTypes";
import TicketService from "../../services/tickets/TicketService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_TICKET_LOADING,
    payload: loading,
  });
};

export const setTickets = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_TICKETS,
    payload: data,
  });
};
export const setTicketTypes = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_TICKET_TYPES,
    payload: data,
  });
};

export const setTicket = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_TICKET,
    payload: data,
  });
};

export const editTicket = (slider) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_TICKET,
    payload: slider,
  });
};

export const removeTicket = (ticketId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_TICKET,
    payload: ticketId,
  });
};

export const resetTicketState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_TICKET_STATE,
  });
};

export const getTickets = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await TicketService.getTickets(page, limit);
    if (response.status == 1) {
      dispatch(setTickets(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Tickets", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
export const getTicketTypes = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await TicketService.getTicketTypes();
    if (response.status == 1) {
      dispatch(setTicketTypes(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Tickets", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getTicket = (ticketId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await TicketService.getTicket(ticketId);
    if (response.status == 1) {
      dispatch(setTicket(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Ticket", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createTicket = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await TicketService.createTicket(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Ticket created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/tickets");
    }
    dispatch(
      setAlert(response.message ?? "failed to create Ticket", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateTicket =
  (ticketId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await TicketService.updateTicket(ticketId, payload);
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "Ticket updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/tickets");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update Ticket",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

// export const updateMultiLangSlider =
//   (ticketId, payload, navigate) => async (dispatch) => {
//     try {
//       dispatch(setLoading(true));
//       const response = await TicketService.updateMultiLangSlider(
//         ticketId,
//         payload
//       );
//       if (response.status == 1) {
//         dispatch(
//           setAlert(
//             response.message ?? "slider localization updated successfully",
//             AlertTypes.SUCCESS
//           )
//         );
//         if (!navigate) return;
//         return navigate("/sliders");
//       }
//       dispatch(
//         setAlert(
//           response.message ?? "failed to update slider",
//           AlertTypes.ERROR
//         )
//       );
//     } catch (error) {
//       dispatch(setAlert("server error", AlertTypes.ERROR));
//     } finally {
//       dispatch(setLoading(false));
//     }
//   };

export const deleteTicket = (ticketId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await TicketService.deleteTicket(ticketId);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Ticket deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeTicket(ticketId));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to delete Ticket", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
