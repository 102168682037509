import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import StoreTagservice from "../../services/storeTags/StoreTagService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORE_TAG_LOADING,
    payload: loading,
  });
};

export const setTags = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORE_TAGS,
    payload: data,
  });
};

export const setTag = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORE_TAG,
    payload: data,
  });
};

export const editTag = (Tag) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_STORE_TAG,
    payload: Tag,
  });
};

export const removeTag = (tagId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_STORE_TAG,
    payload: tagId,
  });
};

export const resetTagState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_STORE_TAG_STATE,
  });
};

export const getStoreTags = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreTagservice.getStoreTags(page, limit);
    if (response.status == 1) {
      dispatch(setTags(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load tags", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getStoreTag = (tagId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreTagservice.getStoreTag(tagId);
    if (response.data && response.status == 1) {
      dispatch(setTag(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Tag", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createStoreTag = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreTagservice.createStoreTag(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Tag created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/storeTags");
    }
    dispatch(
      setAlert(response.message ?? "failed to create Tag", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateStoreTag =
  (tagId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await StoreTagservice.updateStoreTag(tagId, payload);
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "Tag updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/storeTags");
      }
      dispatch(
        setAlert(response.message ?? "failed to update Tag", AlertTypes.ERROR)
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateMultiLangStoreTag =
  (tagId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await StoreTagservice.updateMultiLangStoreTag(
        tagId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "Tag updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/storeTags");
      }
      dispatch(
        setAlert(response.message ?? "failed to update Tag", AlertTypes.ERROR)
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteStoreTag = (tagId, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreTagservice.deleteStoreTag(tagId);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Tag deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeTag(tagId));
      if (!navigate) return;
      return navigate("/storeTags");
    }
    dispatch(
      setAlert(response.message ?? "failed to delete Tag", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
