/* eslint-disable */
import DashIcon from "components/icons/DashIcon";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import { hasPermission } from "utils/permissions";

function CreateLink({ route, isOpen, setOpen }) {
  let location = useLocation();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  if (
    route.layout === "/admin" ||
    route.layout === "/auth" ||
    route.layout === "/rtl"
  ) {
    return (
      <div>
        {route?.subMenu?.length ? (
          <div className={`${isOpen ? "h-auto" : "h-10"} overflow-hidden`}>
            <div
              className={`${!!activeRoute(route.path) ||
                route?.subMenu.some(
                  (sub_route) => !!activeRoute(sub_route.path)
                )
                ? "bg-brand-50"
                : isOpen
                  ? "bg-gray-500"
                  : "bg-none"
                } mx-3 rounded-md`}
            >
              <Link>
                <div className="relative mb-[1px] flex h-10 hover:cursor-pointer">
                  <li
                    className={`flex w-full cursor-pointer items-center justify-between rounded-md px-4
                  ${!!activeRoute(route.path) ||
                        route?.subMenu.some(
                          (sub_route) => !!activeRoute(sub_route.path)
                        )
                        ? "bg-brand-50 text-white"
                        : isOpen
                          ? "bg-gray-500 text-white"
                          : "bg-none text-gray-900"
                      }
                  `}
                    onClick={setOpen}
                  >
                    <div className="flex">
                      <span className={`place-content-center`}>
                        {route.icon ? route.icon : <DashIcon />}{" "}
                      </span>
                      <p className={`leading-1 ml-3 flex`}>{route.name}</p>
                    </div>
                    <span className={`place-content-center`}>
                      {isOpen ? (
                        <MdKeyboardArrowUp className="h-6 w-6" />
                      ) : (
                        <MdKeyboardArrowDown className="h-6 w-6" />
                      )}{" "}
                    </span>
                  </li>
                </div>
                <div className="mb-[1px] mt-1 flex flex-col items-start justify-center px-6 hover:cursor-pointer">
                  {route.subMenu
                    .filter(
                      (x) =>
                        x.isShowInSideNav == true &&
                        hasPermission(x.requiredRoles, x.requiredAction)
                    )
                    .map((sub_route) => (
                      <Link
                        key={sub_route.path}
                        to={sub_route?.path || ""}
                        className="w-full"
                      >
                        <div className="relative mb-1 flex h-8 hover:cursor-pointer">
                          <li
                            className={`flex w-full cursor-pointer items-center rounded-md px-3
                          ${activeRoute(sub_route.path) === true
                                ? "bg-white text-gray-900"
                                : "bg-none text-white"
                              }
                          `}
                          >
                            {/* submenu link icon*/}
                            <span className={`h-4 w-4 place-content-center`}>
                              {sub_route.icon ? sub_route.icon : <DashIcon />}{" "}
                            </span>
                            <p className={`leading-1 ml-2 flex`}>
                              {sub_route.name}
                            </p>
                          </li>
                        </div>
                      </Link>
                    ))}
                </div>
              </Link>
            </div>
          </div>
        ) : (
          <Link to={route?.path || ""}>
              <div className="relative mx-3 mb-[1px] flex h-10 hover:cursor-pointer">
                <li
                  className={`flex w-full cursor-pointer items-center rounded-md px-4
                ${activeRoute(route.path) === true
                      ? "bg-brand-50 text-white"
                      : "bg-none text-gray-900"
                    }
                `}
                >
                  <span className={`place-content-center`}>
                  {route.icon ? route.icon : <DashIcon />}{" "}
                </span>
                  <p className={`leading-1 ml-3 flex`}>{route.name}</p>
                </li>
            </div>
          </Link>
        )}
      </div>
    );
  }
  return null;
}

export default CreateLink;
