import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  languagesCount: 0,
  languages: [],
  language: null,
  languageMultiLang: {},
  loading: false,
};

export const languagesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_LANGUAGES:
      return {
        ...state,
        languagesCount: payload?.meta?.total_documents || 0,
        languages: payload.languages,
        loading: false,
      };
    case ActionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: payload.language,
        languageMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.ADD_LANGUAGE:
      return {
        ...state,
        languages: [...state.languages, payload],
        loading: false,
      };
    case ActionTypes.UPDATE_LANGUAGE:
      return {
        ...state,
        languages: state.languages.map((lang) =>
          lang.id == payload.id ? payload : lang
        ),
        loading: false,
      };
    case ActionTypes.DELETE_LANGUAGE:
      return {
        ...state,
        languages: state.languages.filter((lang) => lang.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_LANGUAGE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_LANGUAGE_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
