import { getStates } from "actions/regions/regions.actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "views/admin/components/pagination";
import StatesTableCard from "./states-table-card";
import { getCountries } from "actions/regions/regions.actions";
import StatesFilters from "./filters";
import { getSystemSettings } from "selectors/settings/settings.selector";

const States = () => {
  const dispatch = useDispatch();
  const { states, statesCount } = useSelector((state) => state.region);
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  return (
    <div className="h-full w-full">
      <StatesTableCard
        states={states}
        filters={<StatesFilters limit={limit} page={page} setPage={setPage} />}
      />

      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={statesCount}
      />
    </div>
  );
};

export default States;
