import { getCities } from "actions/regions/regions.actions";
import { getStates } from "actions/regions/regions.actions";
import { getAreas } from "actions/regions/regions.actions";
import { setAreas, setCities, setStates } from "actions/regions/regions.actions";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeadlessSelectObject from "views/admin/components/headless/selectObject.headless";

const MerchantRegion = ({ merchantData, setMerchantData, onChangeFormData }) => {
  const dispatch = useDispatch()
  const hasMounted = useRef(false);
  const { countries, states, cities, areas, loading } = useSelector((state) => state.region);

  useEffect(() => {
    if (
      !hasMounted.current ||
      loading ||
      !merchantData.country_id ||
      !countries.length
    ) return;
    setMerchantData((prevState) => ({ ...prevState, state_id: "", city_id: "", area_id: "" }));
    dispatch(setStates({ states: [] }));
    dispatch(setCities({ cities: [] }));
    dispatch(setAreas({ areas: [] }));

    const temp_country = countries?.find(
      (c) => c.id === merchantData.country_id
    );
    dispatch(getStates(temp_country?.code));
  }, [merchantData.country_id]);

  useEffect(() => {
    if (
      !hasMounted.current ||
      loading ||
      !merchantData.country_id ||
      !countries.length ||
      !merchantData.state_id ||
      !states.length
    ) return;

    setMerchantData((prevState) => ({ ...prevState, city_id: "", area_id: "" }));
    dispatch(setCities({ cities: [] }));
    dispatch(setAreas({ areas: [] }));

    const temp_country = countries?.find(
      (c) => c.id === merchantData.country_id
    );
    const temp_state = states?.find((s) => s.id === merchantData.state_id);
    dispatch(getCities(temp_country?.code, temp_state?.code));
  }, [merchantData.state_id]);

  useEffect(() => {
    if (
      !hasMounted.current ||
      loading ||
      !merchantData.country_id ||
      !countries.length ||
      !merchantData.state_id ||
      !states.length ||
      !merchantData.city_id ||
      !cities.length
    ) return;

    setMerchantData((prevState) => ({ ...prevState, area_id: "" }));
    dispatch(setAreas({ areas: [] }));
    const temp_country = countries?.find(
      (c) => c.id === merchantData.country_id
    );
    const temp_state = states?.find((s) => s.id === merchantData.state_id);
    const temp_city = cities?.find((s) => s.id === merchantData.city_id);
    dispatch(getAreas(temp_country?.id, temp_state?.id, temp_city?.id));
  }, [merchantData.city_id]);

  useEffect(() => {
    if (!hasMounted.current) hasMounted.current = true
  }, []);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col justify-between gap-2 md:flex-row">
        {/* country */}
        <div className="w-full  md:w-1/2">
          <HeadlessSelectObject
            label="Country"
            disable={!countries.length || loading}
            title={countries.length ? "select country" : "No country found"}
            options={[
              ...countries.map((t) => ({ name: t.name, value: t.id })),
            ]}
            selected={merchantData.country_id}
            onSelect={(value) =>
              onChangeFormData({ name: "country_id", value })
            }
          />
        </div>
        {/* state */}
        <div className="w-full md:w-1/2">
          <HeadlessSelectObject
            label="State"
            disable={!states.length || loading}
            title={states.length ? "select state" : "No state found"}
            options={[
              ...states.map((t) => ({ name: t.name, value: t.id })),
            ]}
            selected={merchantData.state_id}
            onSelect={(value) =>
              onChangeFormData({ name: "state_id", value })
            }
          />
        </div>
      </div>

      <div className="flex flex-col justify-between gap-2 md:flex-row">
        {/* city */}
        <div className="w-full md:w-1/2">
          <HeadlessSelectObject
            label="City"
            disable={!cities.length || loading}
            title={cities.length ? "select city" : "No city found"}
            options={[
              ...cities.map((t) => ({ name: t.name, value: t.id })),
            ]}
            selected={merchantData.city_id}
            onSelect={(value) =>
              onChangeFormData({ name: "city_id", value })
            }
          />
        </div>
        {/* Area */}
        <div className="w-full md:w-1/2">
          <HeadlessSelectObject
            label="Area"
            disable={!areas.length || loading}
            title={areas.length ? "select area" : "No area found"}
            options={[
              ...areas.map((t) => ({ name: t.name, value: t.id })),
            ]}
            selected={merchantData.area_id}
            onSelect={(value) =>
              onChangeFormData({ name: "area_id", value })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MerchantRegion;
