import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { IoClose, IoCloseCircleOutline } from "react-icons/io5";

const ImageModal = (props) => {
  const { onBack, children, loading, customContainerCSS } = props;

  return (
    <Transition.Root show as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open
        onClose={onBack}
      >
        <div className="mt-48 flex min-h-screen items-start justify-center px-4 pb-20 pt-2 text-center sm:mt-0 sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`${
                customContainerCSS?.length
                  ? customContainerCSS
                  : "inline-block transform overflow-hidden rounded-lg  text-left shadow-xl transition-all sm:my-8  sm:max-w-lg  sm:align-middle "
              }`}
            >
              <div className=" relative  text-center">
                <Dialog.Title
                  as="h3"
                  className=" text-lg font-medium leading-6 text-gray-900"
                >
                  <div
                    onClick={onBack}
                    className="absolute right-2 top-2 z-10   flex size-10 cursor-pointer items-center justify-center rounded-full bg-gray-100 text-gray-500 shadow-xl hover:bg-gray-300 hover:text-white"
                  >
                    <IoClose className=" size-6 cursor-pointer " />
                  </div>
                  {children}
                </Dialog.Title>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ImageModal;
