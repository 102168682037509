import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getcustomerClassifications = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_CUSTOMER_CLASSIFICATIONS}?page=${page}&limit=${limit}`
  );
};
const getcustomerClassification = (customerClassification_id) => {
  return RestClient.Get(
    `${API_URL.GET_CUSTOMER_CLASSIFICATION}/${customerClassification_id}`
  );
};

const createcustomerClassification = (payload) => {
  return RestClient.Post(API_URL.CREATE_CUSTOMER_CLASSIFICATION, payload);
};

const updatecustomerClassification = (customerClassification_id, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_CUSTOMER_CLASSIFICATION}/${customerClassification_id}`,
    payload
  );
};

const updateMultiLangClassification = (customerClassification_id, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MULTI_LANG_CUSTOMER_CLASSIFICATION}/${customerClassification_id}`,
    payload
  );
};

const deletecustomerClassification = (customerClassification_id) => {
  return RestClient.Delete(
    `${API_URL.DELETE_CUSTOMER_CLASSIFICATION}/${customerClassification_id}`
  );
};

const exportedObject = {
  getcustomerClassifications,
  getcustomerClassification,
  createcustomerClassification,
  updatecustomerClassification,
  updateMultiLangClassification,
  deletecustomerClassification,
};

export default exportedObject;
