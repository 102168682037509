import { getCities } from "actions/regions/regions.actions";
import { getStates } from "actions/regions/regions.actions";
import { getAreas } from "actions/regions/regions.actions";
import { setAreas, setCities, setStates } from "actions/regions/regions.actions";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeadlessSelectObject from "views/admin/components/headless/selectObject.headless";

const AreaRegion = ({ areaData, setAreaData, handleOnChange }) => {
  const dispatch = useDispatch()
  const hasMounted = useRef(false);
  const { countries, states, cities, areas, loading } = useSelector((state) => state.region);

  useEffect(() => {
    if (
      !hasMounted.current ||
      !areaData.country_id ||
      !countries.length
    ) return;
    setAreaData((prevState) => ({ ...prevState, state_id: "", city_id: "" }));
    dispatch(setStates({ states: [] }));
    dispatch(setCities({ cities: [] }));

    const temp_country = countries?.find(
      (c) => c.id === areaData.country_id
    );
    dispatch(getStates(temp_country?.code));
  }, [areaData.country_id]);

  useEffect(() => {
    if (
      !hasMounted.current ||
      !areaData.country_id ||
      !countries.length ||
      !areaData.state_id ||
      !states.length
    ) return;

    setAreaData((prevState) => ({ ...prevState, city_id: "" }));
    dispatch(setCities({ cities: [] }));

    const temp_country = countries?.find(
      (c) => c.id === areaData.country_id
    );
    const temp_state = states?.find((s) => s.id === areaData.state_id);
    dispatch(getCities(temp_country?.code, temp_state?.code));
  }, [areaData.state_id]);

  useEffect(() => {
    if (!hasMounted.current) hasMounted.current = true
  }, []);

  return (
    // <div className="flex flex-col border-2 border-indigo-400 p-2 gap-3 rounded-md">
    <div className="flex flex-col justify-between gap-2 md:flex-row">
      {/* country */}
      <div className="w-full  md:w-1/2">
        <HeadlessSelectObject
          label="Country"
          disable={!countries.length || loading}
          title={countries.length ? "select country" : "No country found"}
          options={[
            ...countries.map((t) => ({ name: t.name, value: t.id })),
          ]}
          selected={areaData.country_id}
          onSelect={(value) =>
            handleOnChange({ name: "country_id", value })
          }
        />
      </div>
      {/* state */}
      <div className="w-full md:w-1/2">
        <HeadlessSelectObject
          label="State"
          disable={!states.length || loading}
          title={states.length ? "select state" : "No state found"}
          options={[
            ...states.map((t) => ({ name: t.name, value: t.id })),
          ]}
          selected={areaData.state_id}
          onSelect={(value) =>
            handleOnChange({ name: "state_id", value })
          }
        />
      </div>
      <div className="w-full md:w-1/2">
        <HeadlessSelectObject
          label="City"
          disable={!cities.length || loading}
          title={cities.length ? "select city" : "No city found"}
          options={[
            ...cities.map((t) => ({ name: t.name, value: t.id })),
          ]}
          selected={areaData.city_id}
          onSelect={(value) =>
            handleOnChange({ name: "city_id", value })
          }
        />
      </div>
    </div>
  );
};

export default AreaRegion;
