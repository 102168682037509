import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import StoreTagForm from "./StoreTagForm";
import { createStoreTag } from "actions/storeTags/storeTags.actions";

const CreateStoreTag = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <StoreTagForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createStoreTag(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateStoreTag;
