import Footer from "components/footer/Footer";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
} from "react-router-dom";
import routes from "routes.js";
import { hasPermission } from "utils/permissions";

const AdminLayout = (props) => {
  const { ...rest } = props;
  const location = useLocation();
  const { permissions } = useSelector((state) => state.role);
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Dashboard");
  const [breadCrumbRoutes, setBreadCrumbRoutes] = React.useState([]);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Dashboard";
    const temp_bread_crumb_routes = [];
    routes.map((route, key) => {
      if (route.subMenu) {
        route.subMenu.forEach((menu, key) => {
          if (window.location.href.indexOf(menu.path) !== -1) {
            temp_bread_crumb_routes.push({
              title: route.name,
              path: route.path,
            });
            temp_bread_crumb_routes.push({ title: menu.name, path: menu.path });
          }
          if (menu.children) {
            menu.children.forEach((child, key) => {
              if (window.location.href.indexOf(child.path) !== -1) {
                if (menu.name === "List") {
                  temp_bread_crumb_routes.pop();
                }
                temp_bread_crumb_routes.push({
                  title: child.name,
                  path: child.path,
                });
              }
            });
          }
        });
      } else if (window.location.href.indexOf(route.path) !== -1) {
        temp_bread_crumb_routes.push({ title: route.name, path: route.path });
      }
    });
    setCurrentRoute(activeRoute);
    setBreadCrumbRoutes(temp_bread_crumb_routes);
    return activeRoute;
  };

  // const getActiveNavbar = (routes) => {
  //   let activeNavbar = false;
  //   for (let i = 0; i < routes.length; i++) {
  //     if (
  //       window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
  //     ) {
  //       return routes[i].secondary;
  //     }
  //   }
  //   return activeNavbar;
  // };

  const getRoutes = useCallback(() => {
    const routesArray = [];
    routes.map((prop, key) => {
      if (prop.subMenu) {
        prop.subMenu
          .filter((x) => hasPermission(x.requiredRoles, x.requiredAction))
          .forEach((menu, key) => {
            routesArray.push(
              <Route
                path={`/${menu.path}`}
                element={menu.component}
                key={key}
              ></Route>
            );
            menu?.children
              ?.filter((x) => hasPermission(x.requiredRoles, x.requiredAction))
              ?.forEach((child, key) => {
                routesArray.push(
                  <Route
                    path={`/${child.path}`}
                    element={child.component}
                    key={key}
                  />
                );
              });
          });
      } else if (
        prop.layout === "/admin" &&
        hasPermission(prop.requiredRoles, prop.requiredAction)
      ) {
        routesArray.push(
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
        prop?.children
          ?.filter((x) => hasPermission(x.requiredRoles, x.requiredAction))
          ?.forEach((child, key) => {
            routesArray.push(
              <Route
                path={`/${child.path}`}
                element={child.component}
                key={key}
              />
            );
          });
      }
    });
    return routesArray;
  }, [JSON.stringify(routes), JSON.stringify(permissions)]);

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Horizon UI Tailwind React"}
              brandText={currentRoute}
              breadCrumbRoutes={breadCrumbRoutes}
              // secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes()}

                <Route path="/" element={<Navigate to="/" replace />} />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
