import { validatePhoneNumber } from "utils/App.utils";

const validateMerchantForm = (formType, merchantData) => {
  const errors = [];
  if (!merchantData.name) errors.push("name is required");
  if (!merchantData.brand_id) errors.push("brand name is required");
  if (!merchantData.description) errors.push("description is required");
  if (!merchantData.type) errors.push("type is required");
  if (!merchantData.website_url) errors.push("valid website url is required");
  if (!merchantData.address) errors.push("address is required");
  if (!merchantData.category_id) errors.push("please select a category");
  if (
    !merchantData.location.coordinates ||
    merchantData.location.coordinates?.length < 2
  )
    errors.push("latitude and longitude is required");
  if (
    isNaN(merchantData.location.coordinates?.[0]) ||
    isNaN(merchantData.location.coordinates?.[1])
  )
    errors.push("latitude and longitude should be numbers");
  if (!merchantData.contact_info.phone) errors.push("phone number is required");
  if (!validatePhoneNumber(merchantData.contact_info.phone))
    errors.push("phone number is Incorrect");
  if (!merchantData.contact_info.public_phone)
    errors.push("public phone number is required");
  if (!validatePhoneNumber(merchantData.contact_info.public_phone))
    errors.push("public phone number is Incorrect");
  if (!merchantData.contact_info.internal_phone)
    errors.push("internal phone number is required");
  if (!validatePhoneNumber(merchantData.contact_info.internal_phone))
    errors.push("Internal phone number is Incorrect");
  if (!merchantData.contact_info.email) errors.push("valid email is required");

  if (
    !merchantData.logo ||
    !merchantData.listing_image ||
    !merchantData.cover_image
  )
    errors.push("images are required");

  if (
    !merchantData.country_id ||
    !merchantData.state_id ||
    !merchantData.city_id ||
    !merchantData.area_id
  )
    errors.push("Region information is required");

  return { isValid: !errors.length, errors };
};
export default validateMerchantForm;
