import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import HeadlessSelect from "../components/headless/select.headless";
import { FORM_TYPE } from "constants/app.constants";
import { RATING_STATUS } from "constants/app.constants";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

const renderRatingStars = (rating = 0) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(
        <svg
          key={i}
          class={`${i !== 0 ? "ms-1" : ""}  h-4 w-4 text-yellow-300`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 22 20"
        >
          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
        </svg>
      );
    } else {
      stars.push(
        <svg
          key={i}
          class={`${
            i !== 0 ? "ms-1" : ""
          } h-4 w-4 text-gray-300 dark:text-gray-500`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 22 20"
        >
          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
        </svg>
      );
    }
  }
  return <div className="flex items-center">{stars}</div>;
};

const initialRatingData = {
  merchant_id: {
    name: "",
    logo: "",
  },
  customer_id: {
    email_verified: false,
    name: "",
    phone_number_verified: false,
  },
  ratings: 0,
  message: "",
  picture: "",
  status: "",
};

const RatingForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // redux-state
  const { loading } = useSelector((state) => state.currencies);

  const [ratingData, setRatingData] = useState(initialRatingData);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    setRatingData((prevData) => ({ ...prevData, ...initialFormData }));
  }, [initialFormData]);

  const onChangeFormData = (name, value) => {
    setRatingData((prevData) => ({ ...prevData, [name]: value }));
  };

  // submit handler
  const handleSubmit = (redirect) => {
    let payload = { status: ratingData.status ?? "" };
    if (payload.id) delete payload.id;

    handleFormSubmit(payload, redirect);
  };

  return (
    <div className="h-full w-full bg-white">
      {" "}
      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.RATING}
          entityId={ratingData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/rating")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected Customer
              Reviews page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}
      <CardHeader
        label={`${formType} Customer Review`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/rating"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => handleSubmit(false),
                  variant: "primary",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
                // {
                //   text: "Delete",
                //   onClick: () => handleDelete(),
                //   variant: "danger",
                // },
              ]
            : []),
        ]}
      />
      <div className="flex flex-col gap-3 p-4">
        {/* image */}
        {ratingData.picture ? (
          <div className="flex h-full w-full flex-col items-center justify-between self-center rounded-md border border-gray-300 p-2">
            <label
              for="image"
              className="mb-1 block text-lg font-medium text-gray-800"
            >
              Image
            </label>
            <div className="flex min-w-[100px] max-w-[100px]  flex-col justify-between gap-2 sm:flex-row">
              <div className="flex items-center justify-center ">
                <img
                  className="h-full w-full rounded-md object-contain"
                  src={ratingData.picture}
                  alt=""
                  onClick={() => window.open(ratingData.picture, "_blank")}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="flex flex-col justify-between gap-2 md:flex-row">
          {/* Merchant */}
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Merchant
            </label>
            <div className="flex items-center gap-2 text-base font-medium leading-5 text-gray-500">
              <div className=" h-10  w-9">
                <img
                  className="h-9 w-9 rounded-full"
                  src={ratingData?.merchant_id?.logo || ""}
                  alt=""
                />
              </div>
              {ratingData?.merchant_id?.name}
            </div>
          </div>
          {/* Customer */}
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Customer
            </label>
            <div className="flex items-center gap-2 text-base font-medium leading-5 text-gray-500">
              <div className=" h-10  w-9 ">
                <img
                  className="h-9 w-9 rounded-full"
                  src={ratingData?.customer_id?.profile_pic || ProfileIcon}
                  alt=""
                />
              </div>
              {ratingData?.customer_id?.name}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          {/* Message */}
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Message
            </label>
            <div className="w-full flex-wrap py-1 text-base font-medium leading-5 text-gray-500">
              {ratingData?.message}
            </div>
          </div>
          {/* Rating */}
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Rating
            </label>
            <div className="w-full flex-wrap py-1 text-base font-medium leading-5 text-gray-500">
              {renderRatingStars(ratingData?.ratings)}
            </div>
          </div>
        </div>
        {/* status */}
        <div className="flex flex-auto flex-col items-start ">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Status
          </label>
          <HeadlessSelect
            disable={loading}
            title={"select status"}
            options={Object.values(RATING_STATUS)}
            selected={ratingData.status}
            onSelect={(value) => onChangeFormData("status", value)}
          />
        </div>

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
          rounded-md px-6 py-2 font-semibold text-white focus:outline-none
          ${
            loading
              ? "bg-indigo-400 hover:bg-indigo-400"
              : "bg-indigo-500 hover:bg-indigo-600"
          }
          `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default RatingForm;
