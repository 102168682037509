import { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";

const UpdateDevicePIN = ({ handleSubmit, handleCancel }) => {
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPIN] = useState("");
  const [error, setError] = useState("");

  const onConfirm = () => {
    setError("");
    if (!pin || !confirmPin) return setError("PIN and confirm PIN is required");
    if (!pin || pin.length !== 4 || !confirmPin || pin.length !== 4) {
      return setError("Please type exactly a 4-digit PIN");
    }
    if (pin !== confirmPin)
      return setError("confirm PIN should be same as PIN");
    handleSubmit({ pin, confirmPin });
    handleCancel();
  };

  return (
    <div className="h-full w-full bg-white p-4">
      <ConfirmationModal
        onBack={handleCancel}
        onConfirm={onConfirm}
        confirmBtnText=""
      >
        <div className="flex flex-col gap-4">
          <h1>Update PIN</h1>
        </div>
        <div className="flex flex-col justify-between gap-2">
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              PIN
            </label>
            <input
              type="password"
              name="pin"
              placeholder="Type PIN"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Confirm PIN
            </label>
            <input
              type="password"
              name="confirmPin"
              placeholder="Confirm PIN"
              value={confirmPin}
              onChange={(e) => setConfirmPIN(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
          <div className="flex flex-auto flex-col items-start">
            {error ? <p className="text-red-500">{error}</p> : null}
          </div>
        </div>
      </ConfirmationModal>
    </div>
  );
};

export default UpdateDevicePIN;
