import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "../components/cardHeader";
import MerchantTypeForm from "./merchantTypeForm";
import { updateMerchantType } from "actions/merchants/merchants.actions";
import { getMerchantType } from "actions/merchants/merchants.actions";
import { updateLanguageMerchantType } from "actions/merchants/merchants.actions";
import { FORM_TYPE } from "constants/app.constants";
import Loader from "components/loaderSpinner/Loader";
import ConfirmationModal from "../components/ConfirmationModal";
import { deleteMerchantType } from "actions/merchants/merchants.actions";

const UpdateMerchantType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { merchantTypeId } = useParams();
  const { type } = useSelector((state) => state.merchant);

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = (type) => {
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteMerchantType(type.id, navigate));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
  };

  useEffect(() => {
    dispatch(getMerchantType(merchantTypeId));
  }, [merchantTypeId]);

  const handleSubmit = (payload, redirect) => {
    if (payload.lang_id) {
      dispatch(
        updateLanguageMerchantType(
          merchantTypeId,
          payload,
          redirect === false ? null : navigate
        )
      );
      return;
    }
    dispatch(
      updateMerchantType(
        merchantTypeId,
        payload,
        redirect === false ? null : navigate
      )
    );
  };

  if (!type || type.id !== merchantTypeId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the "{type.name}" merchant type</h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <MerchantTypeForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={type}
        handleFormSubmit={handleSubmit}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateMerchantType;
