import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const signin = (credentials) => {
  return RestClient.Post(API_URL.USER_SIGNIN, credentials);
};

const validateUser = () => {
  return RestClient.Get(API_URL.USER_VALIDATE);
};

const logout = () => {
  return RestClient.Get(API_URL.USER_LOGOUT);
};

const requestForgetPassword = (payload) => {
  return RestClient.Post(API_URL.REQUEST_FORGOT_PASSWORD, payload);
};

const validateUserOTP = (payload) => {
  return RestClient.Post(API_URL.FORGOT_PASSWORD_OTP_VALIDATE, payload);
};

const resetPassword = (payload) => {
  return RestClient.Post(API_URL.RESET_PASSWORD, payload);
};

const exportedObject = {
  signin,
  validateUser,
  logout,
  requestForgetPassword,
  validateUserOTP,
  resetPassword,
};

export default exportedObject;
