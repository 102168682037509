import { AlertTypes } from "../../reducers/alert/alert.reducer";
import DeviceService from "../../services/devices/DeviceService";
import * as ActionTypes from "../actionTypes";
import { setAlert } from "../alert/alert.actions";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_DEVICE_LOADING,
    payload: loading,
  });
};

export const setDevices = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_DEVICES,
    payload: data,
  });
};

export const setDevice = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_DEVICE,
    payload: data,
  });
};

export const setDeviceEnableFlag = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_DEVICE_ENABLE_FLAG,
    payload: data,
  });
};

export const setDeviceIntegrationFlag = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_DEVICE_INTEGRATION_FLAG,
    payload: data,
  });
};

export const removeDevice = (deviceId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_DEVICE,
    payload: deviceId,
  });
};

export const resetDeviceState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_DEVICE_STATE,
  });
};

export const getDevices = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    response = await DeviceService.getDevices(page, limit);

    if (response.data && response.status == 1) {
      dispatch(setDevices(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Devices", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDevice = (deviceId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    response = await DeviceService.getDevice(deviceId);
    if (response.data && response.status == 1) {
      dispatch(setDevice(response.data.device));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Device", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createDevice = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    response = await DeviceService.createDevice(payload);
    if (response.data && response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Device created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/devices");
    }
    dispatch(
      setAlert(response.message ?? "failed to create Device", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateDevice =
  (deviceId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      let response;
      response = await DeviceService.updateDevice(deviceId, payload);
      if (response.data && response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "Device updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/devices");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update Device",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateDevicePin = (deviceId, payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    response = await DeviceService.updateDevicePin(deviceId, payload);

    if (response.data && response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Device PIN updated successfully",
          AlertTypes.SUCCESS
        )
      );
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to update Device PIN",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateEnableFlag = (deviceId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    response = await DeviceService.updateDeviceEnableFlag(deviceId);

    if (response.data && response.status == 1) {
      dispatch(setDeviceEnableFlag(response.data.device));
      dispatch(
        setAlert(
          response.message ?? "Device Enable Status updated successfully",
          AlertTypes.SUCCESS
        )
      );
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to update Device Enable Status",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateIntegrationFlag = (deviceId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    response = await DeviceService.updateDeviceIntegrationFlag(deviceId);

    if (response.data && response.status == 1) {
      dispatch(setDeviceIntegrationFlag(response.data.device));
      dispatch(
        setAlert(
          response.message ?? "Device integration Status updated successfully",
          AlertTypes.SUCCESS
        )
      );
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to update Device Enable Status",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteDevice = (deviceId, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    let response;
    response = await DeviceService.deleteDevice(deviceId);

    if (response.data && response.status == 1) {
      dispatch(removeDevice(deviceId));
      dispatch(
        setAlert(
          response.message ?? "Device deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/devices");
    }
    dispatch(
      setAlert(response.message ?? "failed to delete Device", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
