import { useDispatch, useSelector } from "react-redux";
import RatingsTableCard from "./ratings-table-card";
import { useEffect, useState } from "react";
import { MdFilterAltOff } from "react-icons/md";
import { CiFilter } from "react-icons/ci";
import { getRatings } from "actions/rating/RatingActions";
import { RATING_STATUS } from "constants/app.constants";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const Ratings = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const { ratingsCount, ratings, loading } = useSelector(
    (state) => state.rating
  );

  const optionAll = { name: "All", value: "" };

  const getFilteredRatings = () => {
    dispatch(getRatings(page, limit, status));
  };
  // dispatch(updateRating(id,payload));

  useEffect(() => getFilteredRatings(), [page]);

  const removeAllFilters = () => {
    setPage(1);
    setStatus("");
    dispatch(getRatings(1, limit));
  };

  return (
    <div className="h-full w-full">
      <RatingsTableCard ratings={ratings} loading={loading}>
        {/* filter buttons */}
        <div className="flex items-center justify-end gap-2 p-2">
          <div className="w-auto">
            <button
              disabled={loading}
              onClick={removeAllFilters}
              className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
              type="button"
            >
              <MdFilterAltOff className="h-4 w-4" />
              {"Clear Filters"}
            </button>
          </div>
          <div className="w-auto">
            <button
              disabled={loading}
              onClick={getFilteredRatings}
              className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
              type="button"
            >
              <CiFilter className="h-4 w-4" />
              {"Apply Filters"}
            </button>
          </div>
        </div>

        <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
          {/* status */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full ">
              <HeadlessSelectObject
                disable={loading}
                title={"All Customer Reviews"}
                options={[
                  optionAll,
                  ...Object.values(RATING_STATUS).map((status) => ({
                    name: status,
                    value: status,
                  })),
                ]}
                selected={status}
                onSelect={setStatus}
              />
            </div>
          </div>
        </div>
      </RatingsTableCard>

      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={ratingsCount}
      />
    </div>
  );
};

export default Ratings;
