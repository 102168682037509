import Card from "components/card";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import LanguagesFilters from "./filters";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";

function LanguagesTableCard({
  languages,
  onClickAddNew,
  onClickEdit,
  onClickDelete,
}) {
  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      {"Name"}
    </div>,
    "Code",
    "Writing",
    "Locale",
    "Status",
    "Created By",
    "Actions",
  ];
  const records = languages.map((language) => ({
    flag_image: (
      <div className="flex items-center gap-2">
        <div className="flex h-10 w-10 items-center justify-center">
          <img
            className="h-9 w-9  rounded-full"
            src={language.flag_image}
            alt=""
          />
        </div>
        {language.name.split(" ").join("\u00A0")}
      </div>
    ),
    code: language.code,
    isRTL: language.isRTL ? "RTL" : "LTR",
    locale: language.locale,
    status: language.status,
    created_by: language?.created_by?.name ?? "",
    actions: (
      <div className="flex items-center  gap-2">
          {hasPermission(
          [PERSMISSIONS_TYPES.LANGUAGE_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
          onClick={() => onClickEdit(language.id)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.LANGUAGE_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
          onClick={() => onClickDelete(language)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));

  return (
    <div>
      <Card extra={"h-full  w-full bg-white"}>
        <CardHeader
          label={"Languages"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.LANGUAGE_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [{
                  text: "Add New",
                  onClick: onClickAddNew,
                variant: "primary",
                }]
              : null
          }
        />
        <LanguagesFilters />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default LanguagesTableCard;
