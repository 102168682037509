import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../components/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import CartRulesTableCard from "./cart-rules-table-card";
import { getRules } from "actions/cartRules/cartRules.action";
import { deleteRule } from "actions/cartRules/cartRules.action";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const CartRules = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rulesCount, rules, loading } = useSelector((state) => state.rule);
  const [showDelModal, setShowDelModal] = useState(false);
  const [delRule, setDelRule] = useState(null);

  useEffect(() => {
    dispatch(getRules(page, limit));
  }, [page]);

  const handleCancel = () => {
    setShowDelModal(false);
    setDelRule(null);
  };

  const onClickDelete = (cart_rule) => {
    setDelRule(cart_rule);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteRule(delRule.id));
    handleCancel();
  };

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the Rule with name "{delRule?.name ?? ""}"
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}

      <CartRulesTableCard
        rules={rules}
        onClickAddNew={() => navigate("/cartRules/create")}
        onClickEdit={(ruleId) => navigate(`/cartRules/update/${ruleId}`)}
        onClickDelete={onClickDelete}
      />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={rulesCount}
      />
    </div>
  );
};

export default CartRules;
