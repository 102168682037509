import Loader from "components/loaderSpinner/Loader";
import { FORM_TYPE } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import ConfirmationModal from "../components/ConfirmationModal";
import RatingForm from "./ratingForm";
import { getRating } from "actions/rating/RatingActions";
import { updateRating } from "actions/rating/RatingActions";

const UpdateRating = () => {
  const { ratingId } = useParams();

  // reduxState
  const { rating } = useSelector((state) => state.rating);

  // localState
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = () => {
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    // dispatch(delete(rating.id, navigate));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
  };

  useEffect(() => {
    dispatch(getRating(ratingId));
  }, [ratingId]);

  const handleSubmit = (payload, redirect) => {
    dispatch(
      updateRating(ratingId, payload, redirect === false ? null : navigate)
    );
  };

  if (!rating || rating.id !== ratingId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the Rating "</h1>
          </div>
        </ConfirmationModal>
      ) : null}

      <RatingForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={rating}
        handleFormSubmit={handleSubmit}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateRating;
