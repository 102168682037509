import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import StoreCategoryservice from "../../services/storeCategories/StoreCategoryService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORE_CATEGORY_LOADING,
    payload: loading,
  });
};

export const setCategories = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORE_CATEGORIES,
    payload: data,
  });
};

export const setCategory = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORE_CATEGORY,
    payload: data,
  });
};

export const editCategory = (category) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_STORE_CATEGORY,
    payload: category,
  });
};

export const removeCategory = (categoryId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_STORE_CATEGORY,
    payload: categoryId,
  });
};

export const resetCategoryState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_STORE_CATEGORY_STATE,
  });
};

export const getStoreCategories = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreCategoryservice.getStoreCategories(page, limit);
    if (response.status == 1) {
      dispatch(setCategories(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load categories",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getStoreCategory = (categoryId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreCategoryservice.getStoreCategory(categoryId);
    if (response.data && response.status == 1) {
      dispatch(setCategory(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Category", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createStoreCategory = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreCategoryservice.createStoreCategory(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "category created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/storeCategories");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to create category",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateStoreCategory =
  (categoryId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await StoreCategoryservice.updateStoreCategory(
        categoryId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "category updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/storeCategories");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update category",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateMultiLangStoreCategory =
  (categoryId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await StoreCategoryservice.updateMultiLangStoreCategory(
        categoryId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "category updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/storeCategories");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update category",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteStoreCategory =
  (categoryId, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await StoreCategoryservice.deleteStoreCategory(
        categoryId
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "category deleted successfully",
            AlertTypes.SUCCESS
          )
        );
        dispatch(removeCategory(categoryId));
        if (!navigate) return;
        return navigate("/storeCategories");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to delete category",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };
