import { getMerchantPromotionTag, updateMerchantPromotionTag, updateMultiLangMerchantPromotionTag } from "actions/merchantPromotionTags/merchantPromotionTags.action";
import { FORM_TYPE } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MerchantPromotionTagForm from "./merchantPromotionTagForm";
import Loader from "components/loaderSpinner/Loader";
import { deleteMerchantPromotionTag } from "actions/merchantPromotionTags/merchantPromotionTags.action";
import ConfirmationModal from "../components/ConfirmationModal";

const UpdateMerchantPromotionTag = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { merchantPromotionTagId } = useParams();
  const { promotionTag } = useSelector((state) => state.merchantPromotionTag);

  useEffect(() => {
    dispatch(getMerchantPromotionTag(merchantPromotionTagId));
  }, [merchantPromotionTagId]);

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = () => {
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteMerchantPromotionTag(promotionTag.id, navigate));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
  };

  const handleSubmit = (formData, redirect) => {
    if (formData.get("lang_id")) {
      dispatch(
        updateMultiLangMerchantPromotionTag(
          merchantPromotionTagId,
          formData,
          redirect === false ? null : navigate
        )
      );
      return;
    }
    dispatch(
      updateMerchantPromotionTag(
        merchantPromotionTagId,
        formData,
        redirect === false ? null : navigate
      )
    );
  };

  if (!promotionTag || promotionTag.id !== merchantPromotionTagId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );
  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the "{promotionTag.description}" promotion
              tag
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <MerchantPromotionTagForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={promotionTag}
        handleFormSubmit={handleSubmit}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateMerchantPromotionTag;
