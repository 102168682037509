import React, { useEffect, useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import Loader from "components/loaderSpinner/Loader";
import Card from "components/card";
import Table from "./table";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";
import { useDispatch, useSelector } from "react-redux";
import { formatLocalTimeDate } from "utils/App.utils";
import { FaArrowRight, FaEye } from "react-icons/fa";
import { getLogs } from "actions/ActivityLogs/logs.action";
import CardHeader from "./cardHeader";
import { hasPermission } from "utils/permissions";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import { useNavigate } from "react-router-dom";
import ReactJsonViewCompare from "react-json-view-compare";

const getLogTypeClasses = (status) => {
  switch (status) {
    case "CREATE":
      return "bg-green-200 text-green-800";
    case "UPDATE":
      return "bg-yellow-200 text-yellow-800";
    case "DELETE":
      return "bg-red-200 text-red-800";
    default:
      return "bg-gray-200 text-gray-800";
  }
};

const ActivitylogsModal = ({ entityName, entityId, onBack }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, activityLogs } = useSelector((state) => state.logs);

  const [log, setLog] = useState({});
  const [showCompareModal, setShowCompareModal] = useState(false);

  useEffect(() => {
    if (!entityName || !entityId) return;
    dispatch(getLogs(entityName, entityId));
  }, [entityName, entityId]);

  const handleViewChanges = (logId) => {
    const foundLog = activityLogs.find((log) => log.id === logId);
    if (foundLog) {
      setLog(foundLog);
      setShowCompareModal(true);
    }
  };

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      <div className="flex items-center gap-1">
        <p>Updated&nbsp;By</p>
      </div>
    </div>,
    "Updated At",
    "Action Type",
    "Actions",
  ];

  const records = activityLogs.map((log) => ({
    updated_by: (
      <div
        className="flex cursor-pointer items-center gap-2"
        onClick={() => navigate(`/customer/profile/${log?.updated_by?.id}`)}
      >
        <div className="flex h-10 w-10 items-center justify-center">
          <img className="h-9 w-9 rounded-full" src={ProfileIcon} alt="" />
        </div>
        {log?.updated_by?.name ?? "---"}
      </div>
    ),
    updated_at: log.updated_at ? formatLocalTimeDate(log.updated_at) : "---",
    action_type: (
      <span
        className={`rounded-full  px-2 py-1 text-xs font-semibold ${getLogTypeClasses(
          log?.action
        )}`}
      >
        {log?.action ?? ""}
      </span>
    ),
    actions: (
      <div className="flex items-center  gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <button
            onClick={() => handleViewChanges(log.id)}
            className={`
          cursor-pointer' flex h-8 items-center justify-center gap-1 rounded-lg border border-gray-300 bg-white px-3
           text-sm font-medium text-gray-500 hover:text-gray-700
          `}
          >
            <span>View&nbsp;Changes</span>
            <FaArrowRight className="h-3.5 w-3.5" />
          </button>
        ) : null}
      </div>
    ),
  }));

  return (
    <>
      <ConfirmationModal
        onBack={onBack}
        onConfirm={onBack}
        confirmBtnText="OK"
        confirmBtnType="success"
      >
        <div className="flex h-96 w-full flex-col overflow-y-scroll">
          {loading ? (
            <div className="flex h-full w-full items-center justify-center">
              <Loader />
            </div>
          ) : (
            <>
              <div className="flex w-full items-center justify-center pb-1 text-xl font-semibold">
                Activity Logs
              </div>
              <Card extra={" bg-white"}>
                {/* <CardHeader label={"Activity Logs"} /> */}
                <Table
                  tableData={{
                    headers: tableHeaders,
                    records,
                  }}
                />
              </Card>
            </>
          )}
        </div>
      </ConfirmationModal>
      {showCompareModal ? (
        <ConfirmationModal
          onBack={() => setShowCompareModal(false)}
          onConfirm={() => setShowCompareModal(false)}
          confirmBtnText="OK"
          confirmBtnType="success"
        >
          <div className="flex flex-col overflow-y-scroll ">
            <ReactJsonViewCompare
              oldData={log?.old_doc ?? {}}
              newData={log?.new_doc ?? {}}
            />
          </div>
        </ConfirmationModal>
      ) : null}
    </>
  );
};

export default ActivitylogsModal;
