import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import LanguageService from "../../services/languages/LanguageService";
import * as ActionTypes from "../../actions/actionTypes";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_LANGUAGE_LOADING,
    payload: loading,
  });
};

export const setLanguages = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_LANGUAGES,
    payload: data,
  });
};

export const setLanguage = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_LANGUAGE,
    payload: data,
  });
};

export const addLanguage = (language) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_LANGUAGE,
    payload: language,
  });
};

export const editLanguage = (language) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_LANGUAGE,
    payload: language,
  });
};

export const removeLanguage = (language_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_LANGUAGE,
    payload: language_id,
  });
};

export const resetLanguageState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_LANGUAGE_STATE,
  });
};

export const getLanguages =
  (page, limit, lang_id, name, code, isRTL, locale, status) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await LanguageService.getLanguages(
        page,
        limit,
        lang_id,
        name,
        code,
        isRTL,
        locale,
        status
      );
      if (response.status == 1 && response.data) {
        dispatch(setLanguages(response.data));
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load languages",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getLanguage = (language_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await LanguageService.getLanguage(language_id);
    if (response.status == 1 && response.data) {
      dispatch(setLanguage(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load language", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createLanguage = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await LanguageService.createLanguage(payload);
    if (response.status == 1 && response.data) {
      dispatch(
        setAlert(
          response.message ?? "language created successfully",
          AlertTypes.SUCCESS
        )
      );
      // dispatch(addLanguage(response.data.newLang));
      if (!navigate) return;
      return navigate("/languages");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to create language",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateLanguage =
  (language_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await LanguageService.updateLanguage(
        language_id,
        payload
      );
      if (response.status == 1 && response.data) {
        dispatch(
          setAlert(
            response.message ?? "language updated successfully",
            AlertTypes.SUCCESS
          )
        );
        dispatch(editLanguage(response.data.updatedLang));
        if (!navigate) return;
        return navigate("/languages");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update language",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateMultiLangLanguage =
  (language_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await LanguageService.updateMultiLangLanguage(
        language_id,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "language localization updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) {
          return dispatch(getLanguage(language_id));
        }
        return navigate("/languages");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update language localization",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteLanguage = (language_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await LanguageService.deleteLanguage(language_id);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "language deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeLanguage(language_id));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to delete language",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
