import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CardHeader from "../components/cardHeader";
import { createTicket } from "actions/tickets/tickets.action";
import ClassificationForm from "./classificationForm";
import { createClassification } from "actions/customerClassification/customerClassification.actions";
import { FORM_TYPE } from "constants/app.constants";

const CreateClassification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="h-full w-full">
      <ClassificationForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createClassification(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateClassification;
