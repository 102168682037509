import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import TransactionService from "../../services/transactions/TransactionService";
import * as ActionTypes from "../actionTypes";
import { downloadFile } from "utils/App.utils";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_TRANSACTION_LOADING,
    payload: loading,
  });
};

export const setTransactions = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_TRANSACTIONS,
    payload: data,
  });
};

export const setTransaction = (transaction) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_TRANSACTION,
    payload: transaction,
  });
};

export const resetTransactionState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_TRANSACTION_STATE,
  });
};

export const getTransactions =
  (
    page,
    limit,
    merchant_id,
    customer_id,
    country_id,
    state_id,
    city_id,
    startDate,
    endDate,
    order_number
  ) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await TransactionService.GetAllTransaction(
        page,
        limit,
        merchant_id,
        customer_id,
        country_id,
        state_id,
        city_id,
        startDate,
        endDate,
        order_number
      );
      if (response.status == 1 && response.data) {
        dispatch(setTransactions(response.data));
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load transactions",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const exportTransactions =
  (
    merchant_id,
    customer_id,
    country_id,
    state_id,
    city_id,
    startDate,
    endDate,
    order_number
  ) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await TransactionService.exportTransactions(
        merchant_id,
        customer_id,
        country_id,
        state_id,
        city_id,
        startDate,
        endDate,
        order_number
      );
      if (response.status == 0) {
        dispatch(
          setAlert(
            response.message ??
              "Something went wrong while downloading the CSV",
            AlertTypes.ERROR
          )
        );
        return;
      }
      downloadFile(response, "transactions.csv");
    } catch (error) {
      dispatch(
        setAlert(
          "Something went wrong while downloading the CSV",
          AlertTypes.ERROR
        )
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
