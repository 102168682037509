import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BrandTableCard from "./brand-table-card";
import { getBrands } from "actions/Brands/brands.action";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const Brand = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { brandsCount, brands, loading } = useSelector((state) => state.brand);

  useEffect(() => {
    dispatch(getBrands(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <BrandTableCard brands={brands} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={brandsCount}
      />
    </div>
  );
};

export default Brand;
