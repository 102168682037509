import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  promotionTagsCount: 0,
  promotionTags: [],
  promotionTag: null,
  promotionTagMultiLang: {},
  loading: false,
};

export const merchantPromotionTagsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_MERCHANT_PROMOTION_TAGS:
      return {
        ...state,
        promotionTagsCount: payload?.meta?.total_documents || 0,
        promotionTags: payload.promotions,
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_PROMOTION_TAG:
      return {
        ...state,
        promotionTag: payload.promotionTag,
        promotionTagMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_MERCHANT_PROMOTION_TAG:
      return {
        ...state,
        promotionTagsCount: state.promotionTagsCount - 1,
        promotionTags: state.promotionTags.filter(
          (promotionTag) => promotionTag.id !== payload
        ),
        loading: false,
      };
    case ActionTypes.SET_MERCHANT_PROMOTION_TAG_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_MERCHANT_PROMOTION_TAG_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
