import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "actions/roles/roles.actions";
import RolesTableCard from "./roles-table-card";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const Roles = () => {
  const dispatch = useDispatch();
  const { rolesCount, roles } = useSelector((state) => state.role);
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;

  useEffect(() => {
    dispatch(getRoles(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <RolesTableCard roles={roles} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={rolesCount}
      />
    </div>
  );
};

export default Roles;
