import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getBrands = (page = 1, limit = 50) => {
  return RestClient.Get(`${API_URL.GET_BRANDS}?page=${page}&limit=${limit}`);
};
const getBrand = (brand_id) => {
  return RestClient.Get(`${API_URL.GET_BRAND}/${brand_id}`);
};

const createBrand = (payload) => {
  return RestClient.Post(API_URL.CREATE_BRAND, payload);
};

const updateBrand = (brand_id, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_BRAND}/${brand_id}`, payload);
};

const updateBrandPassword = (brand_id, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_BRAND_PASSWORD}/${brand_id}`,
    payload
  );
};

const updateMultiLangBrand = (brand_id, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_LANGUAGE_BRAND}/${brand_id}`,
    payload
  );
};

const deleteBrand = (brand_id) => {
  return RestClient.Delete(`${API_URL.DELETE_BRAND}/${brand_id}`);
};

const exportedObject = {
  getBrands,
  getBrand,
  createBrand,
  updateBrand,
  updateBrandPassword,
  updateMultiLangBrand,
  deleteBrand,
};

export default exportedObject;
