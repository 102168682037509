import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/pagination";
import DeviceTableCard from "./device-table-card";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import { getDevices } from "actions/devices/devices.action";
import { deleteDevice } from "actions/devices/devices.action";

const Devices = () => {
  const [page, setPage] = useState(1);
  const limit = 50;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deviceCount, devices, loading } = useSelector(
    (state) => state.device
  );
  const [showDelModal, setShowDelModal] = useState(false);
  const [delDevice, setDelDevice] = useState(null);

  useEffect(() => {
    dispatch(getDevices(page, limit));
  }, [page]);

  const handleCancel = () => {
    setShowDelModal(false);
    setDelDevice(null);
  };

  const onClickDelete = (device) => {
    setDelDevice(device);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteDevice(delDevice.id));
    handleCancel();
  };

  return (
    <div className="flex h-full w-full flex-col  ">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the vibe Device with name "
              {delDevice?.name ?? ""}"
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}

      <DeviceTableCard
        devices={devices}
        onClickAddNew={() => navigate("/devices/create")}
        onClickEdit={(deviceId) => navigate(`/devices/update/${deviceId}`)}
        onClickDelete={onClickDelete}
      />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={deviceCount}
      />
    </div>
  );
};

export default Devices;
