import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "components/loaderSpinner/Loader";
import { FORM_TYPE } from "constants/app.constants";
import ConfirmationModal from "../components/ConfirmationModal";
import BrandForm from "./brandForm";
import { deleteBrand } from "actions/Brands/brands.action";
import { getBrand } from "actions/Brands/brands.action";
import { updateBrand } from "actions/Brands/brands.action";

const UpdateBrand = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { brand_id } = useParams();
  const { brand } = useSelector((state) => state.brand);

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = () => {
    setShowDelModal(true);
  };

  const handleCancelDelete = () => {
    setShowDelModal(false);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteBrand(brand_id, navigate));
    handleCancelDelete();
  };

  useEffect(() => {
    dispatch(getBrand(brand_id));
  }, [brand_id]);

  const handleSubmit = (formData, redirect) => {
    // if (formData.get("lang_id")) {
    //   dispatch(
    //     updateMultiLangSlider(
    //       brand_id,
    //       formData,
    //       shouldRedirect ? navigate : null
    //     )
    //   );
    //   return;
    // }
    dispatch(
      updateBrand(brand_id, formData, redirect === false ? null : navigate)
    );
  };

  if (!brand_id || brand?.id !== brand_id)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the brand with name {brand.name}</h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <BrandForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={brand}
        handleFormSubmit={handleSubmit}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateBrand;
