import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../../actions/actionTypes";
import StoreLoyaltyService from "../../services/storeLoyalties/StoreLoyaltyService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORE_LOYALTY_LOADING,
    payload: loading,
  });
};

export const setStoreLoyalties = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORE_LOYALTIES,
    payload: data,
  });
};

export const setStoreLoyalty = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORE_LOYALTY,
    payload: data,
  });
};

export const removeStoreLoyalty = (storeloyalty_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_STORE_LOYALTY,
    payload: storeloyalty_id,
  });
};

export const resetStoreLoyaltyState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_STORE_LOYALTY_STATE,
  });
};

export const getStoreLoyalties = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreLoyaltyService.getStoreLoyalties(page, limit);
    if (response.status == 1) {
      dispatch(setStoreLoyalties(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load store loyalties",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getStoreLoyalty = (storeloyalty_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreLoyaltyService.getStoreLoyalty(storeloyalty_id);
    if (response.data && response.status == 1) {
      dispatch(setStoreLoyalty(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load store loyalty",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createStoreLoyalty = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreLoyaltyService.createStoreLoyalty(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "store loyalty created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/storeLoyalty");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to create store loyalty",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateStoreLoyalty =
  (storeloyalty_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await StoreLoyaltyService.updateStoreLoyalty(
        storeloyalty_id,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "store loyalty updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) {
          return dispatch(getStoreLoyalty(storeloyalty_id));
        }
        return navigate("/storeLoyalty");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update store loyalty",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateMultiLangStoreLoyalty =
  (storeloyalty_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await StoreLoyaltyService.updateMultiLangStoreLoyalty(
        storeloyalty_id,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ??
              "store loyalty localization updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) {
          return dispatch(getStoreLoyalty(storeloyalty_id));
        }
        return navigate("/storeLoyalty");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update slider",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteStoreLoyalty =
  (storeloyalty_id, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await StoreLoyaltyService.deleteStoreLoyalty(
        storeloyalty_id
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "store loyalty deleted successfully",
            AlertTypes.SUCCESS
          )
        );
        dispatch(removeStoreLoyalty(storeloyalty_id));
        if (!navigate) return;
        return navigate("/storeLoyalty");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to delete store loyalty",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };
