import { updateLanguage } from "actions/languages/languages.actions";
import Loader from "components/loaderSpinner/Loader";
import { FORM_TYPE } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LanguageForm from "./languageForm";
import { updateMultiLangLanguage } from "actions/languages/languages.actions";
import { getLanguage } from "actions/languages/languages.actions";

const UpdateLanguage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { language_id } = useParams();
  const { loading, language } = useSelector((state) => state.language);

  useEffect(() => {
    dispatch(getLanguage(language_id));
  }, [language_id]);

  const handleSubmit = (formData) => {
    if (formData.get("lang_id")) {
      dispatch(
        updateMultiLangLanguage(language_id, formData, navigate)
      );
      return;
    }
    dispatch(updateLanguage(language_id, formData, navigate))
  };

  if (!language || language.id !== language_id)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      <LanguageForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={language}
        handleFormSubmit={handleSubmit}
      />
    </div>
  );
};

export default UpdateLanguage;
