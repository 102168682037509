import { updateStoreLoyalty } from "actions/storeLoyalties/storeLoyalties.action";
import { FORM_TYPE } from "constants/app.constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StoreLoyaltyForm from "./storeLoyaltyForm";
import { getStoreLoyalty } from "actions/storeLoyalties/storeLoyalties.action";
import { useEffect, useState } from "react";
import Loader from "components/loaderSpinner/Loader";
import { deleteStoreLoyalty } from "actions/storeLoyalties/storeLoyalties.action";
import ConfirmationModal from "../components/ConfirmationModal";
import { updateMultiLangStoreLoyalty } from "actions/storeLoyalties/storeLoyalties.action";

const UpdateStoreLoyalty = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storeloyalty_id } = useParams();
  const { storeLoyalty } = useSelector((state) => state.storeLoyalty);

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = () => {
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteStoreLoyalty(storeloyalty_id, navigate));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
  };

  useEffect(() => {
    dispatch(getStoreLoyalty(storeloyalty_id));
  }, [storeloyalty_id]);

  const handleSubmit = (formData, redirect) => {
    if (formData.get("lang_id")) {
      dispatch(
        updateMultiLangStoreLoyalty(
          storeloyalty_id,
          formData,
          redirect === false ? null : navigate
        )
      );
      return;
    }
    dispatch(
      updateStoreLoyalty(storeloyalty_id, formData, redirect === false ? null : navigate)
    );
  };

  if (!storeLoyalty || storeLoyalty.id !== storeloyalty_id)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );
  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the "{storeLoyalty.name}" store loyalty
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <StoreLoyaltyForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={storeLoyalty}
        handleFormSubmit={handleSubmit}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateStoreLoyalty;
