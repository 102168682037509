
const ToggleSwitch = ({ isEnabled, handleChange, disabled = false }) => {
  return (
    <>
      <label className="flex cursor-pointer select-none items-center">
        <div className="relative">
          <input
            type="checkbox"
            checked={isEnabled}
            onChange={() => handleChange(!isEnabled)}
            className="sr-only"
            disabled={disabled}
          />
          <div
            className={`box block h-6 w-12 rounded-full ${
              isEnabled ? "bg-indigo-600" : "bg-gray-400"
            }`}
          ></div>
          <div
            className={`absolute left-1 top-1/2 flex h-5 w-5 -translate-y-1/2 items-center justify-center rounded-full bg-white transition ${
              isEnabled ? "translate-x-full" : ""
            }`}
          ></div>
        </div>
      </label>
    </>
  );
};

export default ToggleSwitch