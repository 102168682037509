import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CardHeader from "../components/cardHeader";
import TicketForm from "./ticketsForm";
import { createTicket } from "actions/tickets/tickets.action";

const CreateTicket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="h-full w-full">
      <CardHeader
        label={"Create Ticket"}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/tickets"),
          },
        ]}
      />
      <TicketForm
        handleFormSubmit={(formData) =>
          dispatch(createTicket(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateTicket;
