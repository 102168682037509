import axios, { HttpStatusCode } from "axios";
import LocalStorage from "../utils/LocalStorage";
import { store } from "../store/store";
import { userLogout } from "actions/auth/auth.actions";

const BASE_URL = process.env.REACT_APP_ADMIN_SERVER_URL;
const state = store.getState();

const axiosInstance = (token) => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      "device-id": state.auth.deviceId.toString(),
    },
  });

  // Apply the interceptor directly to the instance
  instance.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response.status === HttpStatusCode.Unauthorized) {
        store.dispatch(userLogout(false));
      }
      return Promise.reject(err);
    }
  );

  return instance;
};

const Get = async (host) => {
  console.log(`${BASE_URL}${host}`);
  var token = await LocalStorage.GetData("token");
  return axiosInstance(token)
    .get(host, {
      onDownloadProgress: (progressEvent) => {},
      onUploadProgress: (progressEvent) => {},
    })
    .then((response) => {
      return response.data;
    })
    .catch((error, r) => {
      return error.response.data;
    });
};

const Post = async (host, payload) => {
  console.log(`${BASE_URL}${host}`);
  var token = await LocalStorage.GetData("token");
  return axiosInstance(token)
    .post(host, payload, {
      onDownloadProgress: (progressEvent) => {},
      onUploadProgress: (progressEvent) => {},
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

const Put = async (host, payload) => {
  console.log(`${BASE_URL}${host}`);
  var token = await LocalStorage.GetData("token");
  return axiosInstance(token)
    .put(host, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

const Delete = async (host) => {
  console.log(`${BASE_URL}${host}`);
  var token = await LocalStorage.GetData("token");
  return axiosInstance(token)
    .delete(host)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};

export default { Get, Post, Put, Delete };
