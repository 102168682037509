import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SliderTableCard from "./slider-table-card";
import { getSliders } from "actions/sliders/sliders.action";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const Slider = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { slidersCount, sliders, loading } = useSelector(
    (state) => state.slider
  );

  useEffect(() => {
    dispatch(getSliders(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <SliderTableCard sliders={sliders} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={slidersCount}
      />
    </div>
  );
};

export default Slider;
