/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";
import logoBlack from "assets/img/Logo-black.png";
import logoWhite from "assets/img/Logo-white.png";

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex h-screen flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center`}>
        <div className="ml-1 mt-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          <img className="h-auto w-40 dark:hidden" src={logoBlack} />
          <img className="hidden h-auto w-40 dark:block" src={logoWhite} />
        </div>
      </div>
      {/* divider */}
      <div class="mb-[12px] mt-[58px] h-px bg-gray-300 dark:bg-white/30" />

      {/* Nav item */}
      <ul className="hide-scrollbar mb-auto overflow-y-scroll ">
        <Links routes={routes} />
      </ul>
      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
