import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../../actions/actionTypes";
import SliderService from "../../services/sliders/SliderService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SLIDER_LOADING,
    payload: loading,
  });
};

export const setSliders = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SLIDERS,
    payload: data,
  });
};

export const setSlider = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SLIDER,
    payload: data,
  });
};

export const editSlider = (slider) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_SLIDER,
    payload: slider,
  });
};

export const removeSlider = (sliderId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_SLIDER,
    payload: sliderId,
  });
};

export const resetSliderState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_SLIDER_STATE,
  });
};

export const getSliders = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SliderService.getSliders(page, limit);
    if (response.status == 1) {
      dispatch(setSliders(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load sliders", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getSlider = (sliderId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SliderService.getSlider(sliderId);
    if (response.data && response.status == 1) {
      dispatch(setSlider(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Slider", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createSlider = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SliderService.createSlider(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "slider created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/sliders");
    }
    dispatch(
      setAlert(response.message ?? "failed to create slider", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateSlider =
  (sliderId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await SliderService.updateSlider(sliderId, payload);
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "slider updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/sliders");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update slider",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateMultiLangSlider =
  (sliderId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await SliderService.updateMultiLangSlider(
        sliderId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "slider localization updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/sliders");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update slider",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteSlider = (sliderId, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SliderService.deleteSlider(sliderId);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "slider deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeSlider(sliderId));
      if (!navigate) return;
      return navigate("/sliders");
    }
    dispatch(
      setAlert(response.message ?? "failed to delete slider", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
