import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../../actions/actionTypes";
import CustomerClassificationService from "../../services/customerClassification/CustomerClassificationService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CUSTOMER_CLASSIFICATION_LOADING,
    payload: loading,
  });
};

export const setCustomerClassifications = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CUSTOMER_CLASSIFICATIONS,
    payload: data,
  });
};

export const setCustomerClassification = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CUSTOMER_CLASSIFICATION,
    payload: data,
  });
};

export const editTicket = (CustomerClassification) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_CUSTOMER_CLASSIFICATION,
    payload: CustomerClassification,
  });
};

export const removeTicket = (customerClassification_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_CUSTOMER_CLASSIFICATION,
    payload: customerClassification_id,
  });
};

export const resetCustomerClassificationState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_CUSTOMER_CLASSIFICATION_STATE,
  });
};

export const getCustomerClassifications = (page,limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response =
      await CustomerClassificationService.getcustomerClassifications(page,limit);
    if (response.status == 1) {
      dispatch(setCustomerClassifications(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load Customer Classification",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getCustomerClassification =
  (customerClassification_id) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response =
        await CustomerClassificationService.getcustomerClassification(
          customerClassification_id
        );
      if (response.status == 1) {
        dispatch(setCustomerClassification(response.data));
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load Customer Classification",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createClassification = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response =
      await CustomerClassificationService.createcustomerClassification(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Customer Classification created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/customer-classifications");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to create Customer Classification",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateClassification =
  (customerClassification_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response =
        await CustomerClassificationService.updatecustomerClassification(
          customerClassification_id,
          payload
        );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "Customer Classification updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/customer-classifications");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update Ticket",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateMultiLangClassification =
  (customerClassification_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response =
        await CustomerClassificationService.updateMultiLangClassification(
          customerClassification_id,
          payload
        );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ??
              "classification localization updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/customer-classifications");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update classification localization",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteClassification =
  (customerClassification_id) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response =
        await CustomerClassificationService.deletecustomerClassification(
          customerClassification_id
        );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "Customer Classification deleted successfully",
            AlertTypes.SUCCESS
          )
        );
        dispatch(removeTicket(customerClassification_id));
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to delete Ticket",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };
