import { setNewOrderEvent } from "actions/events/events.action.js";
import { setNewTransactionEvent } from "actions/events/events.action.js";
import { setNewMerchantEvent } from "actions/events/events.action.js";
import { resetEventsState } from "actions/events/events.action.js";
import { setNewMembershipEvent } from "actions/events/events.action.js";
import { setNewCustomerEvent } from "actions/events/events.action.js";
import { setActiveUsersEvent } from "actions/events/events.action.js";
import io from "socket.io-client";
import { store } from "store/store";

// const SOCKET_URL = "https://dev-socket.mithu.com";
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const dispatch = store.dispatch;

export let SOCKET = null;
export const socketInitializer = (user) => {
  if (SOCKET) return;
  SOCKET = io(SOCKET_URL);

  SOCKET.on("connect", () => {
    console.log("Successfully connected to WebSocket server");
    SOCKET.emit("connect_admin_app", { user_id: user.id });
  });

  SOCKET.on("reconnect", () => {
    console.log("Successfully re-connected to WebSocket server");
    SOCKET.emit("connect_admin_app", { user_id: user.id });
  });

  SOCKET.on("connect_error", (err) => {
    console.error("Connection error:", err);
  });

  SOCKET.on("disconnect", (reason) => {
    console.warn("Disconnected:", reason);
  });

  // ------------------------------ ALL EVENTS ------------------------------
  SOCKET.on("update_active_users", (data) => {
    dispatch(setActiveUsersEvent(data));
    console.log("update_active_users eveent triggered", data);
  });

  SOCKET.on("new_order", (data) => {
    console.log("new_order event triggered", data);
    dispatch(setNewOrderEvent(data));
  });

  SOCKET.on("new_transaction", (data) => {
    console.log("new_transaction event triggered", data);
    dispatch(setNewTransactionEvent(data));
  });

  SOCKET.on("new_customer", (data) => {
    console.log("new_customer event triggered", data);
    dispatch(setNewCustomerEvent(data));
  });

  SOCKET.on("new_merchant", (data) => {
    console.log("new_merchant event triggered", data);
    dispatch(setNewMerchantEvent(data));
  });

  SOCKET.on("new_membership", (data) => {
    console.log("new_membership event triggered", data);
    dispatch(setNewMembershipEvent(data));
  });
};

export const socketDisconnect = () => {
  if (!SOCKET) return;
  SOCKET.removeAllListeners();
  SOCKET.disconnect();
  SOCKET = null;
  dispatch(resetEventsState());
};
