import React from "react";
import Card from "components/card";
import ProfileIcon from "../../../../assets/img/profile-icon.png";

const HeaderWidget = ({ customer }) => {
  const {
    profile,
    name,
    total_spendings,
    total_spendings_dollers,
    total_orders_count,
    merchant_points,
    network_points,
    total_redeem,
    total_redeem_in_dollars,
  } = customer || {};

  const infoItems = [
    {
      label: "Total Spent",
      value: `${total_spendings} SAR`,
      description: `${Number(total_spendings_dollers || 0).toFixed(2)} USD`,
    },
    {
      label: "Total Orders",
      value: Number(total_orders_count || 0).toFixed(2),
      description: "Total orders within 360 days",
    },
    {
      label: "Merchant Points",
      value: merchant_points,
      description: "Total acquired within 360 days",
    },
    {
      label: "Network Points",
      value: network_points,
      description: "Total acquired within 360 days",
    },
    {
      label: "Total Redeems",
      value: `${Number(total_redeem_in_dollars || 0).toFixed(2)} USD`,
      description: "Total acquired within 360 days",
    },
  ];

  return (
    <Card extra="p-6 ">
      <div className="flex  flex-col  md:flex-row">
        <div className="h-56 w-56 self-center">
          <img
            className="h-full w-full rounded-full border-4 border-gray-400	"
            src={profile || ProfileIcon}
            alt="Customer Profile"
          />
        </div>
        <div className=" flex flex-auto flex-col justify-between p-4">
          <div className="mb-10 flex flex-col ">
            <span className="text-base font-medium leading-5 text-brand-500">
              Priority Customer
            </span>
            <span className="text-4xl font-medium leading-9">{name}</span>
          </div>
          <div className=" grid grid-cols-1 gap-2  divide-x-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 ">
            {infoItems.map((item, index) => (
              <div
                key={index}
                className={`flex min-h-20 flex-col justify-between pl-2 ${
                  index !== infoItems.length - 1 ? "pr-2" : ""
                }`}
              >
                <span className=" text-base font-medium leading-5 text-gray-500">
                  {item.label}
                </span>
                <span className=" text-2xl font-medium leading-9">
                  {item.value}
                </span>
                {index == 0 ? (
                  <span className="text-base font-medium leading-5 text-gray-400">
                    {item.description}
                  </span>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default HeaderWidget;
