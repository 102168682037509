import { Listbox, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { MdCheck } from "react-icons/md";
import { HiOutlineChevronUpDown } from "react-icons/hi2";

export default function HeadlessSelect({
  multiSelect,
  disable,
  label,
  title,
  options,
  selected,
  onSelect,
}) {

  const isActive = (value) => {
    if (!multiSelect) return selected === value
    return selected.includes(value)
  }

  const handleSelect = (value) => {
    if (!multiSelect) return onSelect(value)
    let temp_values = []
    if (selected.includes(value)) temp_values = selected.filter(val => val !== value)
    else temp_values = [...selected, value]
    onSelect(temp_values)
  }
  return (
    <div className="w-full">
      <Listbox value={selected} onChange={handleSelect}>
        {label ? (
          <div className="mb-1 block text-base font-medium text-gray-800">
            <Listbox.Label>{label}</Listbox.Label>
          </div>
        ) : null}
        <div className="w-full relative rounded-md border border-gray-300">
          <Listbox.Button disabled={disable} className="relative flex w-full cursor-default rounded-lg bg-neutral-100 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-gray-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-sm">
            <span className={`flex w-full max-w-full items-center overflow-x-auto thin-scrollbar text-gray-700 ${((multiSelect && multiSelect.length) || (!multiSelect && selected)) ? "text-gray-900" : ''}`}>
              {(multiSelect && selected?.length) ?
                <div className='flex w-full justify-start items-center'>
                  {selected.map((value, index) =>
                    <p key={Math.random()} className="flex text-nowrap w-auto">{(index > 0 ? ', ' : '') + value}</p>
                  )}
                </div>
                :
                <p className="flex flex-none text-nowrap w-full">{(!multiSelect && selected) ? selected : title}</p>
              }
            </span>
            <span className='w-10' />
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <HiOutlineChevronUpDown
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="hide-scrollbar absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 focus:outline-none text-sm">
              {options.map((option, index) => (
                <Listbox.Option
                  key={index}
                  className="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 hover:bg-gray-100"
                  value={option}
                >
                  <>
                    <span
                      className={`block truncate ${isActive(option) ? 'font-medium' : 'font-normal'
                        }`}
                    >
                      {option}
                    </span>
                    {isActive(option) ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                        <MdCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
