import { getArea, getRegions, resetRegionState, updateArea } from "actions/regions/regions.actions";
import { FORM_TYPE } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AreaForm from "./areaForm";
import Loader from "components/loaderSpinner/Loader";
import ConfirmationModal from "views/admin/components/ConfirmationModal";
import { deleteArea } from "actions/regions/regions.actions";

const UpdateArea = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { areaId } = useParams();
  const { area } = useSelector((state) => state.region);

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = () => {
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteArea(area.id, navigate));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
  };

  useEffect(() => {
    dispatch(resetRegionState());
    dispatch(getArea(areaId));
  }, [areaId]);

  useEffect(() => {
    if (!area) return;
    const { country_id, state_id } = area;
    dispatch(getRegions(country_id?.code, state_id?.code));
  }, [area]);

  if (!area || area.id !== areaId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the "{area.name}" area</h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <AreaForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={area}
        handleFormSubmit={(payload, redirect) => {
          dispatch(
            updateArea(areaId, payload, redirect === false ? null : navigate)
          );
        }}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateArea;
