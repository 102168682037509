import React from "react";
import loader from "../../assets/svg/loader.svg";

const sizeMap = {
  small: "h-24 w-24",
  medium: "h-36 w-36",
  large: "h-48 w-48",
};

const Loader = ({ size = sizeMap.small }) => {
  return (
    <div className={size}>
      <img src={loader} alt="loader spinner" className="h-full w-full" />
    </div>
  );
};

export default Loader;
