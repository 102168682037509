import { SLIDER_TYPE } from "constants/slider.constants";

const validateSliderForm = (formType, sliderData) => {
  const errors = [];
  if (!sliderData.title) errors.push("title is required");
  if (!sliderData.status) errors.push("status is required");
  if (!sliderData.image) errors.push("image is required");
  // if (!sliderData.type) errors.push("slider type is required");
  if (sliderData.type == SLIDER_TYPE.INTERNAL && !sliderData.entity_type)
    errors.push("entity type is required");
  if (
    sliderData.type == SLIDER_TYPE.INTERNAL &&
    sliderData.entity_type &&
    !sliderData.entity_id
  )
    errors.push("please select relevant entity");
  if (sliderData.type == SLIDER_TYPE.EXTERNAL && !sliderData.action_url)
    errors.push("action url is required");

  return { isValid: !errors.length, errors };
};
export default validateSliderForm;
