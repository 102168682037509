import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { FORM_TYPE } from "constants/app.constants";
import BrandForm from "./brandForm";
import { createBrand } from "actions/Brands/brands.action";

const CreateBrand = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <BrandForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createBrand(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateBrand;
