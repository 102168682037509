import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  countriesCount: 0,
  countries: [],
  statesCount: 0,
  states: [],
  citiesCount: 0,
  cities: [],
  // areas
  areasCount: 0,
  areas: [],
  area: null,
  loading: false,
};

export const regionReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: payload.countries,
        countriesCount: payload?.meta?.total_documents || 0,
        loading: false,
      };
    case ActionTypes.SET_STATES:
      return {
        ...state,
        states: payload.states,
        statesCount: payload?.meta?.total_documents || 0,
        loading: false,
      };
    case ActionTypes.SET_CITIES:
      return {
        ...state,
        cities: payload.cities,
        citiesCount: payload?.meta?.total_documents || 0,
        loading: false,
      };
    case ActionTypes.SET_AREAS:
      return {
        ...state,
        areasCount: payload?.meta?.total_documents || 0,
        areas: payload.areas,
        loading: false,
      };
    case ActionTypes.SET_AREA:
      return {
        ...state,
        area: payload,
        loading: false,
      };
    case ActionTypes.DELETE_AREA:
      return {
        ...state,
        areas: state.areas.filter((area) => area.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_REGION_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_REGION_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
