import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  categoriesCount: 0,
  categories: [],
  category: null,
  loading: false,
};

export const VibeCategoryReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_VIBE_CATEGORIES:
      return {
        ...state,
        categoriesCount: payload?.meta?.total_documents || 0,
        categories: payload.VibeCategories,
        loading: false,
      };
    case ActionTypes.SET_VIBE_CATEGORY:
      return {
        ...state,
        category: payload,
        loading: false,
      };
    case ActionTypes.UPDATE_VIBE_CATEGORY_PUBLISH_STATUS:
      return {
        ...state,
        categories: state.categories.map((category) =>
          category.id === payload.id
            ? {
                ...category,
                is_published: payload.is_published,
              }
            : category
        ),
        loading: false,
      };
    case ActionTypes.DELETE_VIBE_CATEGORY:
      return {
        ...state,
        categoriesCount: state.categoriesCount - 1,
        categories: state.categories.filter(
          (category) => category.id !== payload
        ),
        loading: false,
      };
    case ActionTypes.SET_VIBE_CATEGORY_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_VIBE_CATEGORY_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
