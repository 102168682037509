import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserForm from "./userForm";
import { createUser } from "actions/users/users.actions";

const CreateUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="h-full w-full">
      <UserForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createUser(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateUser;
