import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  configuration: null,
  configurationMultiLang: {},
  loading: false,
};

export const configurationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_CONFIGURATION:
      return {
        ...state,
        configuration: payload,
        // sliderMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.SET_CONFIGURATION_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_CONFIGURATION_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
