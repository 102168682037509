import { v4 as uuidv4 } from "uuid";
import * as ActionTypes from "../../actions/actionTypes";

export const setAlert =
  (message, alertType, timeout = 3000) =>
  (dispatch) => {
    const id = uuidv4();
    dispatch({
      type: ActionTypes.SET_ALERT,
      payload: { message, alertType, id },
    });

    setTimeout(
      () => dispatch({ type: ActionTypes.REMOVE_ALERT, payload: id }),
      timeout
    );
  };
