import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  logsCount: 0,
  activityLogs: [],
  loading: false,
};

export const logsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_LOGS:
      return {
        ...state,
        // logsCount: payload?.meta?.total_documents || 0,
        activityLogs: payload.logs,
        loading: false,
      };
    case ActionTypes.SET_LOG_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_LOG_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
