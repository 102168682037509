import Loader from "components/loaderSpinner/Loader";
import { FORM_TYPE } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import ConfirmationModal from "../components/ConfirmationModal";
import StoreForm from "./StoreForm";
import {
  deleteStore,
  getStore,
  updateMultiLangStore,
  updateStore,
} from "actions/store/store.actions";

const UpdateStore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { storeId } = useParams();
  const { store } = useSelector((state) => state.store);

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = () => {
    setShowDelModal(true);
  };

  const handleCancelDelete = () => {
    setShowDelModal(false);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteStore(storeId, navigate));
    handleCancelDelete();
  };

  useEffect(() => {
    dispatch(getStore(storeId));
  }, [storeId]);

  const handleSubmit = (formData, redirect) => {
    if (formData.get("lang_id")) {
      dispatch(
        updateMultiLangStore(storeId, formData, redirect ? navigate : null)
      );
      return;
    }
    dispatch(
      updateStore(storeId, formData, redirect === false ? null : navigate)
    );
  };

  if (!storeId || store?.id !== storeId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the store with name {store.name}</h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <StoreForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={store}
        handleFormSubmit={handleSubmit}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateStore;
