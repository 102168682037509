import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getMerchantPromotionTags = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_MERCHANT_PROMOTION_TAGS}?page=${page}&limit=${limit}`
  );
};

const getMerchantPromotionTag = (merchantPromotionTagId) => {
  return RestClient.Get(
    `${API_URL.GET_MERCHANT_PROMOTION_TAG}/${merchantPromotionTagId}`
  );
};

const createMerchantPromotionTag = (payload) => {
  return RestClient.Post(API_URL.CREATE_MERCHANT_PROMOTION_TAG, payload);
};

const updateMerchantPromotionTag = (merchantPromotionTagId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MERCHANT_PROMOTION_TAG}/${merchantPromotionTagId}`,
    payload
  );
};

const updateMultiLangMerchantPromotionTag = (
  merchantPromotionTagId,
  payload
) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MULTI_LANG_MERCHANT_PROMOTION_TAG}/${merchantPromotionTagId}`,
    payload
  );
};

const deleteMerchantPromotionTag = (merchantPromotionTagId) => {
  return RestClient.Delete(
    `${API_URL.DELETE_MERCHANT_PROMOTION_TAG}/${merchantPromotionTagId}`
  );
};

const exportedObject = {
  getMerchantPromotionTags,
  getMerchantPromotionTag,
  createMerchantPromotionTag,
  updateMerchantPromotionTag,
  updateMultiLangMerchantPromotionTag,
  deleteMerchantPromotionTag,
};

export default exportedObject;
