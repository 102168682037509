import { getCustomerprofile } from "actions/customers/customers.actions";
import { getOrders } from "actions/orders/orders.actions";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "views/admin/components/cardHeader";
import Pagination from "views/admin/components/pagination";
import HeaderWidget from "./headerWidget";
import PersonalInfoCard from "./personalInfoCard";
import TabSelector from "views/admin/components/TabSelector";
import Card from "components/card";
import ProfileOrderTable from "./profileOrderTable";
import { getTransactions } from "actions/transactions/TransactionActions";
import TransactionsTable from "./transactionsTable";
import { getSystemSettings } from "selectors/settings/settings.selector";

const options = [
  {
    name: "Transactions",
  },
];

const CustomerProfile = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(null);

  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;

  const { loading, customer } = useSelector((state) => state.customer);
  const { ordersCount, orders } = useSelector((state) => state.order);
  const { transactionsCount, transactions } = useSelector(
    (state) => state.transaction
  );

  useEffect(() => {
    dispatch(getCustomerprofile(customerId));
  }, []);

  useEffect(() => {
    if (selectedTab === "Transactions") {
      dispatch(
        getTransactions(
          page,
          limit,
          null,
          customerId,
          null,
          null,
          null,
          null,
          null,
          null
        )
      );
    } else dispatch(getOrders(page, limit, null, customerId));
  }, [page, selectedTab]);

  return (
    <div className=" flex h-full w-full flex-col  gap-4">
      <CardHeader
        label={"Customer Profile"}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate(-1),
            variant: "dark",
          },
          ...(customer?.email_verified_at
            ? [
                {
                  text: `Verified on ${dayjs(
                    customer?.email_verified_at
                  ).format("MMMM, DD, YYYY")}`,
                },
              ]
            : []),
        ]}
      />
      <HeaderWidget customer={customer} />
      <div className="flex h-full w-full flex-col gap-4 md:flex-row ">
        <div className="flex w-full flex-col justify-between  md:w-4/12 ">
          <PersonalInfoCard customer={customer} />
        </div>
        <div className="flex flex-auto flex-col overflow-x-auto ">
          <Card extra={" bg-white"}>
            <div className="p-4">
              <TabSelector
                selectors={[
                  { name: "Orders", value: null },
                  ...options.map((opt) => ({
                    name: opt.name,
                    value: opt.name,
                  })),
                ]}
                activeSelector={selectedTab}
                setActiveSelector={(value) => {
                  setSelectedTab(value);
                  setPage(1);
                }}
              />
            </div>
            {selectedTab === "Transactions" ? (
              <TransactionsTable transactions={transactions} />
            ) : (
              <ProfileOrderTable orders={orders} />
            )}
          </Card>
          <Pagination
            page={page}
            setPage={setPage}
            limit={limit}
            totalCount={
              selectedTab === "Transactions" ? transactionsCount : ordersCount
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerProfile;
