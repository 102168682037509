import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../components/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import TicketTableCard from "./tickets-table-card";
import { getTickets } from "actions/tickets/tickets.action";
import { deleteTicket } from "actions/tickets/tickets.action";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const Tickets = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ticketsCount, tickets, loading } = useSelector(
    (state) => state.ticket
  );
  const [showDelModal, setShowDelModal] = useState(false);
  const [delTicket, setDelTicket] = useState(null);

  useEffect(() => {
    dispatch(getTickets(page, limit));
  }, [page]);

  const handleCancel = () => {
    setShowDelModal(false);
    setDelTicket(null);
  };

  const onClickDelete = (lang) => {
    setDelTicket(lang);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteTicket(delTicket.id));
    handleCancel();
  };

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the Ticket "
              {delTicket?.ticket_number ?? ""}"
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}

      <TicketTableCard
        tickets={tickets}
        onClickAddNew={() => navigate("/tickets/create")}
        onClickEdit={(ticketId) => navigate(`/tickets/update/${ticketId}`)}
        onClickDelete={onClickDelete}
      />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={ticketsCount}
      />
    </div>
  );
};

export default Tickets;
