import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  tagsCount: 0,
  tags: [],
  tag: null,
  tagMultiLang: {},
  loading: false,
};

export const storeTagsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_STORE_TAGS:
      return {
        ...state,
        tagsCount: payload?.meta?.total_documents || 0,
        tags: payload.tags,
        loading: false,
      };
    case ActionTypes.SET_STORE_TAG:
      return {
        ...state,
        tag: payload.tag,
        tagMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_STORE_TAG:
      return {
        ...state,
        tagsCount: state.tagsCount - 1,
        tags: state.tags.filter((tag) => tag.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_STORE_TAG_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_STORE_TAG_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
