import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import StoreCategoryForm from "./StoreCategoryForm";
import { createStoreCategory } from "actions/storeCategories/storeCategories.actions";

const CreateStoreCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <StoreCategoryForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createStoreCategory(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateStoreCategory;
