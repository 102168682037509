import NodataFoundImage from "../../../assets/img/noDataGrid.png";

function Table({ tableData }) {
  const { headers, records } = tableData;

  return (
    <div className="w-full overflow-x-hidden overflow-x-scroll px-4">
      <table className="w-full min-w-[500px] border-collapse">
        <thead>
          <tr className=" px-2 py-3">
            {headers.map((header, index) => (
              <th
                key={index}
                // className="px-4 py-2 text-left text-gray-500"
                className={`${
                  header == "" ? "max-w-14 p-2" : "px-4 py-2 text-left"
                }  text-gray-500`}
                onClick={() => console.log("sort by name")}
              >
                {typeof header === "string"
                  ? header.split(" ").join("\u00A0")
                  : header}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {records && records.length > 0 ? (
            records.map((record, rowIndex) => (
              <tr key={Math.random()} className=" border-t">
                {Object.entries(record).map(([key, value], rowIndex) => (
                  <td
                    key={rowIndex}
                    className={`${
                      key == "" || key.includes("__")
                        ? " max-w-14 p-1"
                        : "px-4 py-2 text-left"
                    }  `}
                  >
                    {typeof value === "string"
                      ? value.split(" ").join("\u00A0")
                      : value}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={headers.length}>
                <div className="flex w-full flex-col items-center justify-center gap-2 px-4 py-16">
                  <img
                    src={NodataFoundImage}
                    className="max-h-36 max-w-36 object-contain"
                    alt="No data found"
                  />
                  <h2 className="text-neutral-500 font-sans text-3xl font-bold">
                    No data
                  </h2>
                  <h4 className="font-sans text-xl font-semibold text-gray-500">
                    No data, please try again later
                  </h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
