import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getClaims = (page = 1, limit = 10) => {
  return RestClient.Get(`${API_URL.GET_CLAIMS}?page=${page}&limit=${limit}`);
};

const exportedObject = {
  getClaims,
};

export default exportedObject;
