import { getPushNotifications } from "actions/pushNotifications/pushNotifications.action";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/pagination";
import PushNotificationsTableCard from "./push-notifications-table-card";
import { getSystemSettings } from "selectors/settings/settings.selector";
import ConfirmationModal from "../components/ConfirmationModal";

const PushNotifications = () => {
  const [page, setPage] = useState(1);

  const [notificationId, setNotificationId] = useState("");
  const [modalOpenBody, setModalOpenBody] = useState(false);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notificationsCount, notifications, loading } = useSelector(
    (state) => state.pushNotification
  );

  useEffect(() => {
    dispatch(getPushNotifications(page, limit));
  }, [page]);

  const onClickBody = (id) => {
    setNotificationId(id);
    setModalOpenBody(true);
  };

  const handleCancel = () => {
    setModalOpenBody(false);
  };

  return (
    <div className="h-full w-full">
      {modalOpenBody ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleCancel}
          confirmBtnText="ok!"
        >
          <h1>
            {notifications.find((noti) => noti.id === notificationId)?.title ??
              ""}
          </h1>
          <div className="flex max-h-36 flex-col gap-4 overflow-y-scroll ">
            <p className="p-1 text-sm text-gray-700">
              {notifications.find((noti) => noti.id === notificationId)?.body ??
                ""}
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <PushNotificationsTableCard
        notifications={notifications}
        onClickAddNew={() => navigate("/push-notifications/create")}
        onClickBody={(id) => onClickBody(id)}
      />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={notificationsCount}
      />
    </div>
  );
};

export default PushNotifications;
