import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "views/admin/components/pagination";
import CitiesTableCard from "./cities-table-card";
import CitiesFilters from "./filters";
import { resetRegionState } from "actions/regions/regions.actions";
import { getCountries } from "actions/regions/regions.actions";
import { getSystemSettings } from "selectors/settings/settings.selector";

const Cities = () => {
  const dispatch = useDispatch();
  const { cities, citiesCount } = useSelector((state) => state.region);
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;

  useEffect(() => {
    dispatch(resetRegionState());
    dispatch(getCountries());
  }, []);

  return (
    <div className="h-full w-full">
      <CitiesTableCard
        cities={cities}
        filters={<CitiesFilters limit={limit} page={page} setPage={setPage} />}
      />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={citiesCount}
      />
    </div>
  );
};

export default Cities;
