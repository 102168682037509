import { deleteArea } from "actions/regions/regions.actions";
import Card from "components/card";
import { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "views/admin/components/ConfirmationModal";
import CardHeader from "views/admin/components/cardHeader";
import Table from "views/admin/components/table";
import AreasFilters from "./filters";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";

function AreasTableCard({ areas }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [areaData, setAreaData] = useState(null);

  const onClickDelete = (area) => {
    setAreaData(area);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteArea(areaData.id));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
    setAreaData(null);
  };

  const tableHeaders = [
    "Name",
    "Country",
    "State",
    "City",
    "Created By",
    "Actions",
  ];
  const records = areas.map((area) => ({
    name: area.name,
    country: area.country_id.name,
    state: area.state_id.name,
    city: area.city_id.name,
    created_by: area?.created_by?.name ?? "",
    actions: (
      <div className="flex items-center gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => navigate(`/areas/update/${area.id}`)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
            onClick={() => onClickDelete(area)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));

  return (
    <div>
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the "{areaData.name}" area</h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={"w-full bg-white"}>
        <CardHeader
          label={"Areas"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.REGION_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [{
                  text: "Add New",
                  onClick: () => navigate("/areas/create"),
                variant: "primary",
                }]
              : null
          }
        />
        <AreasFilters />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default AreasTableCard;
