import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import TabSelector from "../components/TabSelector";
import { getLanguages } from "actions/languages/languages.actions";
import { FORM_TYPE } from "constants/app.constants";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

const initialMultiLangData = {
  name: "",
};

const initialMerchantTypeData = {
  name: "",
  display_order: 0,
};

const MerchantTypeForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, merchnatTypeMultiLang, merchantTypesCount } = useSelector(
    (state) => state.merchant
  );
  const { languages } = useSelector((state) => state.language);
  const [typeData, setTypeData] = useState({ ...initialMerchantTypeData, display_order: merchantTypesCount + 1 });
  const [multiLangData, setMultiLangData] = useState(initialMultiLangData);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [lang_id, setLang_id] = useState(null);
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!initialFormData && formType == FORM_TYPE.UPDATE) return;
    setTypeData((prevData) => ({ ...prevData, ...initialFormData }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getLanguages(1, 1000));
  }, []);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = merchnatTypeMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initialMultiLangData, ...langData });
  }, [lang_id]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (lang_id) {
      setMultiLangData({ ...multiLangData, [name]: value });
      return;
    }
    setTypeData({ ...typeData, [name]: value });
  };

  const handleSubmit = (redirect) => {
    let payload = { ...typeData };
    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialMerchantTypeData[key] == undefined) {
        delete payload[key];
      }
    });

    if (lang_id) payload = { ...multiLangData, lang_id };
    handleFormSubmit(payload, redirect);
  };

  return (
    <div className="h-full w-full bg-white">
      {" "}
      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.MERCHANT_TYPE}
          entityId={typeData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/merchant-types")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to merchants
              Types page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}
      <CardHeader
        label={`${formType} Merchant Type`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/merchant-types"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => handleSubmit(false),
                  variant: "primary",
                },
                {
                  text: "Delete",
                  onClick: () => handleDelete(),
                  variant: "danger",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      <div className="flex flex-col gap-3 p-4">
        {/* Tabs */}
        {formType == FORM_TYPE.UPDATE ? (
          <div className="">
            <TabSelector
              selectors={[
                { name: "Standard", value: null },
                ...languages.map((lang) => ({
                  icon: lang.flag_image,
                  name: lang.name,
                  value: lang.id,
                })),
              ]}
              activeSelector={lang_id}
              setActiveSelector={(value) => setLang_id(value)}
            />
          </div>
        ) : null}
        <div className="flex flex-col items-start">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Name
          </label>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={lang_id ? multiLangData.name : typeData.name}
            onChange={handleOnChange}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
          />
        </div>
        {/* display order  */}
        {!lang_id ? (
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Display Order
            </label>
            <input
              onChange={handleOnChange}
              type="number"
              name="display_order"
              value={typeData.display_order}
              className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
            />
          </div>
        ) : null}
        {/* {error ? <span className='text-red-600 p-2'>{error}</span> : null} */}
        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${
              loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
            }
            `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default MerchantTypeForm;
