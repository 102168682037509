import { PERMISSION_PRIORITY } from "constants/role.constants";
import { store } from "../store/store";

// utils/permissions.js
export const hasPermission = (roleTypes, action) => {
  const state = store.getState();

  const { permissions } = state.role;

  const filteredPermissions = permissions.filter((perm) =>
    roleTypes.includes(perm.role_type)
  );

  if (!filteredPermissions.length) return false;

  return filteredPermissions.some(
    (permission) =>
      PERMISSION_PRIORITY[permission.action] >= PERMISSION_PRIORITY[action]
  );
};
