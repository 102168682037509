
import { getCities } from "actions/regions/regions.actions";
import { setStates } from "actions/regions/regions.actions";
import { getStates } from "actions/regions/regions.actions";
import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import HeadlessSelectObject from "views/admin/components/headless/selectObject.headless";

const initialCityFilters = {
  country_code: "",
  state_code: "",
  name: "",
}

const CitiesFilters = ({ page, setPage, limit }) => {
  const dispatch = useDispatch()
  const { countries, states, loading } = useSelector(state => state.region)
  const [filters, setFilters] = useState(initialCityFilters)

  useEffect(() => {
    setFilters((prevState) => ({ ...prevState, state_code: "" }));
    dispatch(setStates({ states: [] }));
    if (!filters.country_code) return;
    dispatch(getStates(filters.country_code));
  }, [filters.country_code]);

  useEffect(() => {
    const { country_code, state_code, name } = filters
    dispatch(getCities(country_code, state_code, name, page, limit))
  }, [page])

  const getFilteredCities = () => {
    setPage(1)
    const { country_code, state_code, name } = filters
    dispatch(getCities(country_code, state_code, name, 1, limit))
  }

  const removeAllFilters = () => {
    setPage(1)
    setFilters(initialCityFilters)
    const { country_code, state_code, name } = initialCityFilters
    dispatch(getCities(country_code, state_code, name, 1, limit))
  }

  const handleOnChange = (name, value) => {
    setFilters((prevData) => ({ ...prevData, [name]: value }))
  }

  return (
    <div className="w-full h-auto">

      {/* filter buttons */}
      <div className="flex items-center justify-end gap-2 p-2">
        <div className="w-auto">
          <button
            disabled={loading}
            onClick={removeAllFilters}
            className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
            type="button"
          >
            <MdFilterAltOff className="h-4 w-4" />
            {"Clear Filters"}
          </button>
        </div>
        <div className="w-auto">
          <button
            disabled={loading}
            onClick={getFilteredCities}
            className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
            type="button"
          >
            <CiFilter className="h-4 w-4" />
            {"Apply Filters"}
          </button>
        </div>
      </div>

      <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
        {/* Country, State and City */}
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="w-full md:w-1/3">
            <HeadlessSelectObject
              disable={!countries.length || loading}
              title={loading ? "Loading..." : !countries.length ? "No Countries" : "All Countries"}
              options={[
                { name: "All", value: "" },
                ...countries.map((t) => ({ name: t.name, value: t.code })),
              ]}
              selected={filters.country_code}
              onSelect={(value) => handleOnChange("country_code", value)}
            />
          </div>
          <div className="w-full  md:w-1/3">
            <HeadlessSelectObject
              disable={!states.length || loading}
              title={loading ? "Loading..." : !states.length ? "No States" : "All States"}
              options={[
                { name: "All", value: "" },
                ...states.map((t) => ({ name: t.name, value: t.code })),
              ]}
              selected={filters.state_code}
              onSelect={(value) => handleOnChange("state_code", value)}
            />
          </div>
          <div className="w-full  md:w-1/3">
            <input onChange={(e) => handleOnChange('name', e.target.value)} value={filters.name}
              type="text" name="name" placeholder="City Name"
              className="flex-auto w-full h-full px-2 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CitiesFilters;