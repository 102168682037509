import { setAlert } from "actions/alert/alert.actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertTypes } from "../../../reducers/alert/alert.reducer";
import ConfirmationModal from "../components/ConfirmationModal";
import HeadlessSelect from "../components/headless/select.headless";
import { getTicketTypes } from "actions/tickets/tickets.action";
import { GiftedChat } from "react-web-gifted-chat";
import { IoMdSend } from "react-icons/io";
import { messages } from "utils/constants";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

const initialLanguageData = {
  attachment: [],
  title: "",
  support_type: "",
  description: "",
  ticket_status: "",
};

const TICKET_STATUS = ["open", "inprogress", "resolved", "closed"];

const TicketForm = ({
  showLogsModal,
  setShowLogsModal,
  initialFormData,
  handleFormSubmit,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { loading, ticketTypes } = useSelector((state) => state.ticket);
  const [ticketData, setTicketData] = useState(initialLanguageData);

  useEffect(() => {
    if (!initialFormData) return;
    setTicketData((prevData) => ({
      ...prevData,
      ...initialFormData,
    }));
  }, [initialFormData]);

  const onChangeFormData = (name, value) => {
    setTicketData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    dispatch(getTicketTypes());
  }, [JSON.stringify(ticketTypes)]);

  const RenderChatBox = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
      // Initialize with some default messages if needed
      setMessages([
        {
          _id: 1,
          text: "Hello! How can I help you today?",
          createdAt: new Date(),
          user: {
            _id: 2,
            name: "Admin",
            avatar: "https://placeimg.com/140/140/any",
          },
        },
      ]);
    }, []);

    const onSend = (newMessages = []) => {
      setMessages(GiftedChat.append(messages, newMessages));
    };

    return (
      <div className="chat-container">
        <GiftedChat
          messages={messages}
          onSend={(messages) => onSend(messages)}
          user={{
            _id: 1,
            name: "User",
          }}
        />
      </div>
    );
  };

  // chatBox------------------------------------------>
  // const RenderChatBox = () => {
  //   const [chatMessages, setChatMessages] = useState(messages);
  //   const [newMessage, setNewMessage] = useState("");

  //   const handleSendMessage = () => {
  //     if (newMessage.trim() === "") return;

  //     const newChatMessage = {
  //       id: chatMessages.length + 1,
  //       userImage: "https://source.unsplash.com/vpOeXr5wmR4/600x600",
  //       message: newMessage,
  //       fromAdmin: true,
  //     };

  //     setChatMessages([...chatMessages, newChatMessage]);
  //     setNewMessage("");
  //   };

  //   return (
  //     <div className="flex h-full w-full flex-col justify-between px-2 sm:px-5">
  //       {/* Chat Messages Container */}
  //       <div className="2xl:max-h-auto mt-1 flex max-h-[250px] flex-grow flex-col overflow-y-scroll sm:max-h-[300px] md:max-h-[350px] lg:max-h-[500px] xl:max-h-[700px]">
  //         {chatMessages.length !== 0 ? (
  //           chatMessages.map((msg) => (
  //             <div
  //               key={msg.id}
  //               className={`mb-4 flex gap-1 ${
  //                 msg.fromAdmin ? "justify-end" : "justify-start"
  //               } ${msg.fromAdmin ? "mr-2" : ""}`}
  //             >
  //               {!msg.fromAdmin && (
  //                 <img
  //                   src={msg.userImage}
  //                   className="h-8 w-8 self-end rounded-full object-cover"
  //                   alt=""
  //                 />
  //               )}
  //               <div
  //                 className={`${
  //                   msg.fromAdmin ? "bg-green-500" : "bg-indigo-600"
  //                 }
  //                   ${
  //                     msg.fromAdmin
  //                       ? "rounded-bl-3xl rounded-tl-3xl rounded-tr-xl"
  //                       : "rounded-br-3xl rounded-tl-xl rounded-tr-3xl"
  //                   }
  //                   px-4 py-2 text-white`}
  //               >
  //                 {msg.message}
  //               </div>
  //               {msg.fromAdmin && (
  //                 <img
  //                   src={msg.userImage}
  //                   className="h-8 w-8 self-end rounded-full object-cover"
  //                   alt=""
  //                 />
  //               )}
  //             </div>
  //           ))
  //         ) : (
  //           <div>nothing to show here</div>
  //         )}
  //       </div>
  //       {/* Input Container */}
  //       <div className="flex items-center justify-between gap-2 py-1">
  //         <input
  //           className="w-full rounded-[10px] border-2 border-gray-200 bg-gray-100 px-3 py-3 focus:border-green-700 focus:outline-none focus:ring-green-700 sm:text-sm"
  //           type="text"
  //           placeholder="Type your message here..."
  //           value={newMessage}
  //           onChange={(e) => setNewMessage(e.target.value)}
  //           onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
  //         />
  //         <button
  //           onClick={handleSendMessage}
  //           className="flex h-12 w-12 items-center justify-center rounded-full bg-blue-600 p-1 text-white"
  //         >
  //           <IoMdSend className="h-6 w-6" />
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };

  const handleSubmit = () => {
    const errors = [];
    // validate data
    Object.entries(ticketData).forEach(([key, value]) => {
      if (value === "") errors.push(`${key} is required`);
    });
    if (errors.length) {
      return errors.forEach((error) =>
        dispatch(setAlert(error, AlertTypes.ERROR))
      );
    }

    const payload = { ticket_status: ticketData.ticket_status };
    if (payload.id) delete payload.id;
    console.log(payload);
    handleFormSubmit(payload);
  };

  return (
    <div className="h-full w-full bg-white sm:p-4">
      {showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.TICKET}
          entityId={ticketData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/tickets")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to tickets
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}
      <div className="flex flex-col gap-4">
        {/* attachments */}
        <div className="flex h-full w-full flex-col items-center justify-between self-center rounded-md border border-gray-300 p-2">
          <label
            for="image"
            className="mb-1 block text-lg font-medium text-gray-800"
          >
            Attachments
          </label>
          <div className="flex min-w-[100px] max-w-[100px]  flex-col justify-between gap-2 sm:flex-row">
            {ticketData.attachment.map((data, index) => (
              <div key={index} className="flex items-center justify-center ">
                <img
                  className="h-full w-full rounded-md object-contain"
                  src={data}
                  alt=""
                  onClick={() => window.open(data, "_blank")}
                />
              </div>
            ))}
          </div>
        </div>
        {/* title & supportType */}
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="flex flex-auto flex-col items-start">
            <span className="text-sm">Title</span>
            <input
              type="text"
              name="name"
              disabled="true"
              placeholder="English"
              value={ticketData.title}
              onChange={(e) => onChangeFormData("title", e.target.value)}
              className="border-zinc-300 relative block w-full rounded-[10px] border  border-gray-300 px-3 py-2  focus:border-green-700 focus:outline-none focus:ring-green-700 sm:text-sm"
            />
          </div>
          <div className="flex flex-auto flex-col items-start">
            <span className="text-sm">Support Type</span>
            {/* <HeadlessSelect
              title={"Select Ticket Type"}
              options={ticketTypes}
              disable={true}
              selected={ticketData.support_type}
              onSelect={(value) => onChangeFormData("support_type", value)}
            /> */}
            <span className="border-zinc-300 relative block w-full rounded-[10px] border border-gray-300  bg-gray-50 px-3 py-2  focus:border-green-700 focus:outline-none focus:ring-green-700 sm:text-sm">
              {ticketData.support_type}
            </span>
          </div>
          <div className="flex flex-auto flex-col items-start">
            <span className="text-sm">Status</span>
            <HeadlessSelect
              title={"Select Ticket Type"}
              options={TICKET_STATUS}
              selected={ticketData.ticket_status}
              onSelect={(value) => onChangeFormData("ticket_status", value)}
            />
          </div>
        </div>

        <div className="flex flex-col items-start">
          <span className="text-lg">Description</span>
          <textarea
            name="description"
            disabled="true"
            value={ticketData.description}
            onChange={(e) => onChangeFormData("description", e.target.value)}
            className="w-full rounded-md border border-gray-300 px-3 py-2  focus:border-green-700 focus:outline-none focus:ring-green-700 sm:text-sm"
            rows="2"
          />
        </div>

        {/* chat box */}
        <RenderChatBox />

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
                rounded-md px-6 py-2 font-semibold text-white focus:outline-none
                ${
                  loading
                    ? "bg-indigo-400 hover:bg-indigo-400"
                    : "bg-indigo-500 hover:bg-indigo-600"
                }
              `}
          >
            {loading ? "Saving..." : "Submit"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketForm;
