import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  slidersCount: 0,
  sliders: [],
  slider: null,
  sliderMultiLang: {},
  loading: false,
};

export const slidersReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_SLIDERS:
      return {
        ...state,
        slidersCount: payload?.meta?.total_documents || 0,
        sliders: payload.slider,
        loading: false,
      };
    case ActionTypes.SET_SLIDER:
      return {
        ...state,
        slider: payload.slider,
        sliderMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_SLIDER:
      return {
        ...state,
        slidersCount: state.slidersCount - 1,
        sliders: state.sliders.filter((slider) => slider.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_SLIDER_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_SLIDER_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
