import { getMerchants } from "actions/merchants/merchants.actions";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import convertToFormData from "utils/ConvertToFormData";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import DescriptionEditor from "../components/descriptionEditor";
import FormFileUploader from "../components/fileUploader";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import { TwitterPicker } from "react-color";
import { IMAGE_SIZES } from "constants/app.constants";
import { FORM_TYPE } from "constants/app.constants";
import { getLanguages } from "actions/languages/languages.actions";
import TabSelector from "../components/TabSelector";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

const colorList = [
  "#FFFFFF", // White
  "#000000", // Black
  "#008000", // Green
  "#FF0000", // Red
  "#FFA500", // Orange
  "#FFFF00", // Yellow
  "#0000FF", // Blue
  "#800080", // Purple
  "#A52A2A", // Brown
  "#808080", // Gray
  "#FFC0CB", // Pink
  "#FF00FF", // Magenta
  "#00FF00", // Lime
  "#4B0082", // Indigo
  "#EE82EE", // Violet
  "#800000", // Maroon
  "#000080", // Navy
  "#808000", // Olive
  "#008080", // Teal
  "#00FFFF", // Aqua
  "#C0C0C0", // Silver
  "#FFD700", // Gold
  "#F5F5DC", // Beige
  "#E6E6FA", // Lavender
  "#FF7F50", // Coral
  "#40E0D0", // Turquoise
  "#D2B48C", // Tan
  "#FFE5B4", // Peach
  "#FFFFF0", // Ivory
];

const initialMultiLangData = {
  name: "",
  title: "",
  sub_title: "",
  background_image: "",
  description: "",
  color_code: "#000000",
};

const initialStoreLoyaltyData = {
  name: "",
  title: "",
  sub_title: "",
  background_image: "",
  description: "",
  merchant_id: "",
  store_loyalty_type: "",
  color_code: "#000000",
};

const StoreLoyaltyForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storeLoyaltyMultiLang } = useSelector((state) => state.storeLoyalty);
  const { languages } = useSelector((state) => state.language);
  const { loading, merchants } = useSelector((state) => state.merchant);
  const [storeLoyaltyData, setStoreLoyaltyData] = useState(
    initialStoreLoyaltyData
  );
  const [multiLangData, setMultiLangData] = useState(initialMultiLangData);
  const [lang_id, setLang_id] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    const { created_by, ...remainingData } = initialFormData;
    setStoreLoyaltyData((prevData) => ({ ...prevData, ...remainingData }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getMerchants());
  }, []);

  useEffect(() => {
    dispatch(getLanguages(1, 1000));
  }, []);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = storeLoyaltyMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initialMultiLangData, ...langData });
  }, [lang_id]);

  const onChangeFormData = ({ name, value }) => {
    if (lang_id) {
      setMultiLangData({ ...multiLangData, [name]: value });
      return;
    }
    setStoreLoyaltyData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (redirect) => {
    let payload = { ...storeLoyaltyData };
    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialStoreLoyaltyData[key] == undefined) {
        delete payload[key];
      }
    });

    if (lang_id) payload = { ...multiLangData, lang_id };
    let formData = convertToFormData(payload);
    handleFormSubmit(formData, redirect);
  };

  return (
    <div className="h-full w-full bg-white">
      {" "}
      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.STORE_LOYALTY}
          entityId={storeLoyaltyData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/storeLoyalty")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to store
              loyaltyCards page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}
      <CardHeader
        label={`${formType} Store Loyalty`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/storeLoyalty"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => handleSubmit(false),
                  variant: "primary",
                },
                {
                  text: "Delete",
                  onClick: () => handleDelete(),
                  variant: "danger",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      <div className="flex flex-col gap-3 p-4">
        {/* Tabs */}
        {formType == FORM_TYPE.UPDATE ? (
          <div className="">
            <TabSelector
              selectors={[
                { name: "Standard", value: null },
                ...languages.map((lang) => ({
                  icon: lang.flag_image,
                  name: lang.name,
                  value: lang.id,
                })),
              ]}
              activeSelector={lang_id}
              setActiveSelector={(value) => setLang_id(value)}
            />
          </div>
        ) : null}

        {!(formType == FORM_TYPE.UPDATE && lang_id) ? (
          <Fragment>
            <div className="flex flex-auto flex-col items-start gap-1">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Merchant
              </label>
              <HeadlessSelectObject
                title={
                  !merchants.length ? "NO Merchant Found" : "Select Merchant"
                }
                disable={!merchants.length}
                options={merchants.map((merchant) => ({
                  name: merchant.name,
                  value: merchant.id,
                }))}
                selected={storeLoyaltyData.merchant_id}
                onSelect={(value) =>
                  onChangeFormData({ name: "merchant_id", value })
                }
              />
            </div>
            <div className="flex flex-auto flex-col items-start gap-1">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Store Loyality Type
              </label>
              <HeadlessSelectObject
                title={"Select Store Loyality Type"}
                // disable={!merchants.length}
                options={[
                  {
                    name: "Claim Membership",
                    value: "membership-claim",
                  },
                ]}
                selected={storeLoyaltyData.store_loyalty_type}
                onSelect={(value) =>
                  onChangeFormData({ name: "store_loyalty_type", value })
                }
              />
            </div>
          </Fragment>
        ) : null}

        {/* color picker */}
        <div className="flex h-full w-full flex-col items-start gap-1">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Card Text Color
          </label>
          <div className="flex gap-2 rounded-md border border-gray-300 p-2 ">
            <div
              className={`h-10 w-10 rounded-md border border-gray-300`}
              style={{
                backgroundColor: lang_id
                  ? multiLangData.color_code
                  : storeLoyaltyData.color_code,
              }}
              onClick={() => setIsPickerVisible((prev) => !prev)}
            />
            <div className="flex items-center justify-center rounded-md border border-gray-300 bg-gray-100 p-2">
              {lang_id ? multiLangData.color_code : storeLoyaltyData.color_code}
            </div>
          </div>
          {isPickerVisible && (
            <div className="absolute z-10 mt-24">
              <TwitterPicker
                colors={colorList}
                triangle="top-left"
                color={
                  lang_id
                    ? multiLangData.color_code
                    : storeLoyaltyData.color_code
                }
                onChange={(color) =>
                  onChangeFormData({ name: "color_code", value: color.hex })
                }
              />
            </div>
          )}
        </div>

        <div className="flex h-full w-full flex-col items-start gap-1">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Card Background Image
          </label>
          <FormFileUploader
            size={IMAGE_SIZES.LOYALTY_CARD}
            image={
              lang_id
                ? multiLangData.background_image
                : storeLoyaltyData.background_image
            }
            handleUpload={(acceptedFiles) =>
              onChangeFormData({
                name: "background_image",
                value: acceptedFiles[0],
              })
            }
          />
        </div>

        <div className="flex flex-auto flex-col items-start gap-1">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Store Loyality Name
          </label>
          <input
            type="text"
            name="name"
            value={lang_id ? multiLangData.name : storeLoyaltyData.name}
            onChange={(e) =>
              onChangeFormData({
                name: "name",
                value: e.target.value,
              })
            }
            className="block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
          />
        </div>

        <div className="flex flex-auto flex-col items-start gap-1">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Card Title
          </label>
          <input
            type="text"
            name="title"
            value={lang_id ? multiLangData.title : storeLoyaltyData.title}
            onChange={(e) =>
              onChangeFormData({
                name: "title",
                value: e.target.value,
              })
            }
            className="block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
          />
        </div>

        <div className="flex flex-auto flex-col items-start gap-1">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Card Sub Title
          </label>
          <input
            type="text"
            name="sub_title"
            value={
              lang_id ? multiLangData.sub_title : storeLoyaltyData.sub_title
            }
            onChange={(e) =>
              onChangeFormData({
                name: "sub_title",
                value: e.target.value,
              })
            }
            className="block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
          />
        </div>

        <div className="flex flex-auto flex-col items-start gap-1">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Description (Editor)
          </label>
          <DescriptionEditor
            value={
              lang_id ? multiLangData.description : storeLoyaltyData.description
            }
            onChange={(value) =>
              onChangeFormData({
                name: "description",
                value: value,
              })
            }
          />
        </div>

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${
              loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
            }
            `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoreLoyaltyForm;
