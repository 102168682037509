import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getTickets = (page = 1, limit = 50) => {
  return RestClient.Get(`${API_URL.GET_TICKETS}?page=${page}&limit=${limit}`);
};
const getTicketTypes = () => {
  return RestClient.Get(`${API_URL.GET_TICKET_TYPES}`);
};
const getTicket = (ticketId) => {
  return RestClient.Get(`${API_URL.GET_TICKET}/${ticketId}`);
};

const createTicket = (payload) => {
  return RestClient.Post(API_URL.CREATE_TICKET, payload);
};

const updateTicket = (ticketId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_TICKET}/${ticketId}`, payload);
};

const deleteTicket = (ticketId) => {
  return RestClient.Delete(`${API_URL.DELETE_TICKET}/${ticketId}`);
};

const exportedObject = {
  getTickets,
  getTicketTypes,
  getTicket,
  createTicket,
  updateTicket,
  deleteTicket,
};

export default exportedObject;
