import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import RegionService from "../../services/regions/RegionService";
import * as ActionTypes from "../actionTypes";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_REGION_LOADING,
    payload: loading,
  });
};

export const setCountries = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_COUNTRIES,
    payload: data,
  });
};

export const setStates = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STATES,
    payload: data,
  });
};

export const setCities = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CITIES,
    payload: data,
  });
};

export const setAreas = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_AREAS,
    payload: data,
  });
};

export const setArea = (area) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_AREA,
    payload: area,
  });
};

export const removeArea = (areaId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_AREA,
    payload: areaId,
  });
};

export const resetRegionState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_REGION_STATE,
  });
};

export const getCountries = (page, limit, name) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await RegionService.getCountries(page, limit, name);
    if (response.status == 1 && response.data) {
      dispatch(setCountries(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load countries", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getStates =
  (country_code, name, page, limit) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await RegionService.getStates(
        country_code,
        name,
        page,
        limit
      );
      if (response.status == 1 && response.data) {
        dispatch(setStates(response.data));
        return;
      }
      dispatch(
        setAlert(response.message ?? "failed to load states", AlertTypes.ERROR)
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getCities =
  (country_code, state_code, name, page, limit) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await RegionService.getCities(
        country_code,
        state_code,
        name,
        page,
        limit
      );
      if (response.status == 1 && response.data) {
        dispatch(setCities(response.data));
        return;
      }
      dispatch(
        setAlert(response.message ?? "failed to load cities", AlertTypes.ERROR)
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getAreas = (country, state, city) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await RegionService.getAreas(country, state, city);
    if (response.status == 1 && response.data) {
      dispatch(setAreas(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load areas", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getArea = (areaId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await RegionService.getArea(areaId);
    if (response.status == 1 && response.data) {
      dispatch(setArea(response.data.area));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load area", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createArea = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await RegionService.createArea(payload);
    if (response.status == 1 && response.data) {
      dispatch(
        setAlert(
          response.message ?? "Area created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/areas");
    }
    dispatch(
      setAlert(response.message ?? "failed to create area", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateArea = (areaId, payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await RegionService.updateArea(areaId, payload);
    if (response.status == 1 && response.data) {
      dispatch(
        setAlert(
          response.message ?? "Area updated successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/areas");
    }
    dispatch(
      setAlert(response.message ?? "failed to update area", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteArea = (areaId, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await RegionService.deleteArea(areaId);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Area deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeArea(areaId));
      if (!navigate) return;
      return navigate("/areas");
    }
    dispatch(
      setAlert(response.message ?? "failed to delete Area", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getRegions =
  (country_code, state_code, country_id, state_id, city_id) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await RegionService.getCountries();
      if (response.status == 1 && response.data) {
        dispatch(setCountries(response.data));
      }

      // get states
      const statesResponse = await RegionService.getStates(country_code);
      if (statesResponse.status == 1 && statesResponse.data) {
        dispatch(setStates(statesResponse.data));
      }

      // get Cities
      const citiesResponse = await RegionService.getCities(
        country_code,
        state_code
      );
      if (citiesResponse.status == 1 && citiesResponse.data) {
        dispatch(setCities(citiesResponse.data));
      }

      // get Cities
      if (country_id && state_id && city_id) {
        const response = await RegionService.getAreas(
          country_id,
          state_id,
          city_id
        );
        if (response.status == 1 && response.data) {
          dispatch(setAreas(response.data));
        }
      }
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };
