import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getLogs = (entity_name, entity_id) => {
  return RestClient.Get(`${API_URL.GET_LOGS}/${entity_name}/${entity_id}`);
};

const exportedObject = {
  getLogs,
};

export default exportedObject;
