import { createFaq } from "actions/faqs/faqs.action";
import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import FaqsForm from "./faqsForm";

const CreateFaq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <FaqsForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) => dispatch(createFaq(formData, navigate))}
      />
    </div>
  );
};

export default CreateFaq;
