import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getLocalizations = (page = 1, limit = 50, lang_id, key, value) => {
  return RestClient.Get(
    `${API_URL.GET_LOCALIZATIONS}?page=${page}&limit=${limit}&lang_id=${lang_id}&key=${key}&value=${value}`
  );
};

const createLocalization = (payload) => {
  return RestClient.Post(API_URL.CREATE_LOCALIZATION, payload);
};

const updateLocalization = (localization_id, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_LOCALIZATION}/${localization_id}`,
    payload
  );
};

const deleteLocalization = (localization_id) => {
  return RestClient.Delete(`${API_URL.DELETE_LOCALIZATION}/${localization_id}`);
};

const exportedObject = {
  getLocalizations,
  createLocalization,
  updateLocalization,
  deleteLocalization,
};

export default exportedObject;
