import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

export default function Pagination({ page, setPage, limit, totalCount }) {

    const calculatePaginateRecords = () => {
        const record_from = (page - 1) * limit + 1;
        const record_to = page * limit;
        const total_record = Math.ceil(totalCount);
        return {
            total_record,
            record_from: record_from <= total_record ? record_from : total_record,
            record_to: record_to <= total_record ? record_to : total_record,
            pages: Math.ceil(totalCount / limit),
        };
    };

    const countPaginate = calculatePaginateRecords()
    return (

        <div className="flex flex-row justify-between items-center mt-2 p-2 bg-indigo-50 border border-indigo-300 rounded-md">
            <div className='w-full'>
                <span>Showing {countPaginate.record_from} to {countPaginate.record_to} of {countPaginate.total_record} results</span>
            </div>
            <div className='w-full flex justify-end gap-4'>
                <button
                    onClick={() => setPage(page - 1)}
                    disabled={page <= 1}
                    class={`
          flex items-center justify-center px-3 h-8 gap-1 text-sm font-medium text-gray-500 bg-gray-100 border border-gray-300 rounded-lg
          ${page > 1 ? 'bg-white hover:bg-gray-100 text-gray-700 hover:text-gray-800 cursor-pointer' : ''}
          `}
                >
                    <FaArrowLeft className="w-3.5 h-3.5" />
                    <span>Previous</span>
                </button>
                <button
                    disabled={page >= countPaginate.pages}
                    onClick={() => setPage(page + 1)}
                    class={`
          flex items-center justify-center gap-1 px-3 h-8 text-sm font-medium text-gray-500 bg-gray-100 border border-gray-300 rounded-lg
          ${!(page >= countPaginate.pages) ? 'bg-white hover:bg-gray-100 text-gray-700 hover:text-gray-800 cursor-pointer' : ''}
          `}
                >
                    <span>Next</span>
                    <FaArrowRight className="w-3.5 h-3.5" />
                </button>
            </div>
        </div>
    )
}