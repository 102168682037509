import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../../actions/actionTypes";
import Categoryservice from "../../services/categories/Categoryservice";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CATEGORY_LOADING,
    payload: loading,
  });
};

export const setCategories = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CATEGORIES,
    payload: data,
  });
};

export const setCategory = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CATEGORY,
    payload: data,
  });
};

export const editCategory = (category) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_CATEGORY,
    payload: category,
  });
};

export const removeCategory = (categoryId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_CATEGORY,
    payload: categoryId,
  });
};

export const resetCategoryState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_CATEGORY_STATE,
  });
};

export const getCategories = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await Categoryservice.getCategories(page, limit);
    if (response.status == 1) {
      dispatch(setCategories(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load categories",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getCategory = (categoryId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await Categoryservice.getCategory(categoryId);
    if (response.data && response.status == 1) {
      dispatch(setCategory(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Category", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createCategory = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await Categoryservice.createCategory(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "category created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/categories");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to create category",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateCategory =
  (categoryId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await Categoryservice.updateCategory(
        categoryId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "category updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/categories");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update category",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateMultiLangCategory =
  (categoryId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await Categoryservice.updateMultiLangCategory(
        categoryId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "category updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/categories");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update category",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteCategory = (categoryId, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await Categoryservice.deleteCategory(categoryId);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "category deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeCategory(categoryId));
      if (!navigate) return;
      return navigate("/categories");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to delete category",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
