import Card from "components/card";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import merchantPointsIcon from "assets/img/airplay.png";
import { Link, useNavigate } from "react-router-dom";
import { roundToTwoDecimals } from "utils/App.utils";
import { FaArrowRight } from "react-icons/fa";
import { formatNumberWithCommas } from "utils/App.utils";
import { formatLocalTimeDate } from "utils/App.utils";

function OrdersTableCard({ orders, children }) {
  const navigate = useNavigate();
  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      <div className="flex items-center gap-1">
        <img className="h-4 w-4 " src={merchantPointsIcon} alt="" />
        <p>Name</p>
      </div>
    </div>,
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      {"Customer"}
    </div>,
    "Number",
    "Amount",
    "Discount",
    "Date",
    "Actions",
  ];

  const records = orders.map((order) => ({
    1: (
      <div className="flex items-center gap-2">
        <div className="flex h-10 w-10 items-center justify-center">
          <img
            className="h-9 w-9 rounded-full"
            src={order.merchant_id?.logo ?? ProfileIcon}
            alt=""
          />
        </div>
        {order.merchant_id?.name ?? ""}
      </div>
    ),

    2: (
      <Link
        to={`/customer/profile/${order?.customer_id?.id}`}
        className="flex items-center gap-2"
      >
        <div className="flex h-10 w-10 items-center justify-center">
          <img
            className="h-9 w-9 cursor-pointer rounded-full"
            src={order.customer_id?.profile_pic || ProfileIcon}
            alt=""
          />
        </div>{" "}
        {order.customer_id?.name}
      </Link>
    ),
    order_number: order.order_number,
    order_amount: `${order.merchant_id?.currency || ""
      } ${formatNumberWithCommas(Number(order?.amount || 0).toFixed(2))}`,
    discount_amount: `${order.merchant_id?.currency || ""
      } ${formatNumberWithCommas(
        Number(order?.discount_amount || 0).toFixed(2)
      )}`,

    created_at: formatLocalTimeDate(order.created_at),
    actions: (
      <div className="flex items-center justify-between gap-2">
        <Link
          to={`/transactions?order_number=${order.order_number}`}
          class={`
        cursor-pointer' flex h-8 items-center justify-center gap-1 rounded-lg border border-gray-300 bg-white px-3
         text-sm font-medium text-gray-500 hover:text-gray-700
        `}
        >
          <span>View&nbsp;Transactions</span>
          <FaArrowRight className="h-3.5 w-3.5" />
        </Link>
      </div>
    ),
  }));

  return (
    <div>
      <Card extra={" bg-white"}>
        <CardHeader label={"Orders"} />
        {children}

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default OrdersTableCard;
