import React from "react";

const FormInputField = ({
  label,
  required,
  disabled,
  type,
  name,
  value,
  placeholder = "",
  onChange,
  className = "",
  ...props
}) => {
  return (
    <div className="w-full">
      {label && (
        <label className="mb-1 block text-base font-medium text-gray-800">
          {label} {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <input
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        {...(required ? { required: true } : {})}
        {...(disabled ? { disabled: true } : {})}
        className={`w-full rounded-md  border-[1px] border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none ${className}`} // Merge passed classes with default ones
        {...props}
      />
    </div>
  );
};

export default FormInputField;
