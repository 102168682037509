import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  categoriesCount: 0,
  categories: [],
  category: null,
  categoryMultiLang: {},
  loading: false,
};

export const storeCategoriesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_STORE_CATEGORIES:
      return {
        ...state,
        categoriesCount: payload?.meta?.total_documents || 0,
        categories: payload.categories,
        loading: false,
      };
    case ActionTypes.SET_STORE_CATEGORY:
      return {
        ...state,
        category: payload.category,
        categoryMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_STORE_CATEGORY:
      return {
        ...state,
        categoriesCount: state.categoriesCount - 1,
        categories: state.categories.filter(
          (category) => category.id !== payload
        ),
        loading: false,
      };
    case ActionTypes.SET_STORE_CATEGORY_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_STORE_CATEGORY_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
