import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FaqsTableCard from "./faqs-table-card";
import { getFaqs } from "actions/faqs/faqs.action";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const Faqs = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { faqsCount, faqs, loading } = useSelector((state) => state.faq);

  useEffect(() => {
    dispatch(getFaqs(page, limit));
  }, [page]);

  if (loading) return;
  return (
    <div className="h-full w-full">
      <FaqsTableCard faqs={faqs} />

      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={faqsCount}
      />
    </div>
  );
};

export default Faqs;
