import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getCurrencies = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_CURRENCIES}?page=${page}&limit=${limit}`
  );
};

const getCurrency = (currency_id) => {
  return RestClient.Get(`${API_URL.GET_CURRENCY}/${currency_id}`);
};

const createCurrency = (payload) => {
  return RestClient.Post(API_URL.CREATE_CURRENCY, payload);
};

const updateCurrency = (currency_id, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_CURRENCY}/${currency_id}`, payload);
};

const deleteCurrency = (currency_id) => {
  return RestClient.Delete(`${API_URL.DELETE_CURRENCY}/${currency_id}`);
};

const exportedObject = {
  getCurrencies,
  getCurrency,
  createCurrency,
  updateCurrency,
  deleteCurrency,
};

export default exportedObject;
