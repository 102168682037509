import { createArea } from "actions/regions/regions.actions";
import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AreaForm from "./areaForm";
import { resetRegionState } from "actions/regions/regions.actions";
import { getCountries } from "actions/regions/regions.actions";
import { useEffect } from "react";

const CreateArea = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetRegionState())
    dispatch(getCountries());
  }, []);

  return (
    <div className="h-full w-full">
      <AreaForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(payload) =>
          dispatch(createArea(payload, navigate))
        }
      />
    </div>
  );
};

export default CreateArea;
