import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const GetAllTransaction = (
  page = 1,
  limit = 50,
  merchant_id,
  customer_id,
  country_id,
  state_id,
  city_id,
  startDate,
  endDate,
  order_number
) => {
  const merchant = merchant_id ? `&merchant_id=${merchant_id}` : "";
  const customer = customer_id ? `&customer_id=${customer_id}` : "";
  const country = country_id ? `&country_id=${country_id}` : "";
  const state = state_id ? `&state_id=${state_id}` : "";
  const city = city_id ? `&city_id=${city_id}` : "";
  const start_date = startDate ? `&startDate=${startDate}` : "";
  const end_date = endDate ? `&endDate=${endDate}` : "";
  const orderNumber = order_number ? `&order_number=${order_number}` : "";
  return RestClient.Get(
    `${API_URL.GET_TRANSACTIONS}?page=${page}&limit=${limit}${merchant}${customer}${country}${state}${city}${start_date}${end_date}${orderNumber}`
  );
};

const exportTransactions = (
  merchant_id,
  customer_id,
  country_id,
  state_id,
  city_id,
  startDate,
  endDate,
  order_number
) => {
  const merchant = merchant_id ? `&merchant_id=${merchant_id}` : "";
  const customer = customer_id ? `&customer_id=${customer_id}` : "";
  const country = country_id ? `&country_id=${country_id}` : "";
  const state = state_id ? `&state_id=${state_id}` : "";
  const city = city_id ? `&city_id=${city_id}` : "";
  const start_date = startDate ? `&startDate=${startDate}` : "";
  const end_date = endDate ? `&endDate=${endDate}` : "";
  const orderNumber = order_number ? `&order_number=${order_number}` : "";
  return RestClient.Get(
    `${API_URL.EXPORT_TRANSACTIONS}?${merchant}${customer}${country}${state}${city}${start_date}${end_date}${orderNumber}`
  );
};

const exportedObject = {
  GetAllTransaction,
  exportTransactions,
};

export default exportedObject;
