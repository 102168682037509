import Card from "components/card";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "../../components/cardHeader";
import Table from "../../components/table";

function CitiesTableCard({ cities, filters }) {
  const { countryCode } = useParams();
  const navigate = useNavigate();
  const tableHeaders = ["Name", "State", "Country"];

  const records = cities.map((city) => ({
    name: city.name,
    state_code: city.state_code,
    country_code: city.country_code,
  }));

  return (
    <div>
      <Card extra={"bg-white"}>
        <CardHeader
          label={"Cities"}
        />
        {filters}

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default CitiesTableCard;
