import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  ticketsCount: 0,
  tickets: [],
  ticketTypes: [],
  ticket: null,
  loading: false,
  // sliderMultiLang: {},
};

export const ticketsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_TICKETS:
      return {
        ...state,
        ticketsCount: payload?.meta?.total_documents || 0,
        tickets: payload.ticket,
        loading: false,
      };
    case ActionTypes.SET_TICKET_TYPES:
      return {
        ...state,
        ticketTypes: payload,
        loading: false,
      };
    case ActionTypes.SET_TICKET:
      return {
        ...state,
        ticket: payload,
        // sliderMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_TICKET:
      return {
        ...state,
        ticketsCount: state.ticketsCount - 1,
        tickets: state.tickets.filter((ticket) => ticket.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_TICKET_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_TICKET_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
