import Card from "components/card";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CardHeader from "../../components/cardHeader";
import Table from "../../components/table";

function CountriesTableCard({ countries, filters }) {
  const navigate = useNavigate();
  const tableHeaders = ["Name", "Code", "Dial Code", "Actions"];

  const records = countries.map((country) => ({
    name: country.name,
    code: country.code,
    dial_code: country.dial_code,
    // created_at: dayjs(country.created_at).format("DD-MM-YYYY"),
    // updated_at: dayjs(country.updated_at).format("DD-MM-YYYY"),
    actions: (
      <div className="flex items-center justify-between gap-2">
        <button
          onClick={() => navigate(`/countries/${country.code}/states`)}
          class={`
          cursor-pointer' : ''} flex h-8 items-center justify-center gap-1 rounded-lg border border-gray-300 bg-white px-3
           text-sm font-medium text-gray-500 hover:text-gray-700
          `}
        >
          <span>View&nbsp;States</span>
          <FaArrowRight className="h-3.5 w-3.5" />
        </button>
      </div>
    ),
  }));

  return (
    <div>
      <Card extra={" bg-white"}>
        <CardHeader
          label={"Countries"}
        />
        {filters}

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default CountriesTableCard;
