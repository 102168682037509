import { setAlert } from "actions/alert/alert.actions";
import { getMerchants } from "actions/merchants/merchants.actions";
import { FORM_TYPE } from "constants/app.constants";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AlertTypes } from "reducers/alert/alert.reducer";
import { convertDateTimeToUTC, formatLocalTimeDate, submitFormHandler } from "utils/App.utils";
import convertToFormData from "utils/ConvertToFormData";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import MediaUploader from "../components/mediaUploader";
import { FILE_TYPE } from "constants/app.constants";
import { getMerchantVibeCategories } from "actions/vibeCategories/vibeCategories.action";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

const initialVibeData = {
  image: "",
  thumbnail: "",
  isVideo: false,
  swipe_text: "",
  vibe_category: "",
  merchant_id: "",
  expiry: dayjs(new Date()).add(24, "hour").format("YYYY-MM-DDTHH:mm"),
};

const VibeForm = ({ formType, initialFormData, handleFormSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vibes, merchants, loading } = useSelector((state) => state.merchant);
  const { categories, loading: vibeCategoryLoading } = useSelector(
    (state) => state.vibeCategory
  );

  // local-state
  const [vibeData, setVibeData] = useState(initialVibeData);
  const formRef = useRef(null);
  const shouldRedirect = useRef(true);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    setVibeData((prevData) => ({
      ...prevData,
      ...initialFormData,
      expiry: initialFormData?.expiry
        ? formatLocalTimeDate(initialFormData.expiry, "YYYY-MM-DDTHH:mm")
        : "",
    }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getMerchants(1, 1000));
  }, []);

  useEffect(() => {
    if (!vibeData.merchant_id) return;
    dispatch(getMerchantVibeCategories(vibeData.merchant_id));
  }, [vibeData.merchant_id]);

  // submit-handler
  const handleSubmit = (e) => {
    e?.preventDefault(e);

    const { image, thumbnail, swipe_text, merchant_id, expiry } = vibeData;

    const errors = [];
    if (!image) errors.push("image is required");
    if (!merchant_id) errors.push("merchant is required");
    if (errors.length) {
      errors.map((error) => dispatch(setAlert(error, AlertTypes.ERROR)));
      return;
    }

    let payload = {
      ...vibeData,
      expiry: convertDateTimeToUTC(expiry),
    };
    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialVibeData[key] == undefined) {
        delete payload[key];
      }
    });
    if (!payload.vibe_category) delete payload.vibe_category;

    const fromData = convertToFormData(payload);
    handleFormSubmit(fromData, shouldRedirect.current);
    shouldRedirect.current = true;
  };

  const onChangeFormData = ({ name, value }) => {
    setVibeData((prevState) => ({ ...prevState, [name]: value }));
    if (name == "merchant_id") {
      setVibeData((prevState) => ({ ...prevState, vibe_category: "" }));
    }
  };

  const handleMediaUpload = (fileType, media, thumbnail) => {
    setVibeData((prevState) => ({
      ...prevState,
      isVideo: fileType === FILE_TYPE.VIDEO,
      image: media,
      thumbnail,
      thumbnail,
    }));
  };

  return (
    <div className="h-full w-full bg-white">
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.MERCHANT_VIBE}
          entityId={vibeData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/vibes")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to vibes
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}

      <CardHeader
        label={`${formType} Vibe`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/vibes"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => {
              submitFormHandler(formRef.current);
            },
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => {
                    shouldRedirect.current = false;
                    submitFormHandler(formRef.current);
                  },
                  variant: "primary",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      <div className="flex flex-col gap-3 p-4">
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 p-4"
        >
          <div className={`flex flex-col justify-between gap-2`}>
            {/* image */}
            <div className="flex h-full w-full flex-col items-start gap-1">
              <label
                for="image"
                className="mb-1 block text-base font-medium text-gray-800"
              >
                Image
              </label>
              {/* <FormFileUploader
                // size={IMAGE_SIZES.VIBE}
                image={vibeData.image}
                handleUpload={(acceptedFiles) =>
                  onChangeFormData({ name: "image", value: acceptedFiles[0] })
                }
              /> */}
              <MediaUploader
                file_types={[FILE_TYPE.IMAGE, FILE_TYPE.VIDEO]}
                media={vibeData.thumbnail}
                handleUpload={({ fileType, media, thumbnail }) =>
                  handleMediaUpload(fileType, media, thumbnail)
                }
                aspectRatio={"9:16"}
              />
              {/* <ALLFileUploader
                size={IMAGE_SIZES.VIBE}
                image={vibeData.image}
                handleUpload={(acceptedFiles) =>
                  onChangeFormData({ name: "image", value: acceptedFiles[0] })
                }
              /> */}
            </div>
            <div className="flex flex-col items-start">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Swipe Text
              </label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={vibeData.swipe_text}
                onChange={(e) =>
                  onChangeFormData({
                    name: "swipe_text",
                    value: e.target.value,
                  })
                }
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
              />
            </div>

            <div className="flex flex-auto flex-col items-start gap-1">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Vibe Category
              </label>
              <HeadlessSelectObject
                title={
                  !categories.length ? "NO Category Found" : "Select Category"
                }
                disable={!categories.length}
                options={categories.map((category) => ({
                  name: category.name,
                  value: category.id,
                }))}
                selected={vibeData.vibe_category}
                onSelect={(value) =>
                  onChangeFormData({ name: "vibe_category", value })
                }
              />
            </div>

            <div className="flex flex-auto flex-col items-start gap-1">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Merchant
              </label>
              <HeadlessSelectObject
                title={
                  !merchants.length ? "NO Merchant Found" : "Select Merchant"
                }
                disable={!merchants.length}
                options={merchants.map((merchant) => ({
                  name: merchant.name,
                  value: merchant.id,
                }))}
                selected={vibeData.merchant_id}
                onSelect={(value) =>
                  onChangeFormData({ name: "merchant_id", value })
                }
              />
            </div>
            <div className="flex flex-col justify-between gap-2 md:flex-row">
              {/* expiry */}
              <div className="w-full">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Expiry
                </label>
                <input
                  // required
                  type="datetime-local"
                  name="expiry"
                  value={vibeData.expiry}
                  onChange={(e) => onChangeFormData(e.target)}
                  className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
                />
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="flex justify-between">
            <button
              disabled={loading}
              type="submit"
              // onClick={() => handleSubmit()}
              className={`
          rounded-md px-6 py-2 font-semibold text-white focus:outline-none
          ${
            loading
              ? "bg-indigo-400 hover:bg-indigo-400"
              : "bg-indigo-500 hover:bg-indigo-600"
          }
          `}
            >
              {loading ? "Saving..." : "Save"}
            </button>
            <button
              type="button"
              onClick={() => setShowCancelModal(true)}
              className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VibeForm;
