import { deleteMerchantTag } from "actions/merchants/merchants.actions";
import Card from "components/card";
import { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";

function MerchantTagsTableCard({ tags }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [delTag, setDelTag] = useState(null);

  const onClickDelete = (tag) => {
    setDelTag(tag);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteMerchantTag(delTag.id));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
    setDelTag(null);
  };

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className=" h-10 w-10 " />
      {"Name"}
    </div>,
    "Created By",
    "Actions",
  ];
  const records = tags.map((tag) => ({
    name: (
      <div className="flex items-center gap-2">
        <div className=" h-10 w-10 ">
          <img
            className="h-9 w-9 rounded-full"
            src={tag.image !== null && tag.image !== undefined ? tag.image : ""}
            alt=""
          />
        </div>
        {tag.name.split(" ").join("\u00A0")}
      </div>
    ),
    created_by: tag?.created_by?.name ?? "",
    actions: (
      <div className="flex items-center gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => navigate(`/merchant-tags/update/${tag.id}`)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
            onClick={() => onClickDelete(tag)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));

  return (
    <div>
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the "{delTag.name}" merchant tag</h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={"w-full bg-white"}>
        <CardHeader
          label={"Merchants Tags"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [{
                  text: "Add New",
                  onClick: () => navigate("/merchant-tags/create"),
                variant: "primary",
                }]
              : null
          }
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default MerchantTagsTableCard;
