import { FILE_TYPE } from "constants/app.constants";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdFileUpload } from "react-icons/md";
import createThumbnail from "utils/createThumbnail";

const MediaUploader = ({ file_types, media, handleUpload, aspectRatio }) => {
    const [error, setError] = useState("");

    const validateAspectRatio = (width, height) => {
        const ratio = width / height;
        const [expectedWidth, expectedHeight] = aspectRatio.split(":").map(Number);
        const expectedRatio = expectedWidth / expectedHeight;

        return Math.abs(ratio - expectedRatio) < 0.01; // Allowing small margin for floating-point precision
    };

    const onDrop = async (acceptedFiles) => {
        setError("");

        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const fileType = file.type.startsWith("image/") ? FILE_TYPE.IMAGE : FILE_TYPE.VIDEO;

            const thumbnail = await createThumbnail(file, fileType);
            if (!aspectRatio) return handleUpload({ fileType, media: file, thumbnail });

            if (fileType === FILE_TYPE.IMAGE) {
                const img = new Image();
                img.src = URL.createObjectURL(file);

                img.onload = () => {
                    if (validateAspectRatio(img.width, img.height)) {
                        handleUpload({ fileType, media: file, thumbnail });
                    } else {
                        setError(`Image aspect ratio must be ${aspectRatio}`);
                    }
                };
            } else if (fileType === FILE_TYPE.VIDEO) {
                const video = document.createElement("video");
                video.src = URL.createObjectURL(file);

                video.onloadedmetadata = () => {
                    if (validateAspectRatio(video.videoWidth, video.videoHeight)) {
                        handleUpload({ fileType, media: file, thumbnail });
                    } else {
                        setError(`Video aspect ratio must be ${aspectRatio}`);
                    }
                };
            }
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        disabled: false,
        accept: {
            // image file types
            ...(!file_types || file_types.includes(FILE_TYPE.IMAGE) ?
                {
                    "image/jpeg": [".jpeg", ".jpg"],
                    "image/png": [".png"],
                }
                : {}
            ),
            // video file types
            ...(!file_types || file_types.includes(FILE_TYPE.VIDEO) ?
                {
                    "video/mp4": [".mp4"],
                }
                : {}
            ),
        },
    });

    return (
        <div className="h-full w-full">
            <div
                {...getRootProps()}
                className="border-1 flex h-24 w-full cursor-pointer items-center justify-between rounded-xl border border-dashed border-green-400 p-2 hover:border-green-500"
            >
                <input {...getInputProps()} />
                <div className="flex justify-center">
                    {media ? (
                        <img
                            className="h-20 w-20 rounded-lg object-cover"
                            src={
                                typeof media == "string"
                                    ? media
                                    : media instanceof File
                                        ? URL.createObjectURL(media)
                                        : ""
                            }
                            alt=""
                        />
                    ) : (
                        <MdFileUpload className="mx-auto block h-16 w-16 max-w-[100px]" />
                    )}
                </div>
                <div className="flex flex-auto flex-col items-center justify-center lg:flex-row lg:gap-4">
                    <p className="whitespace-wrap text-center text-sm text-gray-900">
                        Drag or click to upload media{" "}
                        {aspectRatio ? `(Aspect Ratio: ${aspectRatio})` : ""}
                    </p>
                    <p className="text-center text-xs text-gray-700">
                        Formats: .jpg, .png, .mp4
                    </p>
                    {error ? (
                        <p className="py-1 text-center text-xs text-red-600">{error}</p>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default MediaUploader;
