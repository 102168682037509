import Card from "components/card";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { useNavigate } from "react-router-dom";
import HeadlessSelect from "../components/headless/select.headless";
import { useDispatch } from "react-redux";
import { updateRole } from "actions/roles/roles.actions";
import { useState } from "react";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";

const getRoleClasses = (access) => {
  switch (access) {
    case "Full-Access":
      return "bg-green-500 text-green-900"; // changed to green
    case "Editor-Access":
      return "bg-yellow-200 text-yellow-800"; // changed to yellow
    case "Read-Only-Access":
      return "bg-blue-200 text-blue-800"; // changed to blue
    case "No-Access":
      return "bg-red-200 text-red-800"; // kept red
    default:
      return "bg-gray-200 text-gray-800"; // kept gray as default
  }
};

function RolesTableCard({ roles }) {
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false)
  const dispatch = useDispatch()

  const HandleUpdateRolePermission = (roleId, role_type, action) => {
    const payload = {
      role_type,
      action,
    }
    dispatch(updateRole(roleId, payload))
  }

  // Extract unique permission types across all roles to form headers
  const permissionHeaders = ["Role Name"];
  roles.forEach((role) => {
    role.permissions.forEach((permission) => {
      const formattedRoleType = permission.role_type
        .replace(/-/g, " ")
        .replace("Management", "");

      if (!permissionHeaders.includes(formattedRoleType)) {
        permissionHeaders.push(formattedRoleType);
      }
    });
  });

  // Create records with permissions transposed
  const records = permissionHeaders.slice(1).map((header) => {
    const record = { "Role Name": header };
    roles.forEach((role) => {
      const permission = role.permissions.find(
        (perm) =>
          perm.role_type.replace(/-/g, " ").replace("Management", "") === header
      );
      if (permission) {
        record[role.name.replace(/-/g, " ")] = (
          <>
            {isEditable ?
              <div className='w-full'>
                <HeadlessSelect
                  title={'select access'}
                  options={Object.values(PERMISSION_ACTION_TYPES)}
                  selected={permission.action}
                  onSelect={(action) => HandleUpdateRolePermission(role.id, permission.role_type, action)}
                />
              </div>
              :
              <span
                className={`inline-block text-nowrap rounded-full px-2 py-1 text-xs font-bold ${getRoleClasses(
                  permission.action
                )}`}
              >
                {/* {permission.action.replace(/-/g, " ")} */}
                {permission.action}
              </span>
            }
          </>
        );
      }
    });
    return record;
  });

  // Create new headers with roles as columns
  const transposedHeaders = [
    "",
    ...roles.map((role) => role.name.replace(/-/g, " ")),
  ];

  return (
    <div>
      <Card extra={" bg-white"}>
        <CardHeader label={"Roles"}
          buttons={[{
            text: `${isEditable ? "Cancel" : "Edit"}`,
            onClick: () => setIsEditable(!isEditable)
          }]}
        />
        <Table
          tableData={{
            headers: transposedHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default RolesTableCard;
