import Card from "components/card";
import { useDispatch } from "react-redux";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import ToggleSwitch from "../components/toggleSwitch";
import GridActionButtonEdit from "../components/gridActionButtonEdit";
import GridActionButtonDelete from "../components/gridActionButtonDelete";
import { updateEnableFlag } from "actions/devices/devices.action";
import { updateIntegrationFlag } from "actions/devices/devices.action";

function DeviceTableCard({
  devices,
  onClickAddNew,
  onClickEdit,
  onClickDelete,
}) {
  const dispatch = useDispatch();

  const handleEnableToggle = (device_id) => {
    dispatch(updateEnableFlag(device_id));
  };

  const handleIntegrationFlagToggle = (device_id) => {
    dispatch(updateIntegrationFlag(device_id));
  };

  const tableHeaders = [
    "Device ID",
    "Name",
    "Brand",
    "Merchant",
    "Integration",
    "Enable",
    "Action",
  ];
  const records = devices.map((device) => ({
    deviceId: device?.device_id,
    name: device?.name,
    brand: device?.brand_id?.name,
    merchant: device?.merchant_id?.name,
    // is_not_integrated: (
    //   <ToggleSwitch
    //     isEnabled={!!device?.is_not_integrated}
    //     handleChange={() => {
    //       handleIntegrationFlagToggle(device.id);
    //     }}
    //   />
    // ),
    is_not_integrated: (
      <span className={`inline-block text-nowrap rounded-full ${device?.is_not_integrated ? 'bg-red-500' : 'bg-green-500'} px-2 py-1 text-sm font-bold text-white`}>
        {device?.is_not_integrated ? "Not Integrated" : "Integrated"}
      </span>
    ),
    // enable: (
    //   <ToggleSwitch
    //     isEnabled={device?.enable ?? false}
    //     handleChange={() => {
    //       handleEnableToggle(device.id);
    //     }}
    //   />
    // ),
    enable: (
      <span className={`inline-block text-nowrap rounded-full ${device?.enable ? 'bg-green-500' : 'bg-red-500'} px-2 py-1 text-sm font-bold text-white`}>
        {device?.enable ? "Enabled" : "Disabled"}
      </span>
    ),
    actions: (
      <div className="flex items-center gap-2">
        <GridActionButtonEdit onClick={() => onClickEdit(device?.id)} />
        <GridActionButtonDelete onClick={() => onClickDelete(device)} />
      </div>
    ),
  }));

  return (
    <div>
      <Card extra={"h-full  w-full bg-white"}>
        <CardHeader
          label={"Devices"}
          buttons={[
            {
              text: "Add New",
              onClick: onClickAddNew,
              variant: "primary",
            },
          ]}
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default DeviceTableCard;
