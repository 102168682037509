import React from "react";

const FormSectionHeader = ({ text }) => {
  return (
    <div
      className="flex flex-wrap border-b border-gray-300 py-1 text-center
      text-xl font-medium text-gray-800 dark:border-gray-600 dark:text-white "
    >
      <h3>{text}</h3>
    </div>
  );
};

export default FormSectionHeader;
