import { getMerchant, updateLanguageMerchant, updateMerchant } from "actions/merchants/merchants.actions";
import { getRegions, resetRegionState } from "actions/regions/regions.actions";
import { FORM_TYPE } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MerchantForm from "./merchantForm";
import Loader from "components/loaderSpinner/Loader";
import ConfirmationModal from "../components/ConfirmationModal";
import { deleteMerchant } from "actions/merchants/merchants.actions";

const UpdateMerchant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { merchantId } = useParams();
  console.log(merchantId);
  const { merchant } = useSelector((state) => state.merchant);

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = () => {
    setShowDelModal(true);
  };

  const handleCancelDelete = () => {
    setShowDelModal(false);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteMerchant(merchantId, navigate));
    handleCancelDelete();
  };

  useEffect(() => {
    dispatch(getMerchant(merchantId));
  }, [merchantId]);

  useEffect(() => {
    if (!merchant) return;
    const { country_id, state_id, city_id } = merchant;
    dispatch(resetRegionState());
    dispatch(
      getRegions(
        country_id?.code,
        state_id?.code,
        country_id?.id,
        state_id?.id,
        city_id?.id
      )
    );
  }, [merchant]);

  const handleSubmit = (formData, redirect) => {
    if (formData.get("lang_id")) {
      dispatch(
        updateLanguageMerchant(merchantId, formData, redirect ? navigate : null)
      );
      return;
    }
    dispatch(updateMerchant(merchantId, formData, redirect ? navigate : null));
  };

  if (!merchant || merchant.id !== merchantId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the merchant with email{" "}
              {merchant?.contact_info?.email}
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <MerchantForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={merchant}
        handleFormSubmit={handleSubmit}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateMerchant;
