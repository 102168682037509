import { deleteVibe, getVibeLikes, getVibeSeen } from "actions/merchants/merchants.actions";
import merchantPointsIcon from "assets/img/airplay.png";
import Card from "components/card";
import Loader from "components/loaderSpinner/Loader";
import { PERMISSION_ACTION_TYPES, PERSMISSIONS_TYPES } from "constants/role.constants";
import { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { formatLocalTimeDate } from "utils/App.utils";
import { hasPermission } from "utils/permissions";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import ImageModal from "../components/imageModal";
import Table from "../components/table";

const VibesTableCard = ({ vibes, children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { likedBy, seenBy, loading } = useSelector((state) => state.merchant);
  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showDelModal, setShowDelModal] = useState(false);
  const [showSeenByModal, setShowSeenByModal] = useState(false);
  const [showLikedByModal, setShowLikedByModal] = useState(false);
  const [vibeId, setVibeId] = useState(null);

  const onClickSeenBy = (vibeId) => {
    setVibeId(vibeId);
    dispatch(getVibeSeen(vibeId));
    setShowSeenByModal(true);
  };

  const onClickLikedBy = (vibeId) => {
    setVibeId(vibeId);
    dispatch(getVibeLikes(vibeId));
    setShowLikedByModal(true);
  };

  const onClickDelete = (vibeId) => {
    setVibeId(vibeId);
    setShowDelModal(true);
  };

  const handleCancelDelete = () => {
    setShowSeenByModal(false);
    setShowLikedByModal(false);
    setShowDelModal(false);
    setVibeId(null);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteVibe(vibeId));
    handleCancelDelete();
  };

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      <div className="flex items-center gap-1">
        <img className="h-4 w-4 " src={merchantPointsIcon} alt="" />
        <p>Merchant</p>
      </div>
    </div>,
    "Thumbnail",
    "File Type",
    "Seen By",
    "Liked By",
    "Created At",
    "Expiry",
    "Actions",
  ];

  const records = vibes.map((vibe) => ({
    merchant: (
      <div className="flex items-center gap-2">
        <div className="flex h-10 w-10 items-center justify-center">
          <img
            className="h-9 w-9 rounded-full"
            src={vibe.merchant_id?.logo ?? ProfileIcon}
            alt=""
          />
        </div>
        {vibe.merchant_id?.name ?? ""}
      </div>
    ),
    thumbnail: (
      <div
        className="flex h-10 w-10 cursor-pointer items-center justify-center"
        onClick={() => {
          setVideoUrl(vibe.image);
          setImageUrl(vibe.thumbnail);
        }}
      >
        {vibe.thumbnail ? (
          <img
            className="h-10 w-10 cursor-pointer rounded-full"
            src={vibe.thumbnail}
            alt=""
          />
        ) : null}
      </div>
    ),
    fileType: <div>{vibe.isVideo ? "video" : "Image"}</div>,
    seen_by: (
      <div className="cursor-pointer" onClick={() => onClickSeenBy(vibe.id)}>
        {" "}
        {vibe.seen_by.length}&nbsp;persons
      </div>
    ),
    liked_by: (
      <div className="cursor-pointer" onClick={() => onClickLikedBy(vibe.id)}>
        {" "}
        {vibe.liked_by.length}&nbsp;persons
      </div>
    ),
    created_at: vibe.created_at ? formatLocalTimeDate(vibe.created_at) : "",
    expiry: vibe?.expiry ? formatLocalTimeDate(vibe.expiry) : "",
    actions: (
      <div className="flex items-center  gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => navigate(`/vibes/update/${vibe.id}`)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
            onClick={() => onClickDelete(vibe.id)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));

  return (
    <div>
      {showSeenByModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleCancelDelete}
          confirmBtnText="OK"
          confirmBtnType="success"
        >
          <div className="flex h-56 flex-col gap-2 overflow-y-scroll">
            {loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="flex w-full  items-center justify-center  text-xl font-semibold ">
                  Seen By
                </div>
                {seenBy.map((user, index) => (
                  <Link
                    key={index}
                    to={`/customer/profile/${user?.id}`}
                    className={`flex items-center gap-2 p-2 ${
                      index !== seenBy.length - 1 ? "border-b-2" : ""
                    } border-gray-100`}
                  >
                    <div className="flex h-10 w-10 items-center justify-center">
                      <img
                        className="h-9 w-9 cursor-pointer rounded-full"
                        src={user?.profile_pic || ProfileIcon}
                        alt=""
                      />
                    </div>{" "}
                    {user?.name}
                  </Link>
                ))}
              </>
            )}
          </div>
        </ConfirmationModal>
      ) : null}
      {showLikedByModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleCancelDelete}
          confirmBtnText="OK"
          confirmBtnType="success"
        >
          <div className="flex h-56 flex-col gap-2 overflow-y-scroll">
            {loading ? (
              <div className="flex h-full w-full items-center justify-center">
                <Loader />
              </div>
            ) : (
              <>
                <div className="flex w-full  items-center justify-center  text-xl font-semibold ">
                  Liked By
                </div>
                {likedBy.map((user, index) => (
                  <Link
                    key={index}
                    to={`/customer/profile/${user?.id}`}
                    className={`flex items-center gap-2 p-2 ${
                      index !== seenBy.length - 1 ? "border-b-2" : ""
                    } border-gray-100`}
                  >
                    <div className="flex h-10 w-10 items-center justify-center">
                      <img
                        className="h-9 w-9 cursor-pointer rounded-full"
                        src={user?.profile_pic || ProfileIcon}
                        alt=""
                      />
                    </div>{" "}
                    {user?.name}
                  </Link>
                ))}
              </>
            )}
          </div>
        </ConfirmationModal>
      ) : null}
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the vibe with text{" "}
              {vibes.find((vibe) => vibe.id === vibeId)?.swipe_text ?? ""}
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={" bg-white"}>
        {imageUrl ? (
          <ImageModal onBack={() => setImageUrl(null)}>
            {vibes.find((vibe) => vibe.thumbnail === imageUrl).isVideo ? (
              <video
                // className="size-96"
                controls
                // onError={(e) => console.error("Video error:", e)}
                // onLoad={() => console.log("Video loaded successfully!")}
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={imageUrl} alt="Image" />
            )}
          </ImageModal>
        ) : null}
        <CardHeader
          label={"Vibes"}
          buttons={[
            {
              text: "Add New",
              onClick: () => navigate("/vibes/create"),
              variant: "primary",
            },
          ]}
        />
        {children}

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
};

export default VibesTableCard;
