import * as ActionTypes from "../../actions/actionTypes";

export const AlertTypes = {
  SUCCESS: "SUCCESS",
  WARNING: "WARNING",
  ERROR: "ERROR",
};

// exampleAlert = {
//   id: '123',
//   alertType: AlertTypes.SUCCESS,
//   message: 'success',
// }

const INITIAL_STATE = [];

export const alertReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_ALERTS:
      return payload;
    case ActionTypes.CLEAR_ALERTS:
      return [];
    case ActionTypes.SET_ALERT:
      return [...state, payload];
    case ActionTypes.REMOVE_ALERT:
      return state.filter((alert) => alert.id !== payload);
    default:
      return state;
  }
};
