import { TIME_ZONES } from "constants/app.constants";
import { IoMoonOutline } from "react-icons/io5";
import HeadlessSelectObject from "views/admin/components/headless/selectObject.headless";
import ToggleSwitch from "views/admin/components/toggleSwitch";

const OperationHours = ({ timeZone, operational_hours, onChangeFormData }) => {
  const onChangeOperationalHours = (day, name, value) => {
    const operationalHours = JSON.parse(JSON.stringify(operational_hours));
    operationalHours[day] = { ...operationalHours[day], [name]: value };
    onChangeFormData({ name: "operational_hours", value: operationalHours });
  };

  return (
    <div className="flex flex-col justify-between gap-6">
      <div className="w-full flex justify-between gap-2">
        <label className="flex w-full max-w-60 mb-1 text-base font-medium text-gray-800">
          Time Zone
        </label>
        <HeadlessSelectObject
          title={"Select Time Zone"}
          options={TIME_ZONES.map(({ zone, gmt }) => ({ name: `${zone} ${gmt}`, value: zone }))}
          selected={timeZone}
          onSelect={(value) =>
            onChangeFormData({ name: "timeZone", value })
          }
        />
      </div>
      <div className="flex flex-col gap-4">
        <>
          {Object.entries(operational_hours)?.map(([day, operationHours]) => (
            <div key={day} className="w-full flex flex-col sm:flex-row gap-2 justify-between ">
              <div className="flex w-full max-w-60 justify-start items-center gap-6">
                <ToggleSwitch
                  isEnabled={!operationHours.isOff}
                  handleChange={() => {
                    onChangeOperationalHours(day, "isOff", !operationHours.isOff)
                  }}
                />
                <p className="mb-1 block text-base font-medium text-gray-800">
                  {day}
                </p>
              </div>
              {!operationHours.isOff ? (
                <div className="flex w-full justify-start items-center gap-5">
                  <div className="w-full flex justify-between items-center gap-5 border-2 border-gray-300 rounded-md p-3">
                    <p className="text-base text-gray-500">From</p>
                    <input
                      required
                      type="time"
                      name="opening_time"
                      value={operationHours.opening_time}
                      onChange={(e) => onChangeOperationalHours(day, "opening_time", `${e.target.value}`)}
                      className="w-full focus:outline-none"
                    />
                  </div>
                  <div className="w-full flex justify-between items-center gap-5 border-2 border-gray-300 rounded-md p-3">
                    <p className="text-base text-gray-500">To</p>
                    <input
                      required
                      type="time"
                      name="closing_time"
                      value={operationHours.closing_time}
                      onChange={(e) => onChangeOperationalHours(day, "closing_time", `${e.target.value}`)}
                      className="w-full focus:outline-none"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex w-full justify-start items-center bg-gray-50 px-3 py-3 rounded-md gap-4 text-gray-500">
                  <IoMoonOutline className="w-6 h-6" />
                  <p>Closed</p>
                </div>
              )}
            </div>
          ))}
        </>
      </div>
    </div>
  );
};

export default OperationHours;
