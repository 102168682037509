import { deleteStoreLoyalty } from "actions/storeLoyalties/storeLoyalties.action";
import Card from "components/card";
import { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { hasPermission } from "utils/permissions";
import {
  PERSMISSIONS_TYPES,
  PERMISSION_ACTION_TYPES,
} from "constants/role.constants";

function StoreLoyaltyTableCard({ storeLoyalties }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [loyalty, setLoyalty] = useState(null);

  const onClickDelete = (loyalty) => {
    setLoyalty(loyalty);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteStoreLoyalty(loyalty.id));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
    setLoyalty(null);
  };

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className=" flex h-10 w-14 items-center justify-center p-1" />
      {"Merchant Name".split(" ").join("\u00A0")}
    </div>,
    "Name",
    "Card Title",
    "Card Sub-Title",
    "Created By",
    "Actions",
  ];
  const records = storeLoyalties.map((loyalty) => ({
    image_merchant_name: (
      <div className="flex items-center gap-2">
        <div className="flex h-10 w-14 items-center justify-center p-1">
          <img
            className="h-full w-full  rounded-md"
            src={loyalty.background_image ?? ""}
            alt=""
          />
        </div>
        {loyalty.merchant_id.name.split(" ").join("\u00A0")}
      </div>
    ),
    name: loyalty.name,
    title: loyalty.title,
    sub_title: loyalty.sub_title,
    created_by: loyalty?.created_by?.name ?? "",
    actions: (
      <div className="flex items-center gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.STORE_LOYALTY_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => navigate(`/storeLoyalty/update/${loyalty.id}`)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.STORE_LOYALTY_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
            onClick={() => onClickDelete(loyalty)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));

  return (
    <div>
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the "{loyalty.name}" store loyalty</h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={" w-full bg-white"}>
        {hasPermission(
          [PERSMISSIONS_TYPES.STORE_LOYALTY_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <CardHeader
            label={"Store Loyalties"}
            buttons={
              hasPermission(
                [PERSMISSIONS_TYPES.STORE_LOYALTY_MANAGEMENT],
                PERMISSION_ACTION_TYPES.FULL_ACCESS
              )
                ? [{
                    text: "Add New",
                    onClick: () => navigate("/storeLoyalty/create"),
                  variant: "primary",
                  }]
                : null
            }
          />
        ) : null}

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default StoreLoyaltyTableCard;
