import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import CardHeader from "../components/cardHeader";
import SliderForm from "./sliderForm";
import { createSlider } from "actions/sliders/sliders.action";
import { FORM_TYPE } from "constants/app.constants";

const CreateSlider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <SliderForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createSlider(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateSlider;
