import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  brandsCount: 0,
  brands: [],
  brand: null,
  brandMultiLang: {},
  loading: false,
};

export const brandsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_BRANDS:
      return {
        ...state,
        brandsCount: payload?.meta?.total_documents || 0,
        brands: payload.brands,
        loading: false,
      };
    case ActionTypes.SET_BRAND:
      return {
        ...state,
        brand: payload.brand,
        brandMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_BRAND:
      return {
        ...state,
        brandsCount: state.brandsCount - 1,
        brands: state.brands.filter((brand) => brand.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_BRAND_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_BRAND_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
