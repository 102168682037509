import { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";

const UpdatePassword = ({
  handleSubmit,
  handleCancel,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const onConfirm = () => {
    setError("")
    if (!password || !confirmPassword) return setError("password and confirm password is required")
    if (password !== confirmPassword) return setError("confirm password should be same as password")
    handleSubmit({ password, confirmPassword })
    handleCancel();
  };

  return (
    <div className="h-full w-full bg-white p-4">
      <ConfirmationModal
        onBack={handleCancel}
        onConfirm={onConfirm}
        confirmBtnText=""
      >
        <div className="flex flex-col gap-4">
          <h1>Update Password</h1>
        </div>
        <div className="flex flex-col justify-between gap-2">
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Password
            </label>
            <input
              type="password"
              name="password"
              placeholder="Type Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
          <div className="flex flex-auto flex-col items-start">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Confirm Password
            </label>
            <input
              type="password"
              name="confirm-password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
            />
          </div>
          <div className="flex flex-auto flex-col items-start">
            {error ? <p className="text-red-500">{error}</p> : null}
          </div>

        </div>
      </ConfirmationModal>
    </div>
  );
};

export default UpdatePassword;
