import { ACCOUNT_TYPE } from "constants/app.constants";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import VibeCategoryService from "../../services/vibeCategories/VibeCategoryService";
import * as ActionTypes from "../actionTypes";
import { setAlert } from "../alert/alert.actions";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_VIBE_CATEGORY_LOADING,
    payload: loading,
  });
};

export const setVibeCategories = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_VIBE_CATEGORIES,
    payload: data,
  });
};

export const setVibeCategory = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_VIBE_CATEGORY,
    payload: data,
  });
};

export const updateVibeCategoryStatus = (date) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_VIBE_CATEGORY_PUBLISH_STATUS,
    payload: date,
  });
};

export const removeVibeCategory = (category_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_VIBE_CATEGORY,
    payload: category_id,
  });
};

export const resetVibeCategoryState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_VIBE_CATEGORY_STATE,
  });
};

export const getMerchantVibeCategories = (merchant_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await VibeCategoryService.getMerchantVibeCategories(
      merchant_id
    );

    if (response.data && response.status == 1) {
      dispatch(setVibeCategories(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load vibe categories",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
