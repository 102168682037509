import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getSliders = (page = 1, limit = 50) => {
  return RestClient.Get(`${API_URL.GET_SLIDERS}?page=${page}&limit=${limit}`);
};
const getSlider = (sliderId) => {
  return RestClient.Get(`${API_URL.GET_SLIDER}/${sliderId}`);
};

const createSlider = (payload) => {
  return RestClient.Post(API_URL.CREATE_SLIDER, payload);
};

const updateSlider = (sliderId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_SLIDER}/${sliderId}`, payload);
};

const updateMultiLangSlider = (sliderId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MULTI_LANG_SLIDER}/${sliderId}`,
    payload
  );
};

const deleteSlider = (sliderId) => {
  return RestClient.Delete(`${API_URL.DELETE_SLIDER}/${sliderId}`);
};

const exportedObject = {
  getSliders,
  getSlider,
  createSlider,
  updateSlider,
  updateMultiLangSlider,
  deleteSlider,
};

export default exportedObject;
