import React from "react";

const TabSelector = ({ selectors, activeSelector, setActiveSelector }) => {
  return (
    <ul className="flex flex-wrap border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400">
      {selectors.map((selector) => (
        <li className="me-2" key={selector.value}>
          <button
            aria-current={
              activeSelector === selector.value ? "page" : undefined
            }
            className={`inline-block  rounded-t-lg p-4 ${
              activeSelector === selector.value
                ? "bg-gray-100 text-indigo-500 dark:bg-gray-800 dark:text-blue-500"
                : "hover:bg-gray-50 hover:text-gray-600 dark:hover:bg-gray-800 dark:hover:text-gray-300"
            }`}
            onClick={() => setActiveSelector(selector.value)}
          >
            <div className="flex items-center justify-center gap-1">
              {selector?.icon ? (
                <img className="h-4 w-4" src={selector?.icon} />
              ) : null}
              {selector.name}
            </div>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default TabSelector;
