import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  customerClassificationCount: 0,
  customerClassifications: [],
  customerClassification: null,
  classificationMultiLang: {},
  loading: false,
};

export const customerClassificationReducer = (
  state = INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_CUSTOMER_CLASSIFICATIONS:
      return {
        ...state,
        customerClassificationCount: payload?.meta?.total_documents || 0,
        customerClassifications: payload.classifications,
        loading: false,
      };
    case ActionTypes.SET_CUSTOMER_CLASSIFICATION:
      return {
        ...state,
        customerClassification: payload.customerClassification,
        classificationMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_CUSTOMER_CLASSIFICATION:
      return {
        ...state,
        customerClassificationCount: state.customerClassificationCount - 1,
        customerClassifications: state.customerClassifications.filter(
          (customerClassification) => customerClassification.id !== payload
        ),
        loading: false,
      };
    case ActionTypes.SET_CUSTOMER_CLASSIFICATION_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_CUSTOMER_CLASSIFICATION_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
