
const DeliveryOption = ({ merchantData, onChangeFormData }) => {

  const onChangeDeliveryOption = (name, value) => {
    let delivery_options = merchantData.delivery_options;
    // if (name == "availabe" && !value)
    //   delivery_options =
    //     initialFormData?.delivery_options ||
    //     initialMerchantData.delivery_options;
    // else delivery_options[name] = value;
    delivery_options[name] = value;
    onChangeFormData({ name: "delivery_options", value: delivery_options });
  };

  return (
    <div className="flex flex-col justify-between gap-3">
      {/* <div className="flex w-full items-center gap-20">
        <label className="mb-1 block text-base font-medium text-gray-800">
          Delivery Option Available?
        </label>
      </div> */}

      {/* Radius, Fee and Time */}
      {merchantData.delivery_options.available ? (
        <div className="flex flex-col justify-between gap-2 sm:flex-row">
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Radius
            </label>
            <input
              onChange={(e) =>
                onChangeDeliveryOption(
                  e.target.name,
                  Number(e.target.value)
                )
              }
              required
              type="number"
              name="deliveryRadius"
              value={merchantData.delivery_options.deliveryRadius}
              className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
            />
          </div>
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Fee
            </label>
            <input
              onChange={(e) =>
                onChangeDeliveryOption(
                  e.target.name,
                  Number(e.target.value)
                )
              }
              required
              type="number"
              name="deliveryFee"
              value={merchantData.delivery_options.deliveryFee}
              className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
            />
          </div>
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Time
            </label>
            <input
              onChange={(e) =>
                onChangeDeliveryOption(
                  e.target.name,
                  Number(e.target.value)
                )
              }
              required
              type="number"
              name="deliveryTime"
              value={merchantData.delivery_options.deliveryTime}
              className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DeliveryOption;
