import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  usersCount: 0,
  users: [],
  user: null,
  loading: false,
};

export const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_USERS:
      return {
        ...state,
        usersCount: payload?.meta?.total_documents || 0,
        users: payload.users,
        loading: false,
      };
    case ActionTypes.SET_USER:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case ActionTypes.DELETE_USER:
      return {
        ...state,
        usersCount: state.usersCount - 1,
        users: state.users.filter((user) => user.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_USER_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_USER_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
