import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  faqsCount: 0,
  faqs: [],
  faq: null,
  faqMultiLang: {},
  loading: false,
};

export const faqsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_FAQS:
      return {
        ...state,
        faqsCount: payload?.meta?.total_documents || 0,
        faqs: payload.faqs,
        loading: false,
      };
    case ActionTypes.SET_FAQ:
      return {
        ...state,
        faq: payload.faq,
        faqMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_FAQ:
      return {
        ...state,
        faqsCount: state.merchantsCount - 1,
        faqs: state.faqs.filter((faq) => faq.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_FAQ_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_FAQ_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
