import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  localizationsCount: 0,
  localizations: [],
  loading: false,
};

export const localizationsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_LOCALIZATIONS:
      return {
        ...state,
        localizationsCount: payload?.meta?.total_documents || 0,
        localizations: payload.localizations,
        loading: false,
      };
    case ActionTypes.ADD_LOCALIZATION:
      return {
        ...state,
        localizations: [...state.localizations, payload],
        loading: false,
      };
    case ActionTypes.UPDATE_LOCALIZATION:
      return {
        ...state,
        localizations: state.localizations.map((local) =>
          local.id == payload.id ? payload : local
        ),
        loading: false,
      };
    case ActionTypes.DELETE_LOCALIZTION:
      return {
        ...state,
        localizations: state.localizations.filter(
          (local) => local.id !== payload
        ),
        loading: false,
      };
    case ActionTypes.SET_LOCALIZATION_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_LOCALIZATION_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
