import React, { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { deleteFaq } from "actions/faqs/faqs.action";
import ConfirmationModal from "../components/ConfirmationModal";
import Card from "components/card";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";

const FaqsTableCard = ({ faqs }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [faqData, setFaqData] = useState({});

  const onClickDelete = (faq) => {
    setFaqData(faq);
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteFaq(faqData.id));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
    setFaqData(null);
  };

  const tableHeaders = [
    "Title",
    "Description",
    "status",
    "Created By",
    "Actions",
  ];
  const records = faqs.map((faq) => ({
    name: faq.title,
    description: faq.description,
    status: faq.status,
    created_by: faq?.created_by?.name ?? "",
    actions: (
      <div className="flex items-center  gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.FAQS],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => navigate(`/faqs/update/${faq.id}`)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.FAQS],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
            onClick={() => onClickDelete(faq)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));

  return (
    <div>
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the FAQ with title "{faqData.title}"
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={" w-full bg-white"}>
        <CardHeader
          label={"FAQs"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.FAQS],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [{
                  text: "Add New",
                  onClick: () => navigate("/faqs/create"),
                variant: "primary",
                }]
              : null
          }
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
};

export default FaqsTableCard;
