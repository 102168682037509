import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import StoreForm from "./StoreForm";
import { createStore } from "actions/store/store.actions";

const CreateStore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <StoreForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createStore(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateStore;
