import { getMerchantPromotionTags } from "actions/merchantPromotionTags/merchantPromotionTags.action";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MerchantPromotionTagTableCard from "./merchantPromotionTag-table-card";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const MerchantPromotionTags = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { promotionTagsCount, promotionTags } = useSelector(
    (state) => state.merchantPromotionTag
  );

  useEffect(() => {
    dispatch(getMerchantPromotionTags(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <MerchantPromotionTagTableCard promotionTags={promotionTags} />

      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={promotionTagsCount}
      />
    </div>
  );
};

export default MerchantPromotionTags;
