const convertToFormData = (obj, formData = new FormData(), namespace = '') => {
    // Helper function to handle appending to FormData
    const appendFormData = (key, value) => {
      if (value == undefined || value == null) return
      if (value instanceof File) {
        formData.append(key, value);
      } else if (typeof value === 'object' && value !== null) {
        convertToFormData(value, formData, key);
      } else {
        formData.append(key, value);
      }
    };

    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const formKey = namespace ? `${namespace}[${key}]` : key;

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          appendFormData(`${formKey}[${index}]`, item);
        });
      } else {
        appendFormData(formKey, value);
      }
    });

    return formData;
  }

  export default convertToFormData