import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/loaderSpinner/Loader";
import { FORM_TYPE } from "constants/app.constants";
import CartRuleForm from "./cartRuleForm";
import { getRule } from "actions/cartRules/cartRules.action";
import { updateRule } from "actions/cartRules/cartRules.action";

const UpdateCartRule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ruleId } = useParams();
  const { rule, loading } = useSelector((state) => state.rule);

  useEffect(() => {
    dispatch(getRule(ruleId));
  }, [ruleId]);

  const handleSubmit = (formData) => {
    dispatch(updateRule(ruleId, formData, navigate));
  };

  if (!ruleId || rule?.id !== ruleId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      <CartRuleForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={rule}
        handleFormSubmit={handleSubmit}
      />
    </div>
  );
};

export default UpdateCartRule;
