import { resetPassword } from "actions/auth/auth.actions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import authImg from "../assets/img/auth/auth.png";

export default function ResetPassword() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const otp = searchParams.get("otp");

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(resetPassword({ email, otp, newPassword, confirmPassword }, navigate));
  };

  return (
    <div className="flex min-h-full justify-between">
      <div className="flex min-h-full w-full flex-col justify-center px-6 py-12 md:w-1/2 lg:px-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset Password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="new-password"
                className="block text-sm leading-6 text-gray-900"
              >
                New Password
              </label>
              <div className="mt-2">
                <input
                  required
                  id="newPassword"
                  name="newPassword"
                  type="text"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="confirm-password"
                className="block text-sm leading-6 text-gray-900"
              >
                Confirm Password
              </label>
              <div className="mt-2">
                <input
                  required
                  id="confirmPassword"
                  name="confirmPassword"
                  type="text"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm outline-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="absolute right-0 hidden h-full min-h-screen w-1/2 md:flex">
        <div
          className="absolute flex h-full w-full items-end justify-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
          style={{ backgroundImage: `url(${authImg})` }}
        />
      </div>
    </div>
  );
}
