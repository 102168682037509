import { createLanguage } from "actions/languages/languages.actions";
import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LanguageForm from "./languageForm";

const CreateNewLanguage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="h-full w-full">
      <LanguageForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createLanguage(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateNewLanguage;
