import React from "react";
import Card from "components/card";

const PersonalInfoCard = ({ customer }) => {
  const { name, gender, email, phone_number } = customer || {};

  const infoItems = [
    { label: "Name", value: name },
    { label: "Gender", value: gender },
    { label: "Email", value: email },
    { label: "Phone", value: phone_number },
  ];

  return (
    <Card extra="p-6 w-full shadow-lg gap-4">
      <h4 className="text-lg font-bold text-navy-700 dark:text-white">
        Customer Profile
      </h4>
      {infoItems.map((item, index) => (
        <div key={index} className="flex flex-col gap-2">
          <span className="text-base font-normal leading-4 text-gray-500">
            {item.label}
          </span>
          <span className="text-base font-normal leading-4">
            {item.value ?? "__"}
          </span>
        </div>
      ))}
    </Card>
  );
};

export default PersonalInfoCard;
