import Loader from "components/loaderSpinner/Loader";
import { FORM_TYPE } from "constants/app.constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DeviceForm from "./deviceForm";
import { getDevice, updateDevice } from "actions/devices/devices.action";

const UpdateDevice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { deviceId } = useParams();
  const { device } = useSelector((state) => state.device);

  useEffect(() => {
    dispatch(getDevice(deviceId));
  }, [deviceId]);

  const handleSubmit = (payload, redirect) => {
    dispatch(
      updateDevice(deviceId, payload, redirect === false ? null : navigate)
    );
  };

  if (!device || device.id !== deviceId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      <DeviceForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={device}
        handleFormSubmit={handleSubmit}
      />
    </div>
  );
};

export default UpdateDevice;
