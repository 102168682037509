import React from "react";
import { MdOutlineEdit } from "react-icons/md";

const GridActionButtonEdit = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      // className="flex size-10 cursor-pointer items-center justify-center rounded-full bg-blue-200 hover:bg-blue-300"
      className="flex cursor-pointer items-center justify-center rounded-full "
    >
      <MdOutlineEdit
        // className=" size-4 text-blue-600"
        className=" size-4"
      />{" "}
    </div>
  );
};

export default GridActionButtonEdit;
