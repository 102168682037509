import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getUsers = (page = 1, limit = 10) => {
  return RestClient.Get(`${API_URL.GET_USERS}?page=${page}&limit=${limit}`);
};

const getUser = (userId) => {
  return RestClient.Get(`${API_URL.GET_USER}/${userId}`);
};

const createUser = (payload) => {
  return RestClient.Post(API_URL.CREATE_USER, payload);
};

const updateUser = (userId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_USER}/${userId}`, payload);
};

const updateUserPassword = (userId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_USER_PASSWORD}/${userId}`, payload);
};

const deleteUser = (userId) => {
  return RestClient.Delete(`${API_URL.DELETE_USER}/${userId}`);
};

const exportedObject = {
  getUsers,
  getUser,
  createUser,
  updateUser,
  updateUserPassword,
  deleteUser,
};

export default exportedObject;
