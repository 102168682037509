import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

// Merchant Brand Vibe Categories
const getMerchantVibeCategories = (merchant_id) => {
  return RestClient.Get(
    `${API_URL.GET_MERCHANT_VIBE_CATEGORIES}?merchant_id=${merchant_id}`
  );
};

const exportedObject = {
  getMerchantVibeCategories,
};

export default exportedObject;
