import { useState } from "react";
import { useSelector } from "react-redux";
import Pagination from "views/admin/components/pagination";
import CountriesTableCard from "./countries-table-card";
import CountriesFilters from "./filters";
import { getSystemSettings } from "selectors/settings/settings.selector";

const Countries = () => {
  const { countries, countriesCount } = useSelector((state) => state.region);
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;

  return (
    <div className="h-full w-full">
      <CountriesTableCard
        countries={countries}
        filters={
          <CountriesFilters limit={limit} page={page} setPage={setPage} />
        }
      />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={countriesCount}
      />
    </div>
  );
};

export default Countries;
