import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getRoles = (page = 1, limit = 10) => {
  return RestClient.Get(`${API_URL.GET_ROLES}?page=${page}&limit=${limit}`);
};

const getRolePermissions = () => {
  return RestClient.Get(`${API_URL.GET_ROLE_PERMISSIONS}`);
};

const updateRole = (roleId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_ROLE}/${roleId}`, payload);
};

const exportedObject = {
  getRoles,
  getRolePermissions,
  updateRole,
};

export default exportedObject;
