import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  claimsCount: 0,
  claims: [],
  loading: false,
};

export const membershipClaimsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_CLAIMS:
      return {
        ...state,
        claimsCount: payload?.meta?.total_documents || 0,
        claims: payload.membershipClaim,
        loading: false,
      };

    case ActionTypes.RESET_CLAIMS_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
