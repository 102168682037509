import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import SettingsService from "../../services/settings/SettingsService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SETTING_LOADING,
    payload: loading,
  });
};

export const setSettings = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SETTINGS,
    payload: data,
  });
};

export const setSystemSettings = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SYSTEM_SETTINGS,
    payload: data,
  });
};

export const updateSettings = (setting) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_SETTINGS,
    payload: setting,
  });
};

export const setSetting = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SETTING,
    payload: data,
  });
};

export const editSetting = (setting) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_SETTING,
    payload: setting,
  });
};

export const removeSetting = (setting_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_SETTING,
    payload: setting_id,
  });
};

export const resetSettingState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_SETTING_STATE,
  });
};

export const getSystemSettings = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SettingsService.getSystemSettings();
    if (response.status == 1) {
      dispatch(setSystemSettings(response.data));

      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load all system settings",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getSettings = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SettingsService.getSettings(page, limit);
    if (response.status == 1) {
      dispatch(setSettings(response.data));

      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load settings", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getSetting = (setting_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SettingsService.getSetting(setting_id);
    if (response.data && response.status == 1) {
      dispatch(setSetting(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Slider", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createSetting = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SettingsService.createSetting(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "setting created successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(getSettings(1, 50));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to create setting", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateSetting = (setting_id, payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SettingsService.updateSetting(setting_id, payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "setting updated successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(updateSettings(response.data.updatedSetting));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to update setting", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteSetting = (setting_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await SettingsService.deleteSetting(setting_id);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "setting deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeSetting(setting_id));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to delete setting", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
