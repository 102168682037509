import * as ActionTypes from "../../actions/actionTypes";
import { v4 as uuidv4 } from "uuid";

const prepareEventPayload = (data = null) => {
  return {
    id: uuidv4(),
    data,
  };
};

const INITIAL_STATE = {
  active_users_event: prepareEventPayload(),
  new_order_event: prepareEventPayload(),
  new_transaction_event: prepareEventPayload(),
  new_customer_event: prepareEventPayload(),
  new_merchant_event: prepareEventPayload(),
  new_membership_event: prepareEventPayload(),
};

export const eventsReducer = (state = INITIAL_STATE, action) => {
  let { type, payload } = action;
  payload = prepareEventPayload(payload);

  switch (type) {
    case ActionTypes.SET_NEW_ORDER_EVENT:
      return {
        ...state,
        new_order_event: payload,
      };
    case ActionTypes.SET_NEW_TRANSACTION_EVENT:
      return {
        ...state,
        new_transaction_event: payload,
      };
    case ActionTypes.SET_NEW_CUSTOMER_EVENT:
      return {
        ...state,
        new_customer_event: payload,
      };
    case ActionTypes.SET_MERCHANT_EVENT:
      return {
        ...state,
        new_merchant_event: payload,
      };
    case ActionTypes.SET_NEW_MERBERSHIP_EVENT:
      return {
        ...state,
        new_membership_event: payload,
      };
    case ActionTypes.SET_ACTIVE_USERS_EVENT:
      return {
        ...state,
        active_users_event: payload,
      };
    case ActionTypes.RESET_EVENTS_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
