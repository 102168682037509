
import { useState } from "react";
import { ImCancelCircle } from "react-icons/im";
import { MdOutlineArrowDropDown } from "react-icons/md";


const MultiSelect = ({ title, options, selectedValues, setSelectedValues }) => {
  const [open, setOpen] = useState(false)

  const getLabel = (value) => {
    const option = options.find(opt => opt.value == value)
    return option?.label || 'unknown'
  }

  const handleSelect = (value) => {
    let temp_values = []
    if (selectedValues.includes(value)) temp_values = selectedValues.filter(val => val !== value)
    else temp_values = [...selectedValues, value]
    setSelectedValues(temp_values)
  }



  return (
    <div className="w-full h-full">
      <div class="w-full flex flex-col items-center">
        <div class="w-full">
          <div
            onClick={() => setOpen((prev) => !prev)}
            class="p-1 flex gap-2 items-center divide-x-2 divide-gray-600 border border-gray-200 bg-white rounded">
            <div class="flex gap-2 flex-auto items-center overflow-x-auto">
              {selectedValues.map(value => (
                <div key={Math.random()} className="px-2 py-1 text-sm flex justify-between gap-2 items-center w-auto border border-gray-200 rounded-full">
                  <p className="flex flex-auto text-nowrap">{getLabel(value)}</p>
                  <ImCancelCircle onClick={() => handleSelect(value)} className=" w-4 h-4 text-gray-500 hover:text-gray-900" />
                </div>
              ))}
              {!selectedValues.length ? <p className="text-gray-400 p-1">{title ?? 'click on arrow to open dropdown'}</p> : null}
            </div>
            <MdOutlineArrowDropDown
              // onClick={() => setOpen((prev) => !prev)}
              className="w-8 h-8  text-gray-800"
            />
          </div>
        </div>
        <div class="flex w-full flex-col items-center relative z-50">
          {open && options.length ?
            <div class="absolute shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj">
              <div class="flex flex-col w-full">

                {options.map(option => (
                  <span onClick={() => handleSelect(option.value)} key={Math.random()} class="cursor-pointer w-full border-gray-100 border-b hover:bg-indigo-100">
                    <div
                      class={`flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative ${selectedValues.includes(option.value) ? 'border-indigo-500' : ''}`}
                    >
                      <div class="w-full items-center flex">
                        <div class="mx-2 leading-6">{option.label} </div>
                      </div>
                    </div>
                  </span>
                ))}
              </div>
            </div>
            : null
          }
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
