import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import FormFileUploader from "views/admin/components/fileUploader";
import MultiSelect from "views/admin/components/multiSelect";
import { IMAGE_SIZES } from "constants/app.constants";
import { ImCancelCircle } from "react-icons/im";

const MarketingSection = ({ merchantData, onChangeFormData, lang_id }) => {
  
  const { facilities } = useSelector((state) => state.facility);
  const { tags } = useSelector((state) => state.merchant);
  const [facility, setFacility] = useState(""); // Input field state

  // Handle input change
  const handleChange = (e) => {
    setFacility(e.target.value);
  };

  // Add facility to the array in merchantData
  const addFacility = () => {
    if (facility.trim() !== "") {
      const updatedFacilities = [...merchantData.facilities, facility];
      onChangeFormData({ name: "facilities", value: updatedFacilities });
      setFacility("");
    }
  };

  // Remove facility from the array in merchantData
  const removeFacility = (index) => {
    const updatedFacilities = merchantData.facilities.filter(
      (_, i) => i !== index
    );
    onChangeFormData({ name: "facilities", value: updatedFacilities });
  };

  // Handle Enter key press to add facility
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addFacility();
    }
  };

  return (
    <Fragment>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col justify-between gap-2 sm:flex-row sm:gap-4">
          <div className="flex h-full w-full flex-col items-start">
            <label
              for="listing-ima"
              className="mb-1 block text-base font-medium text-gray-800"
            >
              Listing Image
            </label>
            <FormFileUploader
              size={IMAGE_SIZES.MERCHANT_LISTING}
              image={merchantData.listing_image}
              handleUpload={(acceptedFiles) =>
                onChangeFormData({
                  name: "listing_image",
                  value: acceptedFiles[0],
                })
              }
            />
          </div>

          <div className="flex h-full w-full flex-col items-start">
            <label
              for="cover"
              className="mb-1 block text-base font-medium text-gray-800"
            >
              Cover Image
            </label>
            <FormFileUploader
              size={IMAGE_SIZES.MERCHANT_COVER}
              image={merchantData.cover_image}
              handleUpload={(acceptedFiles) =>
                onChangeFormData({
                  name: "cover_image",
                  value: acceptedFiles[0],
                })
              }
            />
          </div>

          {!lang_id ? (
            <div className="flex h-full w-full flex-col items-start">
              <label
                for="logo"
                className="mb-1 block text-base font-medium text-gray-800"
              >
                Logo
              </label>
              <FormFileUploader
                size={IMAGE_SIZES.MERCHANT_LOGO}
                image={merchantData.logo}
                handleUpload={(acceptedFiles) =>
                  onChangeFormData({ name: "logo", value: acceptedFiles[0] })
                }
              />
            </div>
          ) : null}
        </div>
        {/* Tags and Facilities */}
        {!lang_id ? (
          <>
            <div className="w-full gap-2">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Tags
              </label>
              <MultiSelect
                options={tags.map((t) => ({ label: t.name, value: t.id }))}
                selectedValues={merchantData.merchant_tag_ids}
                setSelectedValues={(values) =>
                  onChangeFormData({
                    name: "merchant_tag_ids",
                    value: values,
                  })
                }
              />
            </div>
            <div className="w-full gap-2">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Facilities
              </label>
              <MultiSelect
                options={facilities.map((t) => ({ label: t.name, value: t.id }))}
                selectedValues={merchantData.facilities}
                setSelectedValues={(values) =>
                  onChangeFormData({
                    name: "facilities",
                    value: values,
                  })
                }
              />
            </div>
          </>
        ) : null}

        {/* <div className="w-full gap-2">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Facilities
          </label>
          <div className="flex flex-col gap-2 rounded-lg border p-2">
            <div className="flex flex-wrap gap-2">
              {merchantData?.facilities?.map((fac, index) => (
                <div
                  key={index}
                  className="flex w-auto items-center justify-between gap-2 rounded-full border border-gray-200 px-2 py-1 text-sm"
                >
                  <p className="flex flex-auto text-nowrap">{fac}</p>
                  <ImCancelCircle
                    onClick={() => removeFacility(index)}
                    className=" h-4 w-4 text-gray-500 hover:text-gray-900"
                  />
                </div>
              ))}
            </div>
            <input
              type="text"
              value={facility}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              className=" block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
              placeholder="Type facility and press Enter"
            />
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default MarketingSection;
