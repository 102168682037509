
import { LANGUAGE_STATUS } from "constants/language.constants";
import { useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { getLanguages } from "actions/languages/languages.actions";
import HeadlessSelect from "../components/headless/select.headless";

const initialLanguageFilters = {
  lang_id: "",
  name: "",
  code: "",
  isRTL: "",
  locale: "",
  status: "",
}

const LanguagesFilters = () => {
  const dispatch = useDispatch()
  const { languages, loading } = useSelector(state => state.language)
  const [filters, setFilters] = useState(initialLanguageFilters)


  const getFilteredLanguages = () => {
    const { lang_id, name, code, isRTL, locale, status } = filters
    dispatch(getLanguages(1,1000,lang_id, name, code, isRTL, locale, status))
  }

  const removeAllFilters = () => {
    setFilters(initialLanguageFilters)
    dispatch(getLanguages(1,1000))
  }

  const handleOnChange = (name, value) => {
    setFilters((prevData) => ({ ...prevData, [name]: value }))
  }

  return (
    <div className="w-full h-full">
      <div className="flex justify-end p-2 items-center gap-2">
        <div className="w-auto">
          <button
            disabled={loading}
            onClick={removeAllFilters}
            className={`inline-flex justify-center items-center w-auto max-w-40 rounded-lg border-[1px] border-dashed border-red-400 shadow-sm px-2 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 hover:border-red-600 focus:outline-none focus:ring-2 focus:ring-red-500`}
            type="button"
          >
            <MdFilterAltOff className='w-4 h-4' />
            {"Clear Filters"}
          </button>
        </div>
        <div className="w-auto">
          <button
            disabled={loading}
            onClick={getFilteredLanguages}
            className={`inline-flex justify-center items-center w-auto max-w-40 rounded-lg border-[1px] border-dashed border-green-400 shadow-sm px-2 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 hover:border-green-600 focus:outline-none focus:ring-2 focus:ring-green-500`}
            type="button"
          >
            <CiFilter className='w-4 h-4' />
            {"Apply Filters"}
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-2 p-1 mb-1 bg-white border border-indigo-100 rounded-md">

        {/* Id, Name and Code */}
        <div className="flex flex-col md:flex-row justify-between gap-2">
          <div className='w-full md:w-1/3'>
            <input
              value={filters.name}
              onChange={(e) => handleOnChange('name', e.target.value)}
              type="text" name="name" placeholder="Name i.e English"
              className="w-full h-full px-2 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
          </div>
          <div className='w-full md:w-1/3'>
            <input
              value={filters.code}
              onChange={(e) => handleOnChange('code', e.target.value)}
              type="text" name="code" placeholder="Code i.e en"
              className="w-full h-full px-2 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
          </div>
          <div className="w-full md:w-1/3">
            <input
              value={filters.locale}
              onChange={(e) => handleOnChange('locale', e.target.value)}
              type="text" name="locale" placeholder="Locale i.e en-US"
              className="w-full h-full px-2 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
          </div>
        </div>
        {/* Locale, isRtl (writing style) and Status */}
        <div className="flex flex-col md:flex-row justify-start gap-2">
          <div className='w-full md:w-1/3'>
            <HeadlessSelect
              title={'All writing styles'}
              options={['All', 'RTL', 'LTR']}
              selected={filters.isRTL === '' ? '' : filters.isRTL ? 'RTL' : 'LTR'}
              onSelect={(value) => handleOnChange('isRTL', value === 'All' ? '' : value === 'RTL')}
            />
          </div>
          <div className='w-full md:w-1/3'>
            <HeadlessSelect
              title={'All status'}
              options={['All', LANGUAGE_STATUS.ACTIVE, LANGUAGE_STATUS.INACTIVE]}
              selected={filters.status === '' ? '' : filters.status}
              onSelect={(value) => handleOnChange('status', value === 'All' ? '' : value)}
            />
          </div>
          <div className='w-full md:w-1/3'>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguagesFilters;