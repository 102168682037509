import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

export const getCustomers = (page = 1, limit = 50, name, email, phoneNumber) => {
  name = name ? `&name=${name}` : "";
  email = email ? `&email=${email}` : "";
  phoneNumber = phoneNumber ? `&phone_number=${phoneNumber}` : "";

  return RestClient.Get(
    `${API_URL.GET_CUSTOMERS}?page=${page}&limit=${limit}${name}${email}${phoneNumber}`
  );
};

export const exportCustomers = (name, email, phoneNumber) => {
  name = name ? `&name=${name}` : "";
  email = email ? `&email=${email}` : "";
  phoneNumber = phoneNumber ? `&phone_number=${phoneNumber}` : "";

  return RestClient.Get(
    `${API_URL.EXPORT_CUSTOMERS}?${name}${email}${phoneNumber}`
  );
};

export const updateCustomer = (customer_id, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_CUSTOMER}/${customer_id}`, payload);
};

export const getCustomerprofile = (customer_id) => {
  return RestClient.Get(
    `${API_URL.GET_CUSTOMER_PROFILE}?customer_id=${customer_id}`
  );
};

const exportedObject = {
  getCustomers,
  exportCustomers,
  updateCustomer,
  getCustomerprofile,
};

export default exportedObject;
