export const SLIDER_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

export const SLIDER_TYPE = {
  INTERNAL: "internal",
  EXTERNAL: "external",
  COMMON: "common",
};
