import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import MerchantService from "../../services/merchants/MerchantService";
import * as ActionTypes from "../../actions/actionTypes";

// >>>>>>>>>>>>>>>>>>>>>>>>>>>> MERCHANTS <<<<<<<<<<<<<<<<<<<

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_LOADING,
    payload: loading,
  });
};

export const setMerchants = (date) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANTS,
    payload: date,
  });
};
export const updateMerchantPublishFlag = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_MERCHANT_PUBLISH_FLAG,
    payload: data,
  });
};

export const setMerchant = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT,
    payload: data,
  });
};


export const removeMerchant = (merchantId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_MERCHANT,
    payload: merchantId,
  });
};

export const resetMerchantState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_MERCHANT_STATE,
  });
};

export const getMerchants =
  (page, limit, name, types, tags, country_id, state_id, city_id, brand_id) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantService.getMerchants(
        page,
        limit,
        name,
        types,
        tags,
        country_id,
        state_id,
        city_id,
        brand_id
      );
      if (response.status == 1 && response.data) {
        dispatch(setMerchants(response.data));
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load merchants",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getMerchant = (merchantId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.getMerchant(merchantId);
    if (response.status == 1 && response.data) {
      dispatch(setMerchant(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load merchant", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createMerchant = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.createMerchant(payload);
    if (response.status == 1 && response.data) {
      dispatch(
        setAlert(
          response.message ?? "merchant created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/merchants");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to create merchant",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateMerchant =
  (merchantId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantService.updateMerchant(
        merchantId,
        payload
      );
      if (response.status == 1 && response.data) {
        dispatch(
          setAlert(
            response.message ?? "merchant updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) {
          dispatch(getMerchant(merchantId));
          return;
        }
        return navigate("/merchants");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update merchant",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      console.log("runsssss");
      dispatch(setLoading(false));
    }
  };

export const updateMerchantPassword =
  (merchantId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantService.updateMerchantPassword(
        merchantId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "merchant password updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/merchants");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update merchant password",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateLanguageMerchant =
  (merchantId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantService.updateLanguageMerchant(
        merchantId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "merchant localization updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) {
          dispatch(getMerchant(merchantId));
          return;
        }
        return navigate("/merchants");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update merchant localization",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const publishMerchant = (merchant_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.publishMerchant(merchant_id);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "merchant Published status updated successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(updateMerchantPublishFlag(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to Publish", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
  
export const deleteMerchant = (merchantId, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.deleteMerchant(merchantId);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "merchant deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeMerchant(merchantId));
      if (!navigate) return;
      return navigate("/merchants");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to delete merchant",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

// >>>>>>>>>>>>>>>>>>>> TAGS <<<<<<<<<<<<<<<<<<

export const setMerchantTags = (merchantTags) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_TAGS,
    payload: merchantTags,
  });
};

export const setMerchantTag = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_TAG,
    payload: data,
  });
};

export const addMerchantTag = (merchantTag) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_MERCHANT_TAG,
    payload: merchantTag,
  });
};

export const editMerchantTag = (merchantTag) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_MERCHANT_TAG,
    payload: merchantTag,
  });
};

export const removeMerchantTag = (merchantTagId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_MERCHANT_TAG,
    payload: merchantTagId,
  });
};

export const getMerchantTags = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.getMerchantTags(page, limit);
    if (response.status == 1 && response.data) {
      dispatch(setMerchantTags(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load merchant tags",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMerchantTag = (merchantTagId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.getMerchantTag(merchantTagId);
    if (response.status == 1 && response.data) {
      dispatch(setMerchantTag(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load merchant Tag",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createMerchantTag = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.createMerchantTag(payload);
    if (response.status == 1 && response.data) {
      dispatch(
        setAlert(
          response.message ?? "merchant tag created successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(addMerchantTag(response.data.newMerchantTag));
      if (!navigate) return;
      return navigate("/merchant-tags");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to create merchant tag",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateMerchantTag =
  (merchantTagId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantService.updateMerchantTag(
        merchantTagId,
        payload
      );
      if (response.status == 1 && response.data) {
        dispatch(
          setAlert(
            response.message ?? "merchant tag updated successfully",
            AlertTypes.SUCCESS
          )
        );
        dispatch(editMerchantTag(response.data.updatedMerchantTag));
        if (!navigate) return;
        return navigate("/merchant-tags");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update merchant tag",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateLanguageMerchantTag =
  (merchantTagId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantService.updateLanguageMerchantTag(
        merchantTagId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ??
              "merchant tag localization updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/merchant-tags");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update merchant tag localization",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteMerchantTag =
  (merchantTagId, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantService.deleteMerchantTag(merchantTagId);
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "merchant tag deleted successfully",
            AlertTypes.SUCCESS
          )
        );
        dispatch(removeMerchantTag(merchantTagId));
        if (!navigate) return;
        return navigate("/merchant-tags");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to delete merchant tag",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

// >>>>>>>>>>>>>>>>>>>> TYPES <<<<<<<<<<<<<<<<<<

export const setMerchantTypes = (merchantTypes) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_TYPES,
    payload: merchantTypes,
  });
};

export const setMerchantType = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_TYPE,
    payload: data,
  });
};

export const addMerchantType = (merchantType) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_MERCHANT_TYPE,
    payload: merchantType,
  });
};

export const editMerchantType = (merchantType) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_MERCHANT_TYPE,
    payload: merchantType,
  });
};

export const removeMerchantType = (merchantTypeId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_MERCHANT_TYPE,
    payload: merchantTypeId,
  });
};

export const getMerchantTypes = (page,limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.getMerchantTypes(page,limit);
    if (response.status == 1 && response.data) {
      dispatch(setMerchantTypes(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load merchants types",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMerchantType = (merchantTypeId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.getMerchantType(merchantTypeId);
    if (response.status == 1 && response.data) {
      dispatch(setMerchantType(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load merchant type",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createMerchantType = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.createMerchantType(payload);
    if (response.status == 1 && response.data) {
      dispatch(
        setAlert(
          response.message ?? "merchant type created successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(addMerchantType(response.data.newMerchantType));
      if (!navigate) return;
      return navigate("/merchant-types");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to create merchant type",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateMerchantType =
  (merchantTypeId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantService.updateMerchantType(
        merchantTypeId,
        payload
      );
      if (response.status == 1 && response.data) {
        dispatch(
          setAlert(
            response.message ?? "merchant type updated successfully",
            AlertTypes.SUCCESS
          )
        );
        dispatch(editMerchantType(response.data.updatedMerchantType));
        if (!navigate) return;
        return navigate("/merchant-types");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update merchant type",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateLanguageMerchantType =
  (merchantTypeId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantService.updateLanguageMerchantType(
        merchantTypeId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ??
              "merchant type localization updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/merchant-types");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update merchant type localization",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteMerchantType =
  (merchantTypeId, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await MerchantService.deleteMerchantType(merchantTypeId);
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "merchant type deleted successfully",
            AlertTypes.SUCCESS
          )
        );
        dispatch(removeMerchantType(merchantTypeId));
        if (!navigate) return;
        return navigate("/merchant-types");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to delete merchant type",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

// >>>>>>>>>>>>>>>>>>>> VIBES <<<<<<<<<<<<<<<<<<


export const setVibes = (date) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_VIBES,
    payload: date,
  });
};

export const setVibe = (date) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_VIBE,
    payload: date,
  });
};

export const setVibeLikes = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_VIBE_LIKED_BY,
    payload: data,
  });
};
export const setVibeSeen = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_VIBE_SEEN_BY,
    payload: data,
  });
};

export const removeVibe = (vibe_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_VIBE,
    payload: vibe_id,
  });
};

export const getVibes = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.getVibes(page, limit);
    if (response.status == 1 && response.data) {
      dispatch(setVibes(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load vibes", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getVibe = (vibe_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.getVibe(vibe_id);
    if (response.status == 1 && response.data) {
      dispatch(setVibe(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load merchant vibe",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
export const getVibeLikes = (vibe_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.getVibeLikes(vibe_id);
    if (response.status == 1 && response.data) {
      dispatch(setVibeLikes(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load merchant vibe Likes",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
export const getVibeSeen = (vibe_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.getVibeSeen(vibe_id);
    if (response.status == 1 && response.data) {
      dispatch(setVibeSeen(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load merchant vibe Seens",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createVibe = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.createVibe(payload);
    if (response.status == 1 && response.data) {
      dispatch(
        setAlert(
          response.message ?? "vibe created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/vibes");
    }
    dispatch(
      setAlert(response.message ?? "failed to create vibe", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateVibe = (vibe_id, payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.updateVibe(vibe_id, payload);
    if (response.status == 1 && response.data) {
      dispatch(
        setAlert(
          response.message ?? "merchant vibe updated successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(setVibe(response.data));
      if (!navigate) return;
      return navigate("/vibes");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to update merchant vibe",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteVibe = (vibe_id, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await MerchantService.deleteVibe(vibe_id);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "merchant type deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeVibe(vibe_id));
      if (!navigate) return;
      return navigate("/vibes");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to delete merchant type",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};