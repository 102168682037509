import { getMerchants } from "actions/merchants/merchants.actions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import { getLanguages } from "actions/languages/languages.actions";
import { FORM_TYPE } from "constants/app.constants";
import TabSelector from "../components/TabSelector";
import { getBrands } from "actions/Brands/brands.action";
import FormFileUploader from "../components/fileUploader";
import convertToFormData from "utils/ConvertToFormData";
import { IMAGE_SIZES } from "constants/app.constants";
import MediaUploader from "../components/mediaUploader";
import { FILE_TYPE } from "constants/app.constants";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

const initialMultiLangData = {
  description: "",
  title: "",
};

const initialPromotionTagData = {
  title: "",
  image: "",
  description: "",
  brand_id: "",
  merchant_id: "",
};

const MerchantPromotionTagForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
  handleDelete,
}) => {
  const page = 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { promotionTagMultiLang, loading } = useSelector(
    (state) => state.merchantPromotionTag
  );

  const { brands } = useSelector((state) => state.brand);
  const { merchants } = useSelector((state) => state.merchant);
  const { languages } = useSelector((state) => state.language);
  const [showLogsModal, setShowLogsModal] = useState(false);

  const [promotionTagData, setPromotionTagData] = useState(
    initialPromotionTagData
  );
  const [multiLangData, setMultiLangData] = useState(initialMultiLangData);
  const [lang_id, setLang_id] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    const { ...remainingData } = initialFormData;
    setPromotionTagData((prevData) => ({ ...prevData, ...remainingData }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getBrands(1, 1000));
    dispatch(getLanguages(1, 1000));
  }, []);
  useEffect(() => {
    if (promotionTagData.brand_id) {
      dispatch(
        getMerchants(
          page,
          1000,
          null,
          null,
          null,
          null,
          null,
          null,
          promotionTagData?.brand_id
        )
      );
    }
  }, [promotionTagData.brand_id]);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = promotionTagMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initialMultiLangData, ...langData });
  }, [lang_id]);

  const onChangeFormData = ({ name, value }) => {
    if (lang_id) {
      setMultiLangData({ ...multiLangData, [name]: value });
      return;
    }
    setPromotionTagData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (redirect) => {
    let payload = { ...promotionTagData };

    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialPromotionTagData[key] == undefined) {
        delete payload[key];
      }
    });
    if (lang_id) payload = { ...multiLangData, lang_id };
    const formData = convertToFormData(payload);
    handleFormSubmit(formData, redirect);
  };

  return (
    <div className="h-full w-full bg-white">
      {" "}
      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.MERCHANT_PROMOTION_TAG}
          entityId={promotionTagData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      <CardHeader
        label={`${formType} Merchant Promotion Message`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/merchant-promotion-tags"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => handleSubmit(false),
                  variant: "primary",
                },
                {
                  text: "Delete",
                  onClick: () => handleDelete(),
                  variant: "danger",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/merchant-promotion-tags")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to promotion
              tags page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}
      <div className="flex flex-col gap-3 p-4">
        {/* Tabs */}
        {formType == FORM_TYPE.UPDATE ? (
          <div className="">
            <TabSelector
              selectors={[
                { name: "Standard", value: null },
                ...languages.map((lang) => ({
                  icon: lang.flag_image,
                  name: lang.name,
                  value: lang.id,
                })),
              ]}
              activeSelector={lang_id}
              setActiveSelector={(value) => setLang_id(value)}
            />
          </div>
        ) : null}
        {!(formType == FORM_TYPE.UPDATE && lang_id) ? (
          <>
            <div className="flex flex-col justify-between gap-2 md:flex-row">
              <div className="flex flex-auto flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Brand
                </label>
                <HeadlessSelectObject
                  title={!brands.length ? "NO Brands Found" : "Select Brand"}
                  disable={!brands.length || formType === FORM_TYPE.UPDATE}
                  options={brands.map((brand) => ({
                    name: brand.name,
                    value: brand.id,
                  }))}
                  selected={promotionTagData.brand_id}
                  onSelect={(value) =>
                    onChangeFormData({ name: "brand_id", value })
                  }
                />
              </div>

              <div className="flex flex-auto flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Merchant
                </label>
                <HeadlessSelectObject
                  title={
                    !merchants.length ? "NO Merchant Found" : "Select Merchant"
                  }
                  disable={!merchants.length || formType === FORM_TYPE.UPDATE}
                  options={merchants.map((merchant) => ({
                    name: merchant.name,
                    value: merchant.id,
                  }))}
                  selected={promotionTagData.merchant_id}
                  onSelect={(value) =>
                    onChangeFormData({ name: "merchant_id", value })
                  }
                />
              </div>
            </div>
            <div className="flex h-full w-full flex-col items-start gap-1">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Image
              </label>
              {/* <FormFileUploader
                size={IMAGE_SIZES.MERCHANT_PROMORION_TAG}
                image={promotionTagData.image}
                handleUpload={(acceptedFiles) =>
                  onChangeFormData({ name: "image", value: acceptedFiles[0] })
                }
              /> */}
              <MediaUploader
                file_types={[FILE_TYPE.IMAGE]}
                media={promotionTagData.image}
                handleUpload={({ fileType, media, thumbnail }) =>
                  onChangeFormData({ name: "image", value: media })
                }
                aspectRatio={"9:16"}
              />
            </div>
          </>
        ) : null}

        <div className="flex flex-auto flex-col items-start">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Title
          </label>
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={lang_id ? multiLangData.title : promotionTagData.title}
            onChange={(e) =>
              onChangeFormData({ name: "title", value: e.target.value })
            }
            className="block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
          />
        </div>
        <div className="flex flex-auto flex-col items-start gap-1">
          <label
            for="content"
            className="mb-1 block text-base font-medium text-gray-800"
          >
            Description
          </label>
          <textarea
            required
            name="description"
            value={
              lang_id ? multiLangData.description : promotionTagData.description
            }
            onChange={(e) => onChangeFormData(e.target)}
            className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
            rows="2"
          />
        </div>

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
            rounded-md px-6 py-2 font-semibold text-white focus:outline-none
            ${
              loading
                ? "bg-indigo-400 hover:bg-indigo-400"
                : "bg-indigo-500 hover:bg-indigo-600"
            }
            `}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default MerchantPromotionTagForm;
