import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  rolesCount: 0,
  roles: [],
  permissions: [],
  loading: false,
};

export const rolesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_ROLES:
      return {
        ...state,
        rolesCount: payload?.meta?.total_documents || 0,
        roles: payload.roles,
        loading: false,
      };
    case ActionTypes.SET_ROLE_PERMISSIONS:
      return {
        ...state,
        permissions: payload.permissions,
        loading: false,
      };
    case ActionTypes.UPDATE_ROLE:
      return {
        ...state,
        roles: state.roles.map((role) => {
          if (role.id === payload.id) return payload;
          return role;
        }),
        loading: false,
      };
    case ActionTypes.SET_ROLE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_ROLE_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
