
import { getCountries } from "actions/regions/regions.actions";
import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';

const initialCountryFilters = {
  name: "",
}

const CountriesFilters = ({ page, setPage, limit }) => {
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.region)
  const [filters, setFilters] = useState(initialCountryFilters)

  useEffect(() => {
    dispatch(getCountries(page, limit, filters.name))
  }, [page])

  const getFilteredCountries = () => {
    setPage(1)
    dispatch(getCountries(1, limit, filters.name))
  }

  const removeAllFilters = () => {
    setPage(1)
    setFilters(initialCountryFilters)
    dispatch(getCountries(1, limit, initialCountryFilters.name))
  }

  const handleOnChange = (name, value) => {
    setFilters((prevData) => ({ ...prevData, [name]: value }))
  }

  return (
    <div className="w-full h-auto">

      {/* filter buttons */}
      <div className="flex items-center justify-end gap-2 p-2">
        <div className="w-auto">
          <button
            disabled={loading}
            onClick={removeAllFilters}
            className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
            type="button"
          >
            <MdFilterAltOff className="h-4 w-4" />
            {"Clear Filters"}
          </button>
        </div>
        <div className="w-auto">
          <button
            disabled={loading}
            onClick={getFilteredCountries}
            className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
            type="button"
          >
            <CiFilter className="h-4 w-4" />
            {"Apply Filters"}
          </button>
        </div>
      </div>

      <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
        {/* Country, State and City */}
        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="w-full  md:w-1/2">
            <input onChange={(e) => handleOnChange('name', e.target.value)} value={filters.name}
              type="text" name="name" placeholder="Country Name"
              className="flex-auto w-full h-full px-2 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountriesFilters;