import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import LocalizationService from "../../services/localizations/LocalizationService";
import * as ActionTypes from "../actionTypes";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_LOCALIZATION_LOADING,
    payload: loading,
  });
};

export const setLocalizations = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_LOCALIZATIONS,
    payload: data,
  });
};

export const addLocalization = (localization) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_LOCALIZATION,
    payload: localization,
  });
};

export const editLocalization = (localization) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_LOCALIZATION,
    payload: localization,
  });
};

export const removeLocalization = (localization_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_LOCALIZTION,
    payload: localization_id,
  });
};

export const resetLocalizationState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_LOCALIZATION_STATE,
  });
};

export const getLocalizations =
  (page, limit, lang_id, key, value) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await LocalizationService.getLocalizations(
        page,
        limit,
        lang_id,
        key,
        value
      );
      if (response.status == 1 && response.data) {
        dispatch(setLocalizations(response.data));
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load localizations",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createLocalization = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await LocalizationService.createLocalization(payload);
    if (response.status == 1 && response.data) {
      dispatch(
        setAlert(
          response.message ?? "localization created successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(addLocalization(response.data.newLocalization));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to create localization",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateLocalization =
  (localization_id, payload) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await LocalizationService.updateLocalization(
        localization_id,
        payload
      );
      if (response.status == 1 && response.data) {
        dispatch(
          setAlert(
            response.message ?? "localization updated successfully",
            AlertTypes.SUCCESS
          )
        );
        dispatch(editLocalization(response.data.updatedLocalization));
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update language",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteLocalization = (localization_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await LocalizationService.deleteLocalization(
      localization_id
    );
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "localization deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeLocalization(localization_id));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to delete localization",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
