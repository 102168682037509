import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

// >>>>>>>>>>>>>>>>>>>> Merchants <<<<<<<<<<<<<<<<<<<

const getMerchants = (
  page = 1,
  limit = 50,
  name,
  types,
  tags,
  country_id,
  state_id,
  city_id,
  brand_id
) => {
  name = name ? `&name=${name}` : "";
  const type = types?.length ? `&type=${types.join(",")}` : "";
  const tag = tags?.length ? `&tag=${tags.join(",")}` : "";

  const country = country_id ? `&country_id=${country_id}` : "";
  const state = state_id ? `&state_id=${state_id}` : "";
  const city = city_id ? `&city_id=${city_id}` : "";
  const brandId = brand_id ? `&brand_id=${brand_id}` : "";

  return RestClient.Get(
    `${API_URL.GET_MERCHANTS}?page=${page}&limit=${limit}${name}${type}${tag}${country}${state}${city}${brandId}`
  );
};

const getMerchant = (merchantId) => {
  return RestClient.Get(`${API_URL.GET_MERCHANT}/${merchantId}`);
};

const createMerchant = (payload) => {
  return RestClient.Post(API_URL.CREATE_MERCHANT, payload);
};

const updateMerchant = (merchantId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_MERCHANT}/${merchantId}`, payload);
};

const updateMerchantPassword = (merchantId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MERCHANT_PASSWORD}/${merchantId}`,
    payload
  );
};

const updateLanguageMerchant = (merchantId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_LANGUAGE_MERCHANT}/${merchantId}`,
    payload
  );
};

const publishMerchant = (merchantId) => {
  return RestClient.Put(`${API_URL.PUBLISH_MERCHANT}/${merchantId}`);
};

const deleteMerchant = (merchantId) => {
  return RestClient.Delete(`${API_URL.DELETE_MERCHANT}/${merchantId}`);
};

// >>>>>>>>>>>>>>>>>>>> Merchants Tag <<<<<<<<<<<<<<<<<<<

const getMerchantTags = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_MERCHANT_TAGS}?page=${page}&limit=${limit}`
  );
};

const getMerchantTag = (merchantTagId) => {
  return RestClient.Get(`${API_URL.GET_MERCHANT_TAG}/${merchantTagId}`);
};

const createMerchantTag = (payload) => {
  return RestClient.Post(API_URL.CREATE_MERCHANT_TAG, payload);
};

const updateMerchantTag = (merchantTagId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MERCHANT_TAG}/${merchantTagId}`,
    payload
  );
};

const updateLanguageMerchantTag = (merchantTagId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_LANGUAGE_MERCHANT_TAG}/${merchantTagId}`,
    payload
  );
};

const deleteMerchantTag = (merchantTagId) => {
  return RestClient.Delete(`${API_URL.DELETE_MERCHANT_TAG}/${merchantTagId}`);
};

// >>>>>>>>>>>>>>>>>>>> Merchants Types <<<<<<<<<<<<<<<<<<<

const getMerchantTypes = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_MERCHANT_TYPES}?page=${page}&limit=${limit}`
  );
};

const getMerchantType = (merchantTypeId) => {
  return RestClient.Get(`${API_URL.GET_MERCHANT_TYPE}/${merchantTypeId}`);
};

const createMerchantType = (payload) => {
  return RestClient.Post(API_URL.CREATE_MERCHANT_TYPE, payload);
};

const updateMerchantType = (merchantTypeId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MERCHANT_TYPE}/${merchantTypeId}`,
    payload
  );
};

const updateLanguageMerchantType = (merchantTypeId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_LANGUAGE_MERCHANT_TYPE}/${merchantTypeId}`,
    payload
  );
};

const deleteMerchantType = (merchantTypeId) => {
  return RestClient.Delete(`${API_URL.DELETE_MERCHANT_TYPE}/${merchantTypeId}`);
};

// >>>>>>>>>>>>>>>>>>>> Vibes <<<<<<<<<<<<<<<<<<<

const getVibes = (page = 1, limit = 50, merchant_id) => {
  const merchant = merchant_id ? `&merchant_id=${merchant_id}` : "";
  return RestClient.Get(
    `${API_URL.GET_VIBES}?page=${page}&limit=${limit}${merchant}`
  );
};

const getVibe = (vibe_id) => {
  return RestClient.Get(`${API_URL.GET_VIBE}/${vibe_id}`);
};

const getVibeLikes = (merchantId) => {
  return RestClient.Get(`${API_URL.GET_VIBE_LIKED_BY}/${merchantId}`);
};
const getVibeSeen = (merchantId) => {
  return RestClient.Get(`${API_URL.GET_VIBE_SEEN_BY}/${merchantId}`);
};

const createVibe = (payload) => {
  return RestClient.Post(`${API_URL.CREATE_VIBE}`, payload);
};

const updateVibe = (vibe_id, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_VIBE}/${vibe_id}`, payload);
};

const deleteVibe = (vibe_id) => {
  return RestClient.Delete(`${API_URL.DELETE_VIBE}/${vibe_id}`);
};

const exportedObject = {
  getMerchants,
  getMerchant,
  createMerchant,
  updateMerchant,
  updateMerchantPassword,
  updateLanguageMerchant,
  publishMerchant,
  deleteMerchant,

  getMerchantTags,
  getMerchantTag,
  createMerchantTag,
  updateMerchantTag,
  updateLanguageMerchantTag,
  deleteMerchantTag,

  getMerchantTypes,
  getMerchantType,
  createMerchantType,
  updateMerchantType,
  updateLanguageMerchantType,
  deleteMerchantType,

  getVibes,
  getVibe,
  getVibeLikes,
  getVibeSeen,
  createVibe,
  updateVibe,
  deleteVibe,
};

export default exportedObject;
