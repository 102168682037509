import merchantPointsIcon from "assets/img/airplay.png";
import mithuPointsIcon from "assets/img/mithu.png";
import Card from "components/card";
import { Link } from "react-router-dom";
import { roundToTwoDecimals } from "utils/App.utils";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { FaArrowDown, FaArrowUp, FaBan } from "react-icons/fa";
import { TRANSACTION_TYPE, TRANSACTION_STAUS } from "constants/app.constants";
import { formatNumberWithCommas } from "utils/App.utils";
import { formatLocalTimeDate } from "utils/App.utils";

function TransactionsTableCard({ transactions, children }) {

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      <div className="flex items-center gap-1">
        <img className="h-4 w-4 " src={merchantPointsIcon} alt="" />
        <p>Name</p>
      </div>
    </div>,
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      {"Customer"}
    </div>,
    "Number",
    "Amount",
    "Discount",
    <div className="flex items-center gap-1">
      <img className="h-4 w-4 " src={merchantPointsIcon} alt="" />
      <p>Points</p>
    </div>,
    <div className="flex items-center gap-1">
      <img className="h-4 w-4 " src={mithuPointsIcon} alt="" />
      <p>Points</p>
    </div>,
    "Type",
    "Date",
  ];

  const records = transactions.map((transaction) => ({
    1: (
      <div className="flex items-center gap-2">
        <div className="flex h-10 w-10 items-center justify-center">
          <img
            className="h-9 w-9 rounded-full"
            src={transaction.logo ?? ProfileIcon}
            alt=""
          />
        </div>
        {transaction.merchant_name}
      </div>
    ),

    2: (
      <Link
        to={`/customer/profile/${transaction?.customer?.id}`}
        className="flex items-center gap-2"
      >
        <div className="flex h-10 w-10 items-center justify-center">
          <img
            className="h-9 w-9 cursor-pointer rounded-full"
            src={transaction.customer?.profile_pic || ProfileIcon}
            alt=""
          />
        </div>{" "}
        {transaction.customer?.name}
      </Link>
    ),
    order_number: transaction.order_number,
    order_amount: `${transaction.currency} ${formatNumberWithCommas(
      Number(transaction?.order_amount || 0).toFixed(2)
    )}`,
    discount: `${transaction.currency} ${formatNumberWithCommas(
      Number(transaction?.discount_amount || 0).toFixed(2)
    )}`,
    merchant_point: (
      <div className="flex items-center justify-between gap-1 text-nowrap">
        <p className="flex">
          {
            transaction?.merchantTransactionStatus == TRANSACTION_STAUS.ENABLED ? (
              transaction?.type == TRANSACTION_TYPE.EARNING ? (
                <FaArrowDown className={`h-6 w-6 p-1 text-green-500`} />
              ) : (
                <FaArrowUp className={`h-6 w-6 p-1 text-red-500`} />
              )
            ) :
              (
                <FaBan className={`h-6 w-6 p-1 text-gray-500`} />
              )
          }
          {transaction?.merchantTransactionStatus == TRANSACTION_STAUS.ENABLED ? `${Number(transaction?.merchant_point || 0).toFixed(2)} pts` : `${Number(0).toFixed(2)} pts`}
        </p>
      </div>
    ),
    network_point: (
      <div className="flex items-center justify-between gap-1 text-nowrap">
        <p className="flex">
          {
            transaction?.networkTransactionStatus == TRANSACTION_STAUS.ENABLED ? (
              transaction?.type == TRANSACTION_TYPE.EARNING ? (
                <FaArrowDown className={`h-6 w-6 p-1 text-green-500`} />
              ) : (
                <FaArrowUp className={`h-6 w-6 p-1 text-red-500`} />
              )
            ) :
              (
                <FaBan className={`h-6 w-6 p-1 text-gray-500`} />
              )
          }
          {transaction?.networkTransactionStatus == TRANSACTION_STAUS.ENABLED ? `${Number(transaction?.network_point || 0).toFixed(2)} pts` : `${Number(0).toFixed(2)} pts`}
        </p>
      </div>
    ),
    type: (
      <span
        className={`inline-block w-full text-nowrap rounded-full px-2 py-1 text-center text-xs font-bold ${transaction?.type == TRANSACTION_TYPE.EARNING
          ? "bg-green-600"
          : "bg-red-600"
          }  text-white`}
      >
        {transaction?.type == TRANSACTION_TYPE.EARNING ? "Earn" : "Redeem"}
      </span>
    ),
    created_at: formatLocalTimeDate(transaction.created_at),
  }));

  return (
    <div>
      <Card extra={" bg-white"}>
        <CardHeader label={"Transactions"} />
        {children}

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default TransactionsTableCard;
