import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../../actions/actionTypes";
import MerchantPromotionTagService from "../../services/merchantPromotionTags/MerchantPromotionTagService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_PROMOTION_TAG_LOADING,
    payload: loading,
  });
};

export const setMerchantPromotionTags = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_PROMOTION_TAGS,
    payload: data,
  });
};

export const setMerchantPromotionTag = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_PROMOTION_TAG,
    payload: data,
  });
};

export const removeMerchantPromotionTag =
  (merchantPromotionTagId) => (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_MERCHANT_PROMOTION_TAG,
      payload: merchantPromotionTagId,
    });
  };

export const resetMerchantPromotionTagState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_MERCHANT_PROMOTION_TAG_STATE,
  });
};

export const getMerchantPromotionTags = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response =
      await MerchantPromotionTagService.getMerchantPromotionTags(page, limit);
    if (response.status == 1) {
      dispatch(setMerchantPromotionTags(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load merchant  promotion tags",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMerchantPromotionTag =
  (merchantPromotionTagId) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response =
        await MerchantPromotionTagService.getMerchantPromotionTag(
          merchantPromotionTagId
        );
      if (response.data && response.status == 1) {
        dispatch(setMerchantPromotionTag(response.data));
        return;
      }
      dispatch(
        setAlert(
          response.message ?? "failed to load merchant promotion tag",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const createMerchantPromotionTag =
  (payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response =
        await MerchantPromotionTagService.createMerchantPromotionTag(payload);
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "merchant promotion tag created successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/merchant-promotion-tags");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to create merchant promotion tag",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateMerchantPromotionTag =
  (merchantPromotionTagId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response =
        await MerchantPromotionTagService.updateMerchantPromotionTag(
          merchantPromotionTagId,
          payload
        );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "merchant promotion tag updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/merchant-promotion-tags");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update merchant promotion tag",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateMultiLangMerchantPromotionTag =
  (merchantPromotionTagId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response =
        await MerchantPromotionTagService.updateMultiLangMerchantPromotionTag(
          merchantPromotionTagId,
          payload
        );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ??
              "merchant promotion tag localization updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/merchant-promotion-tags");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update merchant promotion tag",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteMerchantPromotionTag =
  (merchantPromotionTagId, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response =
        await MerchantPromotionTagService.deleteMerchantPromotionTag(
          merchantPromotionTagId
        );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "merchant promotion tag deleted successfully",
            AlertTypes.SUCCESS
          )
        );
        dispatch(removeMerchantPromotionTag(merchantPromotionTagId));
        if (!navigate) return;
        return navigate("/merchant-promotion-tags");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to delete merchant promotion tag",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };
