import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FacilityTableCard from "./facility-table-card";
import { getFacilities } from "actions/facility/facility.action";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const Facility = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const dispatch = useDispatch();
  const { facilitiesCount, facilities, loading } = useSelector(
    (state) => state.facility
  );

  useEffect(() => {
    dispatch(getFacilities(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <FacilityTableCard facilities={facilities} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={facilitiesCount}
      />
    </div>
  );
};

export default Facility;
