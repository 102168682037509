/* eslint-disable */
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { hasPermission } from "utils/permissions";
import CreateLink from "./createLink";

export function SidebarLinks(props) {
  const { permissions } = useSelector((state) => state.role);
  const { routes } = props;
  const { user } = useSelector((state) => state.auth);

  // State to keep track of which link is open
  const [openLink, setOpenLink] = useState(null);

  const createLinks = (routes) => {
    return routes
      .filter(
        (x) =>
          x.isShowInSideNav == true &&
          hasPermission(x.requiredRoles, x.requiredAction)
      )
      .map((route, index) => (
        <CreateLink
          route={route}
          key={route.path}
          isOpen={openLink === route.path} // Pass whether this link is open
          setOpen={() => setOpenLink(openLink === route.path ? null : route.path)} // Toggle open/close
        />
      ));
  };

  return useMemo(
    () => createLinks(routes),
    [JSON.stringify(routes), JSON.stringify(permissions), openLink]
  );
}

export default SidebarLinks;
