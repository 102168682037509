import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { TbClipboardCopy } from "react-icons/tb";
import ConfirmationModal from './ConfirmationModal';
import { PiFileHtml } from "react-icons/pi";

const isHTMLString = (str) => {
    const htmlRegex = /<[^>]+>/;
    return htmlRegex.test(str);
};

const DescriptionEditor = ({ value, onChange }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [showHtmlPopup, setShowHtmlPopup] = useState(false)
    const [htmlCode, setHtmlCode] = useState('')

    const getHtml = () => {
        const contentState = editorState.getCurrentContent();
        return stateToHTML(contentState);
    };

    useEffect(() => {
        if (value == getHtml()) return

        let contentState;
        if (value && isHTMLString(value)) {
            contentState = stateFromHTML(value);
        } else {
            const wrappedContent = `<p>${value}</p>`;
            contentState = stateFromHTML(wrappedContent);
        }
        setEditorState(EditorState.createWithContent(contentState));
    }, [value]);

    const handleEditorChange = (state) => {
        setEditorState(state);
        const contentState = state.getCurrentContent();
        onChange(stateToHTML(contentState));
    };

    const onClickCopy = () => {
        navigator.clipboard.writeText(getHtml())
    };

    const handleClickEditHtml = () => {
        setHtmlCode(getHtml())
        setShowHtmlPopup(true)
    }

    const handleSaveHtml = () => {
        onChange(htmlCode)
        setShowHtmlPopup(false)
        // contentState = stateFromHTML(htmlCode)
        // setEditorState(EditorState.createWithContent(contentState));
    }

    return (
        <div className='border border-gray-300 rounded-md w-full'>
            {showHtmlPopup ? (
                <ConfirmationModal
                    onBack={() => setShowHtmlPopup(false)}
                    onConfirm={handleSaveHtml}
                    confirmBtnText="save"
                    confirmBtnType="primary"
                >

                    <div className="flex flex-col w-full gap-1">
                        <label
                            for="content"
                            className="mb-1 block text-lg font-medium text-gray-800"
                        >
                            Edit HTML
                        </label>
                        <textarea
                            required
                            name="description"
                            value={htmlCode}
                            onChange={(e) => setHtmlCode(e.target.value)}
                            className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-gray-500 focus:outline-none"
                            rows="8"
                        />
                    </div>
                </ConfirmationModal>
            ) : null}
            <div className='reset-styles'>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    toolbarCustomButtons={[
                        <TbClipboardCopy
                            title='copy source code'
                            className="w-8 h-8 p-1 text-gray-800 cursor-pointer"
                            onClick={onClickCopy}
                        />,
                        <PiFileHtml
                            title='edit html'
                            className="w-8 h-8 p-1 text-gray-800 cursor-pointer"
                            onClick={handleClickEditHtml}
                        />
                    ]}
                />
                {/* <div dangerouslySetInnerHTML={{ __html: getHtml() }}></div> */}
            </div>

        </div>
    );
};

export default DescriptionEditor;
