import Card from "components/card";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "../../../components/cardHeader";
import Table from "../../../components/table";

function StateCitiesTableCard({ cities }) {
  const { countryCode } = useParams();
  const navigate = useNavigate();
  const tableHeaders = [
    "Name",
    "State Code",
    "Country Code",
  ];

  const records = cities.map((city) => ({
    name: city.name,
    state_code: city.state_code,
    country_code: city.country_code,
    // created_at: dayjs(city.created_at).format("DD-MM-YYYY"),
    // updated_at: dayjs(city.updated_at).format("DD-MM-YYYY"),
  }));

  return (
    <div>
      <Card extra={" bg-white"}>
        <CardHeader
          label={"Cities"}
          buttons={[
            {
              text: "Back",
              onClick: () => navigate(`/countries/${countryCode}/states`),
            },
          ]}
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default StateCitiesTableCard;
