import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import LogsService from "../../services/activityLog/LogsService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_LOG_LOADING,
    payload: loading,
  });
};

export const setLogs = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_LOGS,
    payload: data,
  });
};
export const resetLogsState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_LOG_STATE,
  });
};

export const getLogs = (entity_name, entity_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await LogsService.getLogs(entity_name, entity_id);
    if (response.status == 1) {
      dispatch(setLogs(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load logs", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
