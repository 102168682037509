import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  customersCount: 0,
  customers: [],
  customer: null,
  loading: false,
};

export const customersReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_CUSTOMERS:
      return {
        ...state,
        customersCount: payload?.meta?.total_documents || 0,
        customers: payload.customers,
        loading: false,
      };
    case ActionTypes.SET_UPDATE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.map((customer) =>
          customer.id === payload.customer.id
            ? {
                ...customer,
                disable_nft: payload.customer.disable_nft,
                disable_earning: payload.customer.disable_earning,
                disable_redemption: payload.customer.disable_redemption,
              }
            : customer
        ),
        loading: false,
      };
    case ActionTypes.SET_CUSTOMER:
      return {
        ...state,
        customer: payload,
        loading: false,
      };
    case ActionTypes.SET_CUSTOMER_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_CUSTOMER_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
