import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { FORM_TYPE } from "constants/app.constants";
import FacilityForm from "./facilityForm";
import { createFacility } from "actions/facility/facility.action";

const CreateFacility = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <FacilityForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createFacility(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateFacility;
