import Card from "components/card";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";

function LocalizationsTableCard({ localizations, onClickAddNew, onClickEdit, onClickDelete }) {

  const tableHeaders = ["Key", "Value", "Created By", "Actions"];
  const records = localizations.map((localization) => ({
    key: localization.key,
    value: localization.value,
    created_by: localization?.created_by?.name ?? "",
    actions: (
      <div className="flex items-center  gap-2">
        <MdOutlineEdit
          onClick={() => onClickEdit(localization)}
          className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
        />
        <MdOutlineDelete
          onClick={() => onClickDelete(localization)}
          className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
        />
      </div>
    ),
  }));

  return (
    <div>
      <Card extra={" bg-white"}>
        <CardHeader
          label={"Localizations"}
          buttons={[{
            text: "Add New",
            onClick: onClickAddNew,
            variant: "primary",
          }]}
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default LocalizationsTableCard;
