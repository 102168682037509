import Card from "components/card";
import { useState } from "react";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import dayjs from "dayjs";
import { deleteSlider } from "actions/sliders/sliders.action";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";

const SliderTableCard = ({ sliders, children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [deleteSliderId, setDeleteSliderId] = useState(null);

  const onClickDelete = (sliderId) => {
    setDeleteSliderId(sliderId);
    setShowDelModal(true);
  };

  const handleCancelDelete = () => {
    setShowDelModal(false);
    setDeleteSliderId(null);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteSlider(deleteSliderId));
    handleCancelDelete();
  };

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-14 items-center justify-center" />
      {"Title"}
    </div>,
    "Status",
    "Type",
    "Created By",
    "Actions",
  ];
  const records = sliders.map((slider) => ({
    image: (
      <div className="flex items-center gap-2">
        <div className="flex h-10 w-14 items-center justify-center">
          <img className="h-9 w-full  rounded-md" src={slider.image} alt="" />
        </div>
        {slider.title.split(" ").join("\u00A0")}
      </div>
    ),
    status: slider.status,
    type: slider?.type || "",
    created_by: slider?.created_by?.name ?? "",
    actions: (
      <div className="flex items-center  gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.SLIDER_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => navigate(`/sliders/update/${slider.id}`)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.SLIDER_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
            onClick={() => onClickDelete(slider.id)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));
  return (
    <div>
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the slider with title{" "}
              {sliders.find((slider) => slider.id === deleteSliderId).title}
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={" bg-white"}>
        <CardHeader
          label={"Sliders"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.SLIDER_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [{
                  text: "Add New",
                  onClick: () => navigate("/sliders/create"),
                variant: "primary",
                }]
              : null
          }
        />
        {children}

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
};

export default SliderTableCard;
