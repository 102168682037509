import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  stats: null,
  loading: false,
};

export const dashboardReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_DASHBOARD_STATS:
      return {
        ...state,
        stats: payload.stats,
        loading: false,
      };
    case ActionTypes.SET_DASHBOARD_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_DASHBOARD_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
