import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Loader from "components/loaderSpinner/Loader";
import { FORM_TYPE } from "constants/app.constants";
import ConfirmationModal from "../components/ConfirmationModal";
import FacilityForm from "./facilityForm";
import { updateFacility } from "actions/facility/facility.action";
import { updateMultiLangFacility } from "actions/facility/facility.action";
import { deleteFacility } from "actions/facility/facility.action";
import { getFacility } from "actions/facility/facility.action";

const UpdateFacility = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { facilityId } = useParams();
  const { facility, loading } = useSelector((state) => state.facility);

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = () => {
    setShowDelModal(true);
  };

  const handleCancelDelete = () => {
    setShowDelModal(false);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteFacility(facilityId, navigate));
    handleCancelDelete();
  };

  useEffect(() => {
    dispatch(getFacility(facilityId));
  }, [facilityId]);

  const handleSubmit = (payload, redirect) => {
    if (payload.lang_id) {
      dispatch(
        updateMultiLangFacility(facilityId, payload, redirect ? navigate : null)
      );
      return;
    }
    dispatch(
      updateFacility(facilityId, payload, redirect === false ? null : navigate)
    );
  };

  if (!facilityId || facility?.id !== facilityId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the facility</h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <FacilityForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={facility}
        handleFormSubmit={handleSubmit}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateFacility;
