import { createMerchant } from "actions/merchants/merchants.actions";
import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MerchantForm from "./merchantForm";
import { getCountries } from "actions/regions/regions.actions";
import { resetRegionState } from "actions/regions/regions.actions";
import { useEffect } from "react";

const CreateNewMerchant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetRegionState())
    dispatch(getCountries());
  }, []);

  return (
    <div className="h-full w-full">
      <MerchantForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData, redirect) =>
          dispatch(createMerchant(formData, redirect ? navigate : null))
        }
      />
    </div>
  );
};

export default CreateNewMerchant;
