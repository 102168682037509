import { filterRecentSocketConnections } from "utils/App.utils";
import * as ActionTypes from "../actionTypes";

export const setActiveUsersEvent = (data) => (dispatch) => {
  const filteredData = filterRecentSocketConnections(data);
  dispatch({
    type: ActionTypes.SET_ACTIVE_USERS_EVENT,
    payload: filteredData,
  });
};

export const setNewOrderEvent = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_NEW_ORDER_EVENT,
    payload: null,
  });
};

export const setNewTransactionEvent = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_NEW_TRANSACTION_EVENT,
    payload: null,
  });
};

export const setNewCustomerEvent = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_NEW_CUSTOMER_EVENT,
    payload: null,
  });
};

export const setNewMerchantEvent = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_MERCHANT_EVENT,
    payload: null,
  });
};

export const setNewMembershipEvent = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_NEW_MERBERSHIP_EVENT,
    payload: null,
  });
};

export const resetEventsState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_EVENTS_STATE,
  });
};
