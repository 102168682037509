import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import RolesService from "../../services/roles/RolesService";
import * as ActionTypes from "../actionTypes";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_ROLE_LOADING,
    payload: loading,
  });
};

export const setRoles = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_ROLES,
    payload: data,
  });
};

export const setRolePermissions = (permissions) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_ROLE_PERMISSIONS,
    payload: permissions,
  });
};

export const setRole = (user) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_ROLE,
    payload: user,
  });
};

export const setUpdatedRole = (data) => (dispatch) => {
  console.log({ updateRole: data });
  dispatch({
    type: ActionTypes.UPDATE_ROLE,
    payload: data,
  });
};

export const resetRolesState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_ROLE_STATE,
  });
};

export const getRoles = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await RolesService.getRoles(page, limit);
    if (response.status == 1 && response.data) {
      dispatch(setRoles(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load users", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getRolePermissions = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await RolesService.getRolePermissions();
    if (response.status == 1 && response.data) {
      dispatch(setRolePermissions(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load permissions",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateRole = (roleId, payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await RolesService.updateRole(roleId, payload);
    if (response.status == 1) {
      dispatch(setUpdatedRole(response.data.updatedRole));
      dispatch(getRolePermissions());
      dispatch(
        setAlert(response.message ?? "role updated", AlertTypes.SUCCESS)
      );
      if (!navigate) return;
      return navigate("/roles");
    }
    dispatch(
      setAlert(response.message ?? "failed to update role", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
