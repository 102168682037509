import EarningsRedemptionsLineChart from "./charts/earning-redemption-line";
import EarningsRedemptionsPieChart from "./charts/earning-redemption-pie";
import LanguagesCustomersPieChart from "./charts/languages-customers-pie";
import TopCustomersTable from "./charts/top-customers-table";
import TopMerchantsTable from "./charts/top-merchants-table";
import WidgetCards from "./widgetCards";

const Dashboard = () => {

  return (
    <div>
      {/* Card widget */}
      <WidgetCards />

      {/* Charts */}
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {/* Total Spent Line */}
        <EarningsRedemptionsLineChart />

        {/* Pie Charts */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <EarningsRedemptionsPieChart />
          <LanguagesCustomersPieChart />
        </div>
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Top Merchant and Customers */}
        <div>
          <TopMerchantsTable />
        </div>
        <TopCustomersTable />

        {/* <WeeklyRevenue />

        Complex Table , Task & Calendar

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}

        {/* Daily Traffic */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
