import { validateUserOTP } from "actions/auth/auth.actions";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import authImg from "../assets/img/auth/auth.png";

export default function ValidateOtp() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email")
  const [otp, setOtp] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(validateUserOTP({ email, otp }, navigate));
  };

  return (
    <div className="flex min-h-full justify-between">
      <div className="flex min-h-full w-full flex-col justify-center px-6 py-12 md:w-1/2 lg:px-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Enter your OTP
          </h2>
          <p className="mt-4 mb-4 text-center text-sm text-gray-600">
            We've sent you an otp to {email}. please check your email
          </p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="otp" className="sr-only">
                  OTP
                </label>
                <input
                  id="otp"
                  name="otp"
                  value={otp}
                  type="otp"
                  required
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                  placeholder="i.e 123456"
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="absolute right-0 hidden h-full min-h-screen w-1/2 md:flex">
        <div
          className="absolute flex h-full w-full items-end justify-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
          style={{ backgroundImage: `url(${authImg})` }}
        />
      </div>
    </div>
  );
}
