import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../../actions/actionTypes";
import ConfigurationService from "../../services/configuration/ConfigurationService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CONFIGURATION_LOADING,
    payload: loading,
  });
};

export const setConfiguration = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CONFIGURATION,
    payload: data,
  });
};

export const resetConfigurationState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_CONFIGURATION_STATE,
  });
};

export const getConfiguration = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await ConfigurationService.getConfiguration();
    if (response.data && response.status == 1) {
      dispatch(setConfiguration(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load configuration",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateConfiguration = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await ConfigurationService.updateConfiguration(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "configuration updated successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(getConfiguration());
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to update configuration",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
