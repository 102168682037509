import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import PushNotificationService from "../../services/pushNotifications/PushNotificationService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_PUSH_NOTIFICATION_LOADING,
    payload: loading,
  });
};

export const setPushNotifications = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_PUSH_NOTIFICATIONS,
    payload: data,
  });
};

export const setPushNotification = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_PUSH_NOTIFICATION,
    payload: data,
  });
};

export const resetPushNotificationState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_PUSH_NOTIFICATION_STATE,
  });
};

export const getPushNotifications = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await PushNotificationService.getPushNotifications(
      page,
      limit
    );
    if (response.data && response.status == 1) {
      dispatch(setPushNotifications(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load Push Notifications",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createPushNotification =
  (payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await PushNotificationService.createPushNotification(
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "Push notification created successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/push-notifications");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to create notification",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };
