import Card from "components/card";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { FaGreaterThanEqual } from "react-icons/fa";
import { hasPermission } from "utils/permissions";
import { PERSMISSIONS_TYPES } from "constants/role.constants";
import { PERMISSION_ACTION_TYPES } from "constants/role.constants";

function ClassificationTableCard({
  customerClassifications,
  onClickAddNew,
  onClickEdit,
  onClickDelete,
}) {
  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className="flex h-10 w-10 items-center justify-center" />
      {"Name"}
    </div>,
    "Points",
    "Created By",
    "Actions",
  ];
  const records = customerClassifications.map((Classification) => ({
    image: (
      <div className="flex items-center gap-2">
        <div className="flex h-10 w-10 items-center justify-center">
          <img
            className="h-9 w-full  rounded-md"
            src={Classification.image}
            alt=""
          />
        </div>
        {Classification.name.split(" ").join("\u00A0")}
      </div>
    ),
    points: (
      <div className="flex w-full items-center ">
        &ge; {Classification.points}
      </div>
    ),
    created_by: Classification?.created_by?.name ?? "",
    actions: (
      <div className="flex items-center  gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.CLASSIFICATION_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => onClickEdit(Classification.id)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.CLASSIFICATION_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
            onClick={() => onClickDelete(Classification)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));

  return (
    <div>
      <Card extra={"h-full  w-full bg-white"}>
        <CardHeader
          label={"Customer Classifications"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.CLASSIFICATION_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [{
                  text: "Add New",
                  onClick: onClickAddNew,
                variant: "primary",
                }]
              : null
          }
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default ClassificationTableCard;
