import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getCountries = (page = 1, limit = 1000, name) => {
  let query = `?page=${page}&limit=${limit}`;
  if (name) query += `&name=${name}`;

  return RestClient.Post(`${API_URL.GET_COUNTRIES}${query}`);
};

const getStates = (country_code, name = "", page = 1, limit = 1000) => {
  let query = `?page=${page}&limit=${limit}`;
  if (name) query += `&name=${name}`;

  return RestClient.Post(`${API_URL.GET_STATES}${query}`, {
    ...(country_code && { country_code }),
  });
};

const getCities = (country_code, state_code, name, page = 1, limit = 1000) => {
  let query = `?page=${page}&limit=${limit}`;
  if (name) query += `&name=${name}`;

  return RestClient.Post(`${API_URL.GET_CITIES}${query}`, {
    ...(country_code && { country_code }),
    ...(state_code && { state_code }),
  });
};

const getAreas = (countryId = "", stateId = "", cityId = "") => {
  let url = `${API_URL.GET_AREAS}?`;

  if (countryId !== "") {
    url += `country_id=${countryId}`;
    if (stateId !== "") {
      url += `&state_id=${stateId}`;
      if (cityId !== "") {
        url += `&city_id=${cityId}`;
      }
    }
  }

  return RestClient.Get(url);
};

const getArea = (areaId) => {
  return RestClient.Get(`${API_URL.GET_AREA}/${areaId}`);
};

const createArea = (payload) => {
  return RestClient.Post(API_URL.CREATE_AREA, payload);
};

const updateArea = (areaId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_AREA}/${areaId}`, payload);
};

const deleteArea = (areaId) => {
  return RestClient.Delete(`${API_URL.DELETE_AREA}/${areaId}`);
};

const exportedObject = {
  getCountries,
  getStates,
  getCities,
  getAreas,
  getArea,
  createArea,
  updateArea,
  deleteArea,
};

export default exportedObject;
