import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getLanguages = (
  page = 1,
  limit = 50,
  lang_id,
  name,
  code,
  isRTL,
  locale,
  status
) => {
  const params = {
    ...(page && { page }),
    ...(limit && { limit }),
    ...(lang_id && { lang_id }),
    ...(name && { name }),
    ...(code && { code }),
    ...(typeof isRTL == "boolean" && { isRTL }),
    ...(locale && { locale }),
    ...(status && { status }),
  };

  const paramString = Object.entries(params)
    .map(([key, value]) => key + "=" + value)
    .join("&");

  return RestClient.Get(`${API_URL.GET_LANGUAGES}?${paramString}`);
};

const getLanguage = (language_id) => {
  return RestClient.Get(`${API_URL.GET_LANGUAGE}/${language_id}`);
};

const createLanguage = (payload) => {
  return RestClient.Post(API_URL.CREATE_LANGUAGE, payload);
};

const updateLanguage = (language_id, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_LANGUAGE}/${language_id}`, payload);
};

const updateMultiLangLanguage = (language_id, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MULTI_LANG_LANGUAGE}/${language_id}`,
    payload
  );
};

const deleteLanguage = (language_id) => {
  return RestClient.Delete(`${API_URL.DELETE_LANGUAGE}/${language_id}`);
};

const exportedObject = {
  getLanguages,
  getLanguage,
  createLanguage,
  updateLanguage,
  updateMultiLangLanguage,
  deleteLanguage,
};

export default exportedObject;
