import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import BrandsService from "../../services/brands/BrandsService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_BRAND_LOADING,
    payload: loading,
  });
};

export const setBrands = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_BRANDS,
    payload: data,
  });
};

export const setBrand = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_BRAND,
    payload: data,
  });
};

export const editBrand = (brand) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_BRAND,
    payload: brand,
  });
};

export const removeBrand = (brandId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_BRAND,
    payload: brandId,
  });
};

export const resetBrandState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_BRAND_STATE,
  });
};

export const getBrands = (page,limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await BrandsService.getBrands(page,limit);
    if (response.status == 1) {
      dispatch(setBrands(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load brands", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getBrand = (brandId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await BrandsService.getBrand(brandId);
    if (response.data && response.status == 1) {
      dispatch(setBrand(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Brand", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createBrand = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await BrandsService.createBrand(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "brand created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/brands");
    }
    dispatch(
      setAlert(response.message ?? "failed to create brand", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateBrand = (brandId, payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await BrandsService.updateBrand(brandId, payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "brand updated successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/brands");
    }
    dispatch(
      setAlert(response.message ?? "failed to update brand", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateBrandPassword =
  (brandId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await BrandsService.updateBrandPassword(
        brandId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "brand password updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/brands");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update brand password",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

// export const updateMultiLangBrand =
//   (brandId, payload, navigate) => async (dispatch) => {
//     try {
//       dispatch(setLoading(true));
//       const response = await BrandsService.updateMultiLangBrand(
//         brandId,
//         payload
//       );
//       if (response.status == 1) {
//         dispatch(
//           setAlert(
//             response.message ?? "brand localization updated successfully",
//             AlertTypes.SUCCESS
//           )
//         );
//         if (!navigate) return;
//         return navigate("/brands");
//       }
//       dispatch(
//         setAlert(
//           response.message ?? "failed to update brand",
//           AlertTypes.ERROR
//         )
//       );
//     } catch (error) {
//       dispatch(setAlert("server error", AlertTypes.ERROR));
//     } finally {
//       dispatch(setLoading(false));
//     }
//   };

export const deleteBrand = (brandId, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await BrandsService.deleteBrand(brandId);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "brand deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeBrand(brandId));
      if (!navigate) return;
      return navigate("/brands");
    }
    dispatch(
      setAlert(response.message ?? "failed to delete brand", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
