import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "../components/cardHeader";
import TicketForm from "./ticketsForm";
import { updateTicket } from "actions/tickets/tickets.action";
import Loader from "components/loaderSpinner/Loader";
import { getTicket } from "actions/tickets/tickets.action";

const UpdateTicket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ticket_id } = useParams();
  const { ticket, loading } = useSelector((state) => state.ticket);
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    dispatch(getTicket(ticket_id));
  }, [ticket_id]);

  if (!ticket_id || ticket?.id !== ticket_id)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      <CardHeader
        label={"Update Ticket"}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/tickets"),
            variant: "dark",
          },
          {
            text: "View Logs",
            onClick: () => setShowLogsModal(true),
            variant: "primary",
          },
        ]}
      />
      <TicketForm
        showLogsModal={showLogsModal}
        setShowLogsModal={setShowLogsModal}
        initialFormData={ticket}
        handleFormSubmit={(formData) =>
          dispatch(updateTicket(ticket_id, formData, navigate))
        }
      />
    </div>
  );
};

export default UpdateTicket;
