import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../../actions/actionTypes";
import CurrencyService from "../../services/currency/CurrencyService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CURRENCY_LOADING,
    payload: loading,
  });
};

export const setCurrencies = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CURRENCIES,
    payload: data,
  });
};

export const setCurrency = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CURRENCY,
    payload: data,
  });
};

export const removeCurrency = (currency_id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_CURRENCY,
    payload: currency_id,
  });
};

export const resetCurrencyState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_CURRENCY_STATE,
  });
};

export const getCurrencies = (page,limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const response = await CurrencyService.getCurrencies(page,limit);
    if (response.data && response.status == 1) {
      dispatch(setCurrencies(response.data));
      return;
    }
    dispatch(
      setAlert(
        response.message ?? "failed to load Currencies",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getCurrency = (currency_id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CurrencyService.getCurrency(currency_id);
    if (response.status == 1) {
      dispatch(setCurrency(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Currrency", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createCurrency = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CurrencyService.createCurrency(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "Currency created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/currencies");
    }
    dispatch(
      setAlert(response.message ?? "failed to create FAQ", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateCurrency =
  (currency_id, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await CurrencyService.updateCurrency(
        currency_id,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "Currency updated successfully",
            AlertTypes.SUCCESS
          )
        );
        // dispatch(editSlider(response.data.updatedLang));
        if (!navigate) return;
        return navigate("/currencies");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update currency",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteCurrency = (currency_id, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await CurrencyService.deleteCurrency(currency_id);
    if (response.status == 1) {
      dispatch(removeCurrency(currency_id));
      dispatch(
        setAlert(
          response.message ?? "Currency deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/currencies");
    }
    dispatch(
      setAlert(
        response.message ?? "failed to delete Currency",
        AlertTypes.ERROR
      )
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
