import { getCustomers } from "actions/customers/customers.actions";
import { getMerchants } from "actions/merchants/merchants.actions";
import {
  getCities,
  getCountries,
  getStates,
  setCities,
  setStates,
} from "actions/regions/regions.actions";
import { getTransactions } from "actions/transactions/TransactionActions";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import Pagination from "../components/pagination";
import TransactionsTableCard from "./transactions-table-card";
import { getSystemSettings } from "selectors/settings/settings.selector";
import { exportTransactions } from "actions/transactions/TransactionActions";
import { PiExport } from "react-icons/pi";

const Transactions = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { transactionsCount, transactions, loading } = useSelector(
    (state) => state.transaction
  );
  const { merchants, loading: merchantLoading } = useSelector(
    (state) => state.merchant
  );
  const { customers, loading: customerLoading } = useSelector(
    (state) => state.customer
  );
  const {
    countries,
    states,
    cities,
    loading: regionLoading,
  } = useSelector((state) => state.region);
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [merchantId, setMerchantId] = useState("");

  const order_number = searchParams.get("order_number");
  const [customerId, setCustomerId] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [fromDate, setFromDAte] = useState(
    dayjs("1980-01-01").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(dayjs(new Date()).format("YYYY-MM-DD"));
  const [orderNo, setOrderNo] = useState(order_number ?? "");
  const optionAll = { name: "All", value: "" };

  useEffect(() => {
    dispatch(getMerchants(page, 1000));
    dispatch(getCustomers(page, 1000));
    dispatch(getCountries());
  }, []);

  useEffect(() => {
    setState("");
    setCity("");
    dispatch(setStates({ states: [], meta: { totalDocuments: 0 } }));
    dispatch(setCities({ cities: [], meta: { totalDocuments: 0 } }));
    if (!country) return;
    const temp_country = countries.find((c) => c.id === country);
    dispatch(getStates(temp_country.code));
  }, [country]);

  useEffect(() => {
    dispatch(setCities({ cities: [], meta: { totalDocuments: 0 } }));
    setCity("");
    if (!country || !state) return;
    const temp_country = countries.find((c) => c.id === country);
    const temp_state = states.find((s) => s.id === state);
    dispatch(getCities(temp_country.code, temp_state.code));
  }, [state]);

  const getFilteredTransactions = () => {
    setFiltersApplied(true)
    dispatch(
      getTransactions(
        page,
        limit,
        merchantId,
        customerId,
        country,
        state,
        city,
        fromDate,
        toDate,
        orderNo
      )
    );
  };

  useEffect(() => {
    const prev_filter_state = filtersApplied
    getFilteredTransactions();
    setFiltersApplied(prev_filter_state)
  }, [page]);

  const removeAllFilters = () => {
    setFiltersApplied(false)
    setPage(1);
    setMerchantId("");
    setCustomerId("");
    setCountry("");
    setState("");
    setCity("");
    setOrderNo("");
    setFromDAte(dayjs("1980-01-01").format("YYYY-MM-DD"));
    setToDate(dayjs(new Date()).format("YYYY-MM-DD"));
    dispatch(getTransactions(1, limit));
  };

  const exportTransaction = (appFilters) => {
    if (!appFilters) dispatch(exportTransactions());
    else {
      dispatch(
        exportTransactions(
          merchantId,
          customerId,
          country,
          state,
          city,
          fromDate,
          toDate,
          orderNo
        )
      );
    }
  };

  return (
    <div className="h-full w-full">
      <TransactionsTableCard transactions={transactions}>
        <div className="flex items-end justify-between">
          {/* export buttons */}
          <div className="flex items-center justify-start gap-2 p-2">
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={() => exportTransaction(false)}
                className={`flex items-center gap-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50`}
                type="button"
              >
                <PiExport className="h-4 w-4" />
                {"Export All"}
              </button>
            </div>
            {filtersApplied ?
            <div className="w-auto">
              <button
                disabled={loading}
                onClick={() => exportTransaction(true)}
                className={`flex items-center gap-1 px-3 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50`}
                type="button"
              >
                <PiExport className="h-4 w-4" />
                {"Export Filtered Data"}
              </button>
            </div>
              : null
            }
          </div>

        {/* filter buttons */}
        <div className="flex items-center justify-end gap-2 p-2">
          <div className="w-auto">
            <button
              disabled={loading}
              onClick={removeAllFilters}
              className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-red-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-red-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500`}
              type="button"
            >
              <MdFilterAltOff className="h-4 w-4" />
              {"Clear Filters"}
            </button>
          </div>
          <div className="w-auto">
            <button
              disabled={loading}
              onClick={getFilteredTransactions}
              className={`inline-flex w-auto max-w-40 items-center justify-center rounded-lg border-[1px] border-dashed border-green-400 bg-white px-2 py-1 text-sm font-medium text-gray-700 shadow-sm hover:border-green-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500`}
              type="button"
            >
              <CiFilter className="h-4 w-4" />
              {"Apply Filters"}
            </button>
          </div>
        </div>
        </div>

        <div className="mb-1 flex flex-col gap-2 rounded-md border border-indigo-100 bg-white p-1">
          {/* Merchant, Customer and Date */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full  md:w-1/2">
              <HeadlessSelectObject
                disable={!merchants.length || merchantLoading}
                title={merchantLoading ? "Loading..." : "All Merchants"}
                options={[
                  optionAll,
                  ...merchants.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={merchantId}
                onSelect={setMerchantId}
              />
            </div>

            <div className="w-full md:w-1/2">
              <HeadlessSelectObject
                disable={!customers.length || customerLoading}
                title={customerLoading ? "Loading..." : "All Customers"}
                options={[
                  optionAll,
                  ...customers.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={customerId}
                onSelect={setCustomerId}
              />
            </div>
          </div>

          {/* Country, State and City */}
          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full  md:w-1/3">
              <HeadlessSelectObject
                disable={!countries.length || regionLoading}
                title={regionLoading ? "Loading..." : "All Countries"}
                options={[
                  optionAll,
                  ...countries.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={country}
                onSelect={setCountry}
              />
            </div>

            <div className="w-full md:w-1/3">
              <HeadlessSelectObject
                disable={!states.length || regionLoading}
                title={regionLoading ? "Loading..." : "All States"}
                options={[
                  optionAll,
                  ...states.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={state}
                onSelect={setState}
              />
            </div>

            <div className="w-full md:w-1/3">
              <HeadlessSelectObject
                disable={!cities.length || regionLoading}
                title={regionLoading ? "Loading..." : "All Cities"}
                options={[
                  optionAll,
                  ...cities.map((t) => ({ name: t.name, value: t.id })),
                ]}
                selected={city}
                onSelect={setCity}
              />
            </div>
          </div>

          <div className="flex flex-col justify-between gap-2 md:flex-row">
            <div className="w-full  md:w-1/2">
              <input
                required
                type="text"
                name="orderNo"
                placeholder="Order No"
                value={orderNo}
                onChange={(e) => setOrderNo(e.target.value)}
                className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
              />
            </div>

            <div className="flex w-full items-center justify-between gap-2 rounded-md border border-gray-300 p-1 md:w-1/2">
              <input
                className="text-sm"
                type="date"
                value={fromDate}
                onChange={(e) => setFromDAte(e.target.value)}
              />
              <p className="text-lg font-bold">-</p>
              <input
                className="text-sm"
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      </TransactionsTableCard>
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={transactionsCount}
      />
    </div>
  );
};

export default Transactions;
