import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getStoreCategories = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_STORE_CATEGORIES}?page=${page}&limit=${limit}`
  );
};
const getStoreCategory = (categoryId) => {
  return RestClient.Get(`${API_URL.GET_STORE_CATEGORY}/${categoryId}`);
};

const createStoreCategory = (payload) => {
  return RestClient.Post(API_URL.CREATE_STORE_CATEGORY, payload);
};

const updateStoreCategory = (categoryId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_STORE_CATEGORY}/${categoryId}`,
    payload
  );
};

const updateMultiLangStoreCategory = (categoryId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MULTI_LANG_STORE_CATEGORY}/${categoryId}`,
    payload
  );
};

const deleteStoreCategory = (categoryId) => {
  return RestClient.Delete(`${API_URL.DELETE_STORE_CATEGORY}/${categoryId}`);
};

const exportedObject = {
  getStoreCategories,
  getStoreCategory,
  createStoreCategory,
  updateStoreCategory,
  updateMultiLangStoreCategory,
  deleteStoreCategory,
};

export default exportedObject;
