export const MODELS = {
  CUSTOMER: "Customer",
  RESET_PASSWORD: "ResetPassword",
  COUNTRY: "Country",
  CITY: "City",
  STATE: "State",
  AREA: "Area",
  OTP: "Otp",
  CURRENCY: "Currency",
  TRANSACTION_SOURCE: "TransactionSource",
  CUSTOMER_TYPE: "CustomerType",
  CUSTOMER_BALANCE: "CustomerBalance",
  MERCHANT_TYPE: "MerchantType",
  MERCHANT_TAG: "MerchantTag",
  MERCHANT: "Merchant",
  BRAND: "Brand",
  MERCHANT_CATEGORY: "MerchantCategory",
  MERCHANT_PRODUCT: "MerchantProduct",
  REFERRAL_CODE: "ReferralCode",
  FAQ: "FAQ",
  CATEGORY: "Category",
  TICKET: "Ticket",
  CUSTOMER_TRANSACTION: "CustomerTransaction",
  NOTIFICATION: "Notification",
  RATING: "Rating",
  SLIDER: "Slider",
  ORDER: "Order",
  USER: "User",
  LANGUAGE: "Language",
  LOCALIZATION: "Localization",
  SYSTEM_LOCALIZATION: "SystemLocalization",
  CUISINE_TYPE: "CuisineType",
  CUSTOMER_CLASSIFICATION: "CustomerClassification",
  ROLE: "Role",
  STORE_LOYALTY: "StoreLoyalty",
  MEMBERSHIP_CLAIM: "MembershipClaim",
  MERCHANT_PROMOTION_TAG: "MerchantPromotionTag",
  CRYPTO_CURRENCY: "CryptoCurrency",
  THIRD_PARTY_INTEGRATION: "ThirdPartyIntegration",
  FOODICS_REWARD: "FoodicsReward",
  MERCHANT_VIBE: "MerchantVibe",
  FACILITY: "Facility",
  CART_RULE: "CartRule",
  VIBE_CATEGORY: "VibeCategory",
  PUSH_NOTIFICATION: "PushNotification",
  SETTINGS: "Settings",
  WEBHOOK_DUMP: "WebhookDump",
  HOLDING_SCHEMA: "HoldingSchema",
  ACTIVITY_LOG: "ActivityLog",
  MESSAGE_LOG: "MessageLog",
  USER_SESSION: "UserSession",
  DEVICE: "Device",
  VENDOR_CUSTOMER_SESSION: "VendorCustomerSession",
  STORE: "Store",
  STORE_CATEGORY: "StoreCategory",
  STORE_TAG: "StoreTag",
};
