import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import ClaimsService from "../../services/membership-claims/ClaimsService";
import * as ActionTypes from "../actionTypes";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CLAIMS_LOADING,
    payload: loading,
  });
};

export const setClaims = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CLAIMS,
    payload: data,
  });
};

export const resetClaimsState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_CLAIMS_STATE,
  });
};

export const getClaims = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await ClaimsService.getClaims(page, limit);
    if (response.status == 1 && response.data) {
      dispatch(setClaims(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load claims", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
