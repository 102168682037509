import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getStoreTags = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_STORE_TAGS}?page=${page}&limit=${limit}`
  );
};
const getStoreTag = (tagId) => {
  return RestClient.Get(`${API_URL.GET_STORE_TAG}/${tagId}`);
};

const createStoreTag = (payload) => {
  return RestClient.Post(API_URL.CREATE_STORE_TAG, payload);
};

const updateStoreTag = (tagId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_STORE_TAG}/${tagId}`, payload);
};

const updateMultiLangStoreTag = (tagId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MULTI_LANG_STORE_TAG}/${tagId}`,
    payload
  );
};

const deleteStoreTag = (tagId) => {
  return RestClient.Delete(`${API_URL.DELETE_STORE_TAG}/${tagId}`);
};

const exportedObject = {
  getStoreTags,
  getStoreTag,
  createStoreTag,
  updateStoreTag,
  updateMultiLangStoreTag,
  deleteStoreTag,
};

export default exportedObject;
