import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import LoadingPage from "pages/LoadingPage";
import { loadAuthToken } from "actions/auth/auth.actions";
import AppAlerts from "views/admin/components/appAlerts";

const App = () => {
  useEffect(() => store.dispatch(loadAuthToken()), []);

  return (
    <Provider store={store}>
      {/* <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="/*" element={<AdminLayout />} />
        <Route path="rtl/*" element={<RtlLayout />} />
      </Routes> */}
      <LoadingPage />
      <AppAlerts />
    </Provider>
  );
};

export default App;
