import { createFaq } from "actions/faqs/faqs.action";
import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import CurencyForm from "./currencyForm";
import { createCurrency } from "actions/currency/currency.action";

const CreateCurrency = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <CurencyForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createCurrency(formData,navigate))
        }
      />
    </div>
  );
};

export default CreateCurrency;
