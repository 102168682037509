import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getCategories = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_CATEGORIES}?page=${page}&limit=${limit}`
  );
};
const getCategory = (categoryId) => {
  return RestClient.Get(`${API_URL.GET_CATEGORY}/${categoryId}`);
};

const createCategory = (payload) => {
  return RestClient.Post(API_URL.CREATE_CATEGORY, payload);
};

const updateCategory = (categoryId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_CATEGORY}/${categoryId}`, payload);
};

const updateMultiLangCategory = (categoryId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MULTI_LANG_CATEGORY}/${categoryId}`,
    payload
  );
};

const deleteCategory = (categoryId) => {
  return RestClient.Delete(`${API_URL.DELETE_CATEGORY}/${categoryId}`);
};

const exportedObject = {
  getCategories,
  getCategory,
  createCategory,
  updateCategory,
  updateMultiLangCategory,
  deleteCategory,
};

export default exportedObject;
