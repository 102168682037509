import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getPushNotifications = (page = 1, limit = 50) => {
  return RestClient.Get(
    `${API_URL.GET_PUSH_NOTIFICATIONS}?page=${page}&limit=${limit}`
  );
};

const createPushNotification = (payload) => {
  return RestClient.Post(API_URL.CREATE_PUSH_NOTIFICATION, payload);
};

const exportedObject = {
  getPushNotifications,
  createPushNotification,
};

export default exportedObject;
