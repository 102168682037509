import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import * as ActionTypes from "../actionTypes";
import StoreService from "../../services/store/StoreService";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORE_LOADING,
    payload: loading,
  });
};

export const setStores = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORES,
    payload: data,
  });
};

export const setCampaigns = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_CAMPAIGNS,
    payload: data,
  });
};

export const setStore = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_STORE,
    payload: data,
  });
};

export const editStore = (store) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_STORE,
    payload: store,
  });
};

export const removeStore = (categoryId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_STORE,
    payload: categoryId,
  });
};

export const resetStoreState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_STORE_STATE,
  });
};

export const getStores = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreService.getStores(page, limit);
    if (response.status == 1) {
      dispatch(setStores(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Stores", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getStoreCompaigns = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreService.getStoreCompaigns();
    if (response) {
      dispatch(setCampaigns(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Stores", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getStore = (categoryId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreService.getStore(categoryId);
    if (response.data && response.status == 1) {
      dispatch(setStore(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load Category", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createStore = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreService.createStore(payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "store created successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/stores");
    }
    dispatch(
      setAlert(response.message ?? "failed to create store", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateStore =
  (categoryId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await StoreService.updateStore(categoryId, payload);
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "store updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/stores");
      }
      dispatch(
        setAlert(response.message ?? "failed to update store", AlertTypes.ERROR)
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updateMultiLangStore =
  (categoryId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await StoreService.updateMultiLangStore(
        categoryId,
        payload
      );
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "store updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/stores");
      }
      dispatch(
        setAlert(response.message ?? "failed to update store", AlertTypes.ERROR)
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteStore = (categoryId, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await StoreService.deleteStore(categoryId);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "store deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeStore(categoryId));
      if (!navigate) return;
      return navigate("/stores");
    }
    dispatch(
      setAlert(response.message ?? "failed to delete store", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
