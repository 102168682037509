import { setAlert } from "../alert/alert.actions";
import { AlertTypes } from "../../reducers/alert/alert.reducer";
import UserService from "../../services/users/UserService";
import * as ActionTypes from "../actionTypes";

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_USER_LOADING,
    payload: loading,
  });
};

export const setUsers = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_USERS,
    payload: data,
  });
};

export const setUser = (user) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_USER,
    payload: user,
  });
};

export const removeUser = (userId) => (dispatch) => {
  dispatch({
    type: ActionTypes.DELETE_USER,
    payload: userId,
  });
};

export const resetUserState = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_USER_STATE,
  });
};

export const getUsers = (page, limit) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await UserService.getUsers(page, limit);
    if (response.status == 1 && response.data) {
      dispatch(setUsers(response.data));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load users", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getUser = (userId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await UserService.getUser(userId);
    if (response.status == 1 && response.data) {
      dispatch(setUser(response.data.user));
      return;
    }
    dispatch(
      setAlert(response.message ?? "failed to load user", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createUser = (data, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await UserService.createUser(data);
    if (response.status == 1 && response.data) {
      dispatch(
        setAlert(
          response.message || "User created Successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/users");
    }
    dispatch(
      setAlert(response.message ?? "User creation failed", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("something went wrong", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateUser = (userId, payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await UserService.updateUser(userId, payload);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "user updated successfully",
          AlertTypes.SUCCESS
        )
      );
      if (!navigate) return;
      return navigate("/users");
    }
    dispatch(
      setAlert(response.message ?? "failed to update user", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateUserPassword =
  (userId, payload, navigate) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await UserService.updateUserPassword(userId, payload);
      if (response.status == 1) {
        dispatch(
          setAlert(
            response.message ?? "user password updated successfully",
            AlertTypes.SUCCESS
          )
        );
        if (!navigate) return;
        return navigate("/users");
      }
      dispatch(
        setAlert(
          response.message ?? "failed to update user password",
          AlertTypes.ERROR
        )
      );
    } catch (error) {
      dispatch(setAlert("server error", AlertTypes.ERROR));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteUser = (userId) => async (dispatch, navigate) => {
  try {
    dispatch(setLoading(true));
    const response = await UserService.deleteUser(userId);
    if (response.status == 1) {
      dispatch(
        setAlert(
          response.message ?? "user deleted successfully",
          AlertTypes.SUCCESS
        )
      );
      dispatch(removeUser(userId));
      if (!navigate) return;
      return navigate("/users");
    }
    dispatch(
      setAlert(response.message ?? "failed to delete user", AlertTypes.ERROR)
    );
  } catch (error) {
    dispatch(setAlert("server error", AlertTypes.ERROR));
  } finally {
    dispatch(setLoading(false));
  }
};
