import { getFingerpeintDeviceId } from "utils/App.utils";
import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  token: null,
  user: null,
  deviceId: await getFingerpeintDeviceId(),
  loading: false,
};

export const authReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        token: payload,
        loading: false,
      };
    case ActionTypes.SET_AUTH_USER:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case ActionTypes.SET_DEVICE_ID:
      return {
        ...state,
        deviceId: payload,
        loading: false,
      };
    case ActionTypes.SET_AUTH_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_AUTH_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
