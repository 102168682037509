import { setAlert } from "actions/alert/alert.actions";
import { deleteMerchant } from "actions/merchants/merchants.actions";
import Card from "components/card";
import { PERMISSION_ACTION_TYPES, PERSMISSIONS_TYPES } from "constants/role.constants";
import { useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertTypes } from "reducers/alert/alert.reducer";
import { hasPermission } from "utils/permissions";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import ToggleSwitch from "../components/toggleSwitch";
import { publishMerchant } from "actions/merchants/merchants.actions";

function MerchantsTableCard({ merchants, children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDelModal, setShowDelModal] = useState(false);
  const [deleteMerchantId, setDeleteMerchantId] = useState(null);

  const handleCancelDelete = () => {
    setShowDelModal(false);
    setDeleteMerchantId(null);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteMerchant(deleteMerchantId));
    handleCancelDelete();
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(setAlert("link copied", AlertTypes.SUCCESS));
  };
  const handlePublish = (merchant_id) => {
    dispatch(publishMerchant(merchant_id));
  };

  const tableHeaders = [
    <div className="flex w-full items-center gap-2 ">
      <div className=" h-10 w-10 " />
      <p className="w-auto">{"Name"}</p>
    </div>,
    "Code",
    "Email",
    "Phone",
    "Country",
    "State",
    "City",
    "Foodics",
    "Created By",
    "Publish",
    "Actions",
  ];
  const records = merchants.map((merchant) => ({
    logo: (
      <div className="flex w-full items-center gap-2">
        <div className=" h-10 w-10 ">
          <img
            className="h-9 w-9 rounded-full"
            src={
              merchant.logo !== null && merchant.logo !== undefined
                ? merchant.logo
                : ""
            }
            alt=""
          />
        </div>
        <p className="w-auto"> {merchant.name.split(" ").join("\u00A0")}</p>
      </div>
    ),
    code: merchant.unique_code,
    email: merchant.contact_info?.email,
    phone: merchant.contact_info?.phone,
    country: merchant.country_id?.name,
    state: merchant.state_id?.name,
    city: merchant.city_id?.name,
    is_connected_foodics: merchant.is_connected_foodics ? (
      <span className="inline-block text-nowrap rounded-full bg-green-500 px-2 py-1 text-sm font-bold text-white">
        Connected
      </span>
    ) : (
      <button
        onClick={() => handleCopy(merchant.foodics_integration_link)}
        className={
          "flex items-center gap-2 text-nowrap rounded-full bg-blue-500 px-2 py-1 text-sm font-bold text-white hover:bg-blue-600"
        }
      >
        Connection <IoCopyOutline className="h-4 w-4 hover:font-semibold" />
      </button>
    ),
    created_by: merchant?.created_by?.name ?? "",
    publish: (
      <ToggleSwitch
        isEnabled={merchant?.is_published ?? false}
        handleChange={() => {
          handlePublish(merchant.id);
        }}
      />
    ),
    actions: (
      <div className="flex items-center gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
            onClick={() => navigate(`/merchants/update/${merchant.id}`)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {/* {hasPermission(
          [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
            onClick={() => onClickDelete(merchant.id)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null} */}
      </div>
    ),
  }));

  return (
    <div>
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the merchant with email{" "}
              {
                merchants.find((merchant) => merchant.id === deleteMerchantId)
                  .contact_info.email
              }
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <Card extra={" bg-white"}>
        <CardHeader
          label={"Merchants"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                  {
                    text: "Add New",
                    onClick: () => navigate("/merchants/create"),
                  variant: "primary",
                  },
                ]
              : null
          }
        />
        {children}

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default MerchantsTableCard;
