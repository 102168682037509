import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  settingsCount: 0,
  settings: [],
  systemSettings: null,
  setting: null,
  // sliderMultiLang: {},
  loading: false,
};

export const settingsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_SETTINGS:
      return {
        ...state,
        settingsCount: payload?.meta?.total_documents || 0,
        settings: payload.settings,
        loading: false,
      };
    case ActionTypes.UPDATE_SETTINGS:
      return {
        ...state,
        settings: state.settings.map((setting) =>
          setting.id === payload.id ? payload : setting
        ),
        loading: false,
      };
    case ActionTypes.SET_SYSTEM_SETTINGS:
      return {
        ...state,
        systemSettings: payload.systemSettings,
        loading: false,
      };
    case ActionTypes.SET_SETTING:
      return {
        ...state,
        setting: payload.setting,
        loading: false,
      };
    case ActionTypes.DELETE_SETTING:
      return {
        ...state,
        settingsCount: state.settingsCount - 1,
        settings: state.settings.filter((setting) => setting.id !== payload),
        loading: false,
      };
    case ActionTypes.SET_SETTING_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_SETTING_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
