import { Transition } from "@headlessui/react";
import { MdCheck, MdWarningAmber, MdDangerous } from "react-icons/md";
import { Fragment } from "react";
import { useSelector } from "react-redux";
// import { AlertTypes } from "store/alert/alert.reducer";
import { AlertTypes } from "../../../reducers/alert/alert.reducer";

export default function AppAlerts() {
  const alerts = useSelector((state) => state.alerts);

  return (
    <div
      aria-live="assertive"
      className="z-100 pointer-events-none fixed inset-0 mb-14 flex items-end px-4 py-6 sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-3 sm:items-end">
        <Transition
          show={!!alerts.length}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="flex flex-col gap-2">
            {alerts.map((alert) => (
              <div
                key={alert.id}
                className={`
                  w-full max-w-sm shadow-sm ring-1
                  ${
                    alert.alertType === AlertTypes.SUCCESS
                      ? "bg-green-100 shadow-green-200 ring-green-200"
                      : alert.alertType === AlertTypes.ERROR
                      ? "bg-red-100 shadow-red-200 ring-red-200"
                      : alert.alertType === AlertTypes.WARNING
                      ? "bg-yellow-100 shadow-yellow-200 ring-yellow-200"
                      : "bg-indigo-100 shadow-indigo-200 ring-indigo-200"
                  }
                  pointer-events-auto overflow-hidden rounded-lg
                `}
              >
                <div className="flex items-start px-3 py-2">
                  <div className="flex-shrink-0">
                    {alert.alertType === AlertTypes.SUCCESS ? (
                      <MdCheck
                        className="size-7 text-green-500"
                        aria-hidden="true"
                      />
                    ) : alert.alertType === AlertTypes.ERROR ? (
                      <MdWarningAmber
                        className="size-7 text-red-500"
                        aria-hidden="true"
                      />
                    ) : alert.alertType === AlertTypes.WARNING ? (
                      <MdDangerous
                        className="size-7 text-yellow-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <MdDangerous
                        className="size-7 text-indigo-500"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-3 flex-1 pt-0.5">
                    <p className="text-sm text-gray-800">{alert.message}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Transition>
      </div>
    </div>
  );
}
