import { getBrandMerchants } from "actions/merchants/merchants.actions";
import { ACCOUNT_TYPE, FORM_TYPE } from "constants/app.constants";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { submitFormHandler } from "utils/App.utils";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import FormInputField from "../components/formInputField";
import UpdateDevicePIN from "../components/updateDevicePIN";
import { updateDevicePin } from "actions/devices/devices.action";
import { getBrands } from "actions/Brands/brands.action";
import { getMerchants } from "actions/merchants/merchants.actions";
import HeadlessSelect from "../components/headless/select.headless";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

const initialDeviceData = {
  brand_id: "",
  merchant_id: "",
  name: "",
  pin: "",
  enable: true,
  is_not_integrated: false,
};

const DeviceForm = ({ formType, initialFormData, handleFormSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { merchants, loading } = useSelector((state) => state.merchant);
  const { brands } = useSelector((state) => state.brand);

  // local-state
  const [deviceData, setDeviceData] = useState(initialDeviceData);
  const formRef = useRef(null);
  const shouldRedirect = useRef(true);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showUpdatePINPopup, setShowUpdatePINPopup] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    setDeviceData((prevData) => ({
      ...prevData,
      ...initialFormData,
    }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getBrands(1, 1000));
  }, []);

  useEffect(() => {
    if (deviceData.brand_id) {
      dispatch(
        getMerchants(
          1,
          1000,
          null,
          null,
          null,
          null,
          null,
          null,
          deviceData?.brand_id
        )
      );
    }
  }, [deviceData.brand_id]);

  // submit-handler
  const handleSubmit = (e) => {
    e?.preventDefault(e);

    let payload = { ...deviceData };

    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialDeviceData[key] == undefined) {
        delete payload[key];
      }
    });

    if (formType === FORM_TYPE.UPDATE) {
      delete payload.pin;
    }

    // const fromData = convertToFormData(payload);
    handleFormSubmit(payload, shouldRedirect.current);
    shouldRedirect.current = true;
  };

  const onChangeFormData = ({ name, value }) => {
    setDeviceData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="h-full w-full bg-white">
      {" "}
      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.DEVICE}
          entityId={deviceData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/devices")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to Devices
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}
      {showUpdatePINPopup ? (
        <UpdateDevicePIN
          handleSubmit={({ pin, confirmPin }) => {
            dispatch(
              updateDevicePin(deviceData.id, {
                pin,
                confirmPin,
              })
            );
          }}
          handleCancel={() => setShowUpdatePINPopup(false)}
        />
      ) : null}
      <CardHeader
        label={`${formType} Device`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/devices"),
            variant: "dark",
          },
          {
            text: "Save",
            onClick: () => {
              submitFormHandler(formRef.current);
            },
            variant: "secondary",
          },
          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Update PIN",
                  onClick: () => setShowUpdatePINPopup(true),
                  variant: "primary",
                },
                {
                  text: "Save & Continue Edit",
                  onClick: () => {
                    shouldRedirect.current = false;
                    submitFormHandler(formRef.current);
                  },
                  variant: "secondary",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      <div className="flex flex-col gap-3 p-4 ">
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 "
        >
          <div className={`flex flex-col justify-between gap-2`}>
            {/* Brands */}
            <div className="flex flex-auto flex-col items-start gap-1">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Brand
              </label>
              {formType === FORM_TYPE.UPDATE ? (
                <div className="w-full flex-wrap py-1 text-base font-medium leading-5 text-gray-500">
                  {/* {deviceData.brand_id} */}
                  {
                    brands.find((brand) => brand.id === deviceData.brand_id)
                      ?.name
                  }
                </div>
              ) : (
                <HeadlessSelectObject
                  title={!brands.length ? "NO Brand Found" : "Select Brand"}
                  disable={!brands.length}
                  options={brands.map((brand) => ({
                    name: brand.name,
                    value: brand.id,
                  }))}
                  selected={deviceData.brand_id}
                  onSelect={(value) =>
                    onChangeFormData({ name: "brand_id", value })
                  }
                />
              )}
            </div>

            {/* merchant */}
            <div className="flex flex-auto flex-col items-start gap-1">
              <label className="mb-1 block text-base font-medium text-gray-800">
                Merchant
              </label>
              {formType === FORM_TYPE.UPDATE ? (
                <div className="w-full flex-wrap py-1 text-base font-medium leading-5 text-gray-500">
                  {
                    merchants.find(
                      (merch) => merch.id === deviceData.merchant_id
                    )?.name
                  }
                </div>
              ) : (
                <HeadlessSelectObject
                  title={
                    !merchants.length ? "NO Branch Found" : "Select Branch"
                  }
                  disable={!merchants.length}
                  options={merchants.map((merchant) => ({
                    name: merchant.name,
                    value: merchant.id,
                  }))}
                  selected={deviceData.merchant_id}
                  onSelect={(value) =>
                    onChangeFormData({ name: "merchant_id", value })
                  }
                />
              )}
            </div>

            {/* name */}
            <FormInputField
              label="Name"
              type="text"
              name="name"
              placeholder="Device Name"
              value={deviceData.name}
              onChange={(e) =>
                onChangeFormData({
                  name: "name",
                  value: e.target.value,
                })
              }
            />

            {/*only in the case of CREATE*/}
            {formType === FORM_TYPE.CREATE ? (
              <FormInputField
                label="Device PIN"
                type="password"
                name="pin"
                placeholder="XXXX"
                value={deviceData.pin}
                onChange={(e) =>
                  onChangeFormData({
                    name: "pin",
                    value: e.target.value,
                  })
                }
              />
            ) : null}

            <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
              <div className="flex w-full flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Enable Status
                </label>
                <HeadlessSelect
                  title={"Select Status"}
                  options={["Enable", "Disable"]}
                  selected={deviceData.enable ? "Enable" : "Disable"}
                  onSelect={(value) =>
                    onChangeFormData({
                      name: "enable",
                      value: value === "Enable",
                    })
                  }
                />
              </div>
              <div className="flex w-full flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Integration Status
                </label>
                <HeadlessSelect
                  title={"Select Status"}
                  options={["Integrated", "Not Integrated"]}
                  selected={
                    deviceData.is_not_integrated
                      ? "Not Integrated"
                      : "Integrated"
                  }
                  onSelect={(value) =>
                    onChangeFormData({
                      name: "is_not_integrated",
                      value: value !== "Integrated",
                    })
                  }
                />
              </div>
            </div>
          </div>

          {/* buttons */}
          <div className="flex justify-between">
            <button
              disabled={loading}
              type="submit"
              className={`
          rounded-md px-6 py-2 font-semibold text-white focus:outline-none
          ${
            loading
              ? "bg-indigo-400 hover:bg-indigo-400"
              : "bg-indigo-500 hover:bg-indigo-600"
          }
          `}
            >
              {loading ? "Saving..." : "Save"}
            </button>
            <button
              type="button"
              onClick={() => setShowCancelModal(true)}
              className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeviceForm;
