import Card from "components/card";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import ProfileIcon from "../../../assets/img/profile-icon.png";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import { FaArrowRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { formatLocalTimeDate } from "utils/App.utils";
import ToggleSwitch from "../components/toggleSwitch";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCustomer } from "actions/customers/customers.actions";
import { truncateText } from "utils/App.utils";

function CustomersTableCard({ customers, children, loading }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleToggle = (customer_id, updatedFlags) => {
    dispatch(updateCustomer(customer_id, updatedFlags));
  };

  const tableHeaders = [
    <div className="flex items-center gap-2">
      <div className=" h-10 w-10 " />
      {"Name"}
    </div>,
    // "Email",
    "Email Status",
    "Phone",
    "Phone Status",
    "Gender",
    "Created at",
    "Disable NFT",
    "Disable Earning",
    "Disable Redeem",
    "Action",
  ];
  const records = customers.map((customer) => ({
    name: (
      <Link
        to={`/customer/profile/${customer.id}`}
        className="flex items-center gap-2"
      >
        <div className=" h-10 w-10 ">
          <img
            className="h-9 w-9 cursor-pointer rounded-full"
            src={
              customer.profile_pic !== null &&
              customer.profile_pic !== undefined
                ? customer.profile_pic
                : ProfileIcon
            }
            alt=""
          />
        </div>
        {truncateText(
          customer.name ? customer.name.split(" ").join("\u00A0") : "",
          20
        )}
      </Link>
    ),
    // email: customer.email !== null ? customer.email : "N/A",
    email_verified: customer.email_verified ? (
      <div className="flex items-center gap-2">
        <div className={`rounded-full text-xl`}>
          <MdCheckCircle className="text-green-500" />
        </div>
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          Verified
        </p>
      </div>
    ) : (
      <div className="flex items-center gap-2">
        <div className={`rounded-full text-xl`}>
          <MdCancel className="text-red-500" />
        </div>
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          Not&nbsp;Verified
        </p>
      </div>
    ),
    phone_number: customer.phone_number?.code
      ? customer.phone_number?.code + customer.phone_number?.number
      : customer.phone_number,
    phone_number_verified: customer.phone_number_verified ? (
      <div className="flex items-center gap-2">
        <div className={`rounded-full text-xl`}>
          <MdCheckCircle className="text-green-500" />
        </div>
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          Verified
        </p>
      </div>
    ) : (
      <div className="flex items-center gap-2">
        <div className={`rounded-full text-xl`}>
          <MdCancel className="text-red-500" />
        </div>
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          Not&nbsp;Verified
        </p>
      </div>
    ),
    gender: customer.gender ?? "NA",
    created_at: formatLocalTimeDate(customer.created_at),
    NFT: (
      <div className="flex justify-center">
        <ToggleSwitch
          disabled={loading}
          isEnabled={customer?.disable_nft ?? false}
          handleChange={() =>
            handleToggle(customer.id, { disable_nft: !customer?.disable_nft })
          }
        />
      </div>
    ),
    Earning: (
      <div className="flex justify-center">
        <ToggleSwitch
          disabled={loading}
          isEnabled={customer?.disable_earning ?? false}
          handleChange={() =>
            handleToggle(customer.id, {
              disable_earning: !customer?.disable_earning,
            })
          }
        />
      </div>
    ),
    redemption: (
      <div className="flex justify-center">
        <ToggleSwitch
          disabled={loading}
          isEnabled={customer?.disable_redemption ?? false}
          handleChange={() =>
            handleToggle(customer.id, {
              disable_redemption: !customer?.disable_redemption,
            })
          }
        />
      </div>
    ),
    actions: (
      <div className="flex items-center justify-between gap-2">
        <button
          onClick={() => navigate(`/customer/profile/${customer.id}`)}
          className={`
          cursor-pointer' flex h-8 items-center justify-center gap-1 rounded-lg border border-gray-300 bg-white px-3
           text-sm font-medium text-gray-500 hover:text-gray-700
          `}
        >
          <span>View&nbsp;Profile</span>
          <FaArrowRight className="h-3.5 w-3.5" />
        </button>
      </div>
    ),
  }));

  return (
    <div>
      <Card extra={" bg-white"}>
        <CardHeader label={"Customers"} />
        {children}

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default CustomersTableCard;
