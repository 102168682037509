import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";


const getStores = (page = 1, limit = 50) => {
  return RestClient.Get(`${API_URL.GET_STORES}?page=${page}&limit=${limit}`);
};
const getStore = (storeId) => {
  return RestClient.Get(`${API_URL.GET_STORE}/${storeId}`);
};

const getStoreCompaigns = () => {
  return RestClient.Get(`${API_URL.GET_COMPAINGNS}`);
};

const createStore = (payload) => {
  return RestClient.Post(API_URL.CREATE_STORE, payload);
};

const updateStore = (storeId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_STORE}/${storeId}`, payload);
};

const updateMultiLangStore = (storeId, payload) => {
  return RestClient.Put(
    `${API_URL.UPDATE_MULTI_LANG_STORE}/${storeId}`,
    payload
  );
};

const deleteStore = (storeId) => {
  return RestClient.Delete(`${API_URL.DELETE_STORE}/${storeId}`);
};

const exportedObject = {
  getStores,
  getStoreCompaigns,
  getStore,
  createStore,
  updateStore,
  updateMultiLangStore,
  deleteStore,
};

export default exportedObject;
