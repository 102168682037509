import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "actions/categories/categories.actions";
import CategoriesTableCard from "./categories-table-card";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";

const Categories = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;

  const dispatch = useDispatch();
  const { categoriesCount, categories } = useSelector(
    (state) => state.category
  );

  useEffect(() => {
    dispatch(getCategories(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <CategoriesTableCard categories={categories} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={categoriesCount}
      />
    </div>
  );
};

export default Categories;
