import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getSettings = (page = 1, limit = 50) => {
  return RestClient.Get(`${API_URL.GET_SETTINGS}?page=${page}&limit=${limit}`);
};

const getSystemSettings = () => {
  return RestClient.Get(`${API_URL.GET_SYSTEM_SETTINGS}`);
};

const getSetting = (setting_id) => {
  return RestClient.Get(`${API_URL.GET_SETTING}/${setting_id}`);
};

const createSetting = (payload) => {
  return RestClient.Post(API_URL.CREATE_SETTING, payload);
};

const updateSetting = (setting_id, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_SETTING}/${setting_id}`, payload);
};

const deleteSetting = (setting_id) => {
  return RestClient.Delete(`${API_URL.DELETE_SETTING}/${setting_id}`);
};

const exportedObject = {
  getSettings,
  getSystemSettings,
  getSetting,
  createSetting,
  updateSetting,
  deleteSetting,
};

export default exportedObject;
