import { getMerchantTag, updateLanguageMerchantTag, updateMerchantTag } from "actions/merchants/merchants.actions";
import { FORM_TYPE } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MerchantTagForm from "./merchantTagForm";
import Loader from "components/loaderSpinner/Loader";
import { deleteMerchantTag } from "actions/merchants/merchants.actions";
import ConfirmationModal from "../components/ConfirmationModal";

const UpdateMerchantTag = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { merchantTagId } = useParams();
  const { tag } = useSelector((state) => state.merchant);

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = (tag) => {
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteMerchantTag(merchantTagId, navigate));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
  };

  useEffect(() => {
    dispatch(getMerchantTag(merchantTagId));
  }, [merchantTagId]);

  const handleSubmit = (formData, redirect) => {
    if (formData.get("lang_id")) {
      dispatch(
        updateLanguageMerchantTag(
          merchantTagId,
          formData,
          redirect === false ? null : navigate
        )
      );
      return;
    }
    dispatch(
      updateMerchantTag(merchantTagId, formData, redirect === false ? null : navigate)
    );
  };

  if (!tag || tag.id !== merchantTagId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );
  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>You are about to delete the "{tag.name}" merchant tag</h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <MerchantTagForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={tag}
        handleFormSubmit={handleSubmit}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateMerchantTag;
