import { createVibe } from "actions/merchants/merchants.actions";
import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import VibeForm from "./vibeForm";

const CreateVibe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <VibeForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createVibe(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateVibe;
