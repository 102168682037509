import { toggleRulePublishStatus } from "actions/cartRules/cartRules.action";
import Card from "components/card";
import { PERMISSION_ACTION_TYPES, PERSMISSIONS_TYPES } from "constants/role.constants";
import dayjs from "dayjs";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { hasPermission } from "utils/permissions";
import CardHeader from "../components/cardHeader";
import Table from "../components/table";
import ToggleSwitch from "../components/toggleSwitch";

function CartRulesTableCard({
  rules,
  onClickAddNew,
  onClickEdit,
  onClickDelete,
}) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.rule);
  const tableHeaders = [
    "Name",
    "Type",
    "Value",
    "Valid From",
    "Valid Till",
    "Publish",
    "Action",
  ];
  const records = rules.map((rule) => ({
    name: rule.name,
    type: rule.action.type,
    value: rule.action.value,
    start_date: dayjs(rule.conditions.start_date).format("MMM DD, YYYY"),
    end_date: dayjs(rule.conditions.end_date).format("MMM DD, YYYY"),
    publish: (
      <div className="flex justify-center">
        <ToggleSwitch
          disabled={loading}
          isEnabled={!!rule.is_published}
          handleChange={() => dispatch(toggleRulePublishStatus(rule.id))}
        />
      </div>
    ),
    actions: (
      <div className="flex items-center  gap-2">
        {hasPermission(
          [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
          PERMISSION_ACTION_TYPES.EDITOR_ACCESS
        ) ? (
          <MdOutlineEdit
              onClick={() => onClickEdit(rule.id)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-yellow-300"
          />
        ) : null}
        {hasPermission(
          [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
          PERMISSION_ACTION_TYPES.FULL_ACCESS
        ) ? (
          <MdOutlineDelete
              onClick={() => onClickDelete(rule)}
            className="h-4 w-4 hover:h-5 hover:w-5 hover:text-red-500"
          />
        ) : null}
      </div>
    ),
  }));

  return (
    <div>
      <Card extra={"h-full  w-full bg-white"}>
        <CardHeader
          label={"Earning Rules"}
          buttons={
            hasPermission(
              [PERSMISSIONS_TYPES.MERCHANT_MANAGEMENT],
              PERMISSION_ACTION_TYPES.FULL_ACCESS
            )
              ? [
                {
                  text: "Add New",
                  onClick: onClickAddNew,
                  variant: "primary",
                },
              ]
              : null
          }
        />

        <Table
          tableData={{
            headers: tableHeaders,
            records,
          }}
        />
      </Card>
    </div>
  );
}

export default CartRulesTableCard;
