
const SocialLinks = ({ merchantData, onChangeFormData }) => {

  const onChangeSocialProfile = (e) => {
    const { name, value } = e.target;
    let social_profiles = { ...merchantData.social_profiles };
    social_profiles[name] = value;
    onChangeFormData({ name: "social_profiles", value: social_profiles });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col justify-between gap-2 md:flex-row">
        <div className="w-full">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Facebook
          </label>
          <input
            // required
            type="url"
            name="facebook"
            placeholder="https://facebook.com/"
            value={merchantData.social_profiles.facebook}
            onChange={onChangeSocialProfile}
            className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
          />
        </div>
        <div className="w-full">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Instagram
          </label>
          <input
            // required
            type="url"
            name="instagram"
            placeholder="https://instagram.com/"
            value={merchantData.social_profiles.instagram}
            onChange={onChangeSocialProfile}
            className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
          />
        </div>
        <div className="w-full">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Twitter
          </label>
          <input
            // required
            type="url"
            name="twitter"
            placeholder="https://twitter.com/"
            value={merchantData.social_profiles.twitter}
            onChange={onChangeSocialProfile}
            className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
          />
        </div>
      </div>
    </div>
  );
};

export default SocialLinks;
