import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  storeLoyaltiesCount: 0,
  storeLoyalties: [],
  storeLoyalty: null,
  storeLoyaltyMultiLang: {},
  loading: false,
};

export const storeLoyaltiesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_STORE_LOYALTIES:
      return {
        ...state,
        storeLoyaltiesCount: payload?.meta?.total_documents || 0,
        storeLoyalties: payload.storeLoyalty,
        loading: false,
      };
    case ActionTypes.SET_STORE_LOYALTY:
      return {
        ...state,
        storeLoyalty: payload.storeLoyalty,
        storeLoyaltyMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_STORE_LOYALTY:
      return {
        ...state,
        storeLoyaltiesCount: state.storeLoyaltiesCount - 1,
        storeLoyalties: state.storeLoyalties.filter(
          (storeLoyalty) => storeLoyalty.id !== payload
        ),
        loading: false,
      };
    case ActionTypes.SET_STORE_LOYALTY_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_STORE_LOYALTY_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
