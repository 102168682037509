import { FORM_TYPE } from "constants/app.constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import DeviceForm from "./deviceForm";
import { createDevice } from "actions/devices/devices.action";

const CreateDevice = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="h-full w-full">
      <DeviceForm
        formType={FORM_TYPE.CREATE}
        handleFormSubmit={(formData) =>
          dispatch(createDevice(formData, navigate))
        }
      />
    </div>
  );
};

export default CreateDevice;
