import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/pagination";
import { getSystemSettings } from "selectors/settings/settings.selector";
import { getStoreCategories } from "actions/storeCategories/storeCategories.actions";
import StoreTagsTableCard from "./store-Tags-table-card";
import { getStoreTags } from "actions/storeTags/storeTags.actions";

const StoreTags = () => {
  const [page, setPage] = useState(1);
  const { default_page_size } = useSelector(getSystemSettings);
  const limit = default_page_size;

  const dispatch = useDispatch();
  const { tagsCount, tags } = useSelector((state) => state.storeTags);

  useEffect(() => {
    dispatch(getStoreTags(page, limit));
  }, [page]);

  return (
    <div className="h-full w-full">
      <StoreTagsTableCard tags={tags} />
      <Pagination
        page={page}
        setPage={setPage}
        limit={limit}
        totalCount={tagsCount}
      />
    </div>
  );
};

export default StoreTags;
