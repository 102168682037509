import { getCities } from "actions/regions/regions.actions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StateCitiesTableCard from "./cities-table-card";

const StateCities = () => {
  const { countryCode, stateCode } = useParams();
  const dispatch = useDispatch();
  const { cities, citiesCount } = useSelector((state) => state.region);

  useEffect(() => {
    dispatch(getCities(countryCode, stateCode));
  }, []);

  return (
    <div className="h-full w-full">
      <StateCitiesTableCard cities={cities} />
    </div>
  );
};

export default StateCities;
