import { setAlert } from "actions/alert/alert.actions";
import { getLanguages } from "actions/languages/languages.actions";
import { FORM_TYPE, PUSH_NOTIFICATION_PLATFORM } from "constants/app.constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertTypes } from "../../../reducers/alert/alert.reducer";
import ConfirmationModal from "../components/ConfirmationModal";
import CardHeader from "../components/cardHeader";
import HeadlessSelect from "../components/headless/select.headless";
import HeadlessSelectObject from "../components/headless/selectObject.headless";
import ActivitylogsModal from "../components/activitylogsModal";
import { MODELS } from "constants/activityLog.constants";

const initialPushNotificationData = {
  title: "",
  body: "",
  platform: PUSH_NOTIFICATION_PLATFORM.BOTH,
  language_id: "",
};

const PushNotificationForm = ({
  formType,
  initialFormData,
  handleFormSubmit,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.pushNotification);
  const { languages, loading: languageLoading } = useSelector(
    (state) => state.language
  );
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [pushNotificationData, setPushNotificationData] = useState(
    initialPushNotificationData
  );
  const optionAll = { name: "All", value: "" };
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    setPushNotificationData((prevData) => ({
      ...prevData,
      ...initialFormData,
    }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getLanguages(1, 1000));
  }, []);

  const handleChangeFormData = (key, value) => {
    setPushNotificationData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    let payload = { ...pushNotificationData };
    const errors = [];

    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialPushNotificationData[key] === undefined) {
        delete payload[key];
      }
    });

    // validate data
    Object.entries(pushNotificationData).forEach(([key, value]) => {
      if (value === "" && key !== "language_id")
        errors.push(`${key} is required`);
    });
    if (!payload.language_id) delete payload.language_id;

    if (errors.length) {
      return errors.forEach((error) =>
        dispatch(setAlert(error, AlertTypes.ERROR))
      );
    }

    handleFormSubmit(payload);
  };

  return (
    <div className="h-full w-full bg-white ">
      {" "}
      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.PUSH_NOTIFICATION}
          entityId={pushNotificationData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/push-notifications")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to Push
              Notifications page.
            </p>
          </div>
        </ConfirmationModal>
      ) : null}
      <CardHeader
        label={`${formType} Push NOtification`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/push-notifications"),
            variant: "dark",
          },
          {
            text: "Send",
            onClick: () => handleSubmit(),
            variant: "secondary",
          },

          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "Save & Continue Edit",
                  onClick: () => handleSubmit(false),
                  variant: "primary",
                },
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      <div className="flex flex-col gap-4 p-4">
        <div className="w-full">
          <label className="mb-1 block text-base font-medium text-gray-800">
            Title
          </label>
          <input
            required
            type="text"
            name="name"
            value={pushNotificationData.title}
            onChange={(e) => handleChangeFormData("title", e.target.value)}
            className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
          />
        </div>
        <div className="w-full">
          <label
            for="content"
            className="mb-1 block text-base font-medium text-gray-800"
          >
            Body
          </label>
          <textarea
            required
            name="body"
            value={pushNotificationData.body}
            onChange={(e) => handleChangeFormData("body", e.target.value)}
            className="w-full rounded-md border border-gray-300 px-2 py-1 focus:border-indigo-500 focus:outline-none"
            rows="3"
          />
        </div>

        <div className="flex flex-col justify-between gap-2 md:flex-row">
          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Platform
            </label>
            <HeadlessSelect
              title={"select rule type"}
              options={Object.values(PUSH_NOTIFICATION_PLATFORM)}
              selected={pushNotificationData.platform}
              onSelect={(value) => handleChangeFormData("platform", value)}
            />
          </div>

          <div className="w-full">
            <label className="mb-1 block text-base font-medium text-gray-800">
              Language
            </label>
            <HeadlessSelectObject
              disable={!languages.length || languageLoading}
              title={languageLoading ? "Loading..." : "All Languages"}
              options={[
                optionAll,
                ...languages.map((t) => ({ name: t.name, value: t.id })),
              ]}
              selected={pushNotificationData.language_id}
              onSelect={(value) => handleChangeFormData("language_id", value)}
            />
          </div>
        </div>

        <div className="flex justify-between">
          <button
            disabled={loading}
            type="button"
            onClick={() => handleSubmit()}
            className={`
                rounded-md px-6 py-2 font-semibold text-white focus:outline-none
                ${
                  loading
                    ? "bg-indigo-400 hover:bg-indigo-400"
                    : "bg-indigo-500 hover:bg-indigo-600"
                }
              `}
          >
            {loading ? "Sending..." : "Send"}
          </button>
          <button
            type="button"
            onClick={() => setShowCancelModal(true)}
            className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PushNotificationForm;
