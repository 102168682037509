import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { updateSlider } from "actions/sliders/sliders.action";
import CardHeader from "../components/cardHeader";
import SliderForm from "./sliderForm";
import { getSlider } from "actions/sliders/sliders.action";
import Loader from "components/loaderSpinner/Loader";
import { FORM_TYPE } from "constants/app.constants";
import { updateMultiLangSlider } from "actions/sliders/sliders.action";
import ConfirmationModal from "../components/ConfirmationModal";
import { deleteSlider } from "actions/sliders/sliders.action";

const UpdateSlider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { sliderId } = useParams();
  const { slider } = useSelector((state) => state.slider);

  const [showDelModal, setShowDelModal] = useState(false);

  const onClickDelete = () => {
    setShowDelModal(true);
  };

  const handleCancelDelete = () => {
    setShowDelModal(false);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteSlider(sliderId, navigate));
    handleCancelDelete();
  };

  useEffect(() => {
    dispatch(getSlider(sliderId));
  }, [sliderId]);

  const handleSubmit = (formData, shouldRedirect) => {
    if (formData.get("lang_id")) {
      dispatch(
        updateMultiLangSlider(
          sliderId,
          formData,
          shouldRedirect ? navigate : null
        )
      );
      return;
    }
    dispatch(
      updateSlider(
        sliderId,
        formData,
        shouldRedirect === false ? null : navigate
      )
    );
  };

  if (!sliderId || slider?.id !== sliderId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the slider with title {slider.title}
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      <SliderForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={slider}
        handleFormSubmit={handleSubmit}
        handleDelete={onClickDelete}
      />
    </div>
  );
};

export default UpdateSlider;
