import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createLanguage,
  deleteLanguage,
  getLanguages,
  updateLanguage,
} from "actions/languages/languages.actions";
import ConfirmationModal from "../components/ConfirmationModal";
import HeadlessSelect from "../components/headless/select.headless";
import LocalizationsTableCard from "./localizations-table-card";
import { getLocalizations } from "actions/localizations/localizations.actions";
import { createLocalization } from "actions/localizations/localizations.actions";
import { updateLocalization } from "actions/localizations/localizations.actions";
import { deleteLocalization } from "actions/localizations/localizations.actions";
import Pagination from "../components/pagination";
import { CiFilter } from "react-icons/ci";
import { MdFilterAltOff } from "react-icons/md";

const initialLocalizationData = {
  key: "",
  value: "",
};

const initialLocalizationFilters = {
  page: 1,
  limit: 50,
  key: "",
  value: "",
}

const Localizations = ({ language_id }) => {
  const dispatch = useDispatch();
  const { localizationsCount, localizations, loading } = useSelector((state) => state.localization);
  const [showDelModal, setShowDelModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [localizationData, setLocalizationData] = useState(
    initialLocalizationData
  );
  const [tool, setTool] = useState(null);
  const [errors, setErrors] = useState([]);
  const [filters, setFilters] = useState(initialLocalizationFilters)

  useEffect(() => {
    if (loading) return
    getFilteredLocalizations();
  }, [filters.page, language_id]);

  const getFilteredLocalizations = (resetPage) => {
    const { page, limit, key, value } = filters
    dispatch(getLocalizations(resetPage ? 1 : page, limit, language_id, key, value));
    if (resetPage) setFilters((prev) => ({ ...prev, page: 1 }))
  }

  const removeAllFilters = () => {
    dispatch(getLocalizations(1, filters.limit, language_id, "", ""))
    setFilters(initialLocalizationFilters)
  }

  const handleOnChangeFilter = (name, value) => {
    setFilters((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setLocalizationData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onClickAddNew = () => {
    setTool("add");
    setShowAddEditModal(true);
  };

  const onClickEdit = (local) => {
    setLocalizationData(local);
    setTool("edit");
    setShowAddEditModal(true);
  };

  const handleConfirmAddEdit = () => {
    const temp_errors = [];
    setErrors(temp_errors);

    const payload = { ...localizationData };
    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialLocalizationData[key] == undefined) {
        delete payload[key];
      }
    });

    // validate date
    Object.entries(payload).forEach(([key, value]) => {
      if (!value) temp_errors.push(`${key} is required`);
    });
    if (temp_errors.length) return setErrors(temp_errors);


    if (tool == "add") {
      dispatch(createLocalization({ ...payload, lang_id: language_id }));
    } else {
      dispatch(updateLocalization(localizationData.id, { ...payload, lang_id: language_id }))
    };
    handleCancel();
  };

  const onClickDelete = (local) => {
    setLocalizationData(local);
    setTool("delete");
    setShowDelModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteLocalization(localizationData.id));
    handleCancel();
  };

  const handleCancel = () => {
    setShowDelModal(false);
    setShowAddEditModal(false);
    setLocalizationData(initialLocalizationData);
    setTool(null);
    setErrors([]);
  };

  return (
    <div className="h-full w-full">
      {showDelModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmDelete}
          confirmBtnText=""
          confirmBtnType="danger"
        >
          <div className="flex flex-col gap-4">
            <h1>
              You are about to delete the "{localizationData.key}" localization
            </h1>
          </div>
        </ConfirmationModal>
      ) : null}
      {showAddEditModal ? (
        <ConfirmationModal
          onBack={handleCancel}
          onConfirm={handleConfirmAddEdit}
          confirmBtnText={tool == "add" ? "Save" : "Update Localization"}
          confirmBtnType="success"
        >
          <div className="flex flex-col gap-1">
            <span className="mb-1 font-bold">
              {tool == "add" ? "Add New Localization" : "Update Localization"}
            </span>
            <div className="flex justify-between gap-2">
              <div className="flex flex-auto flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Key
                </label>
                <input
                  type="text"
                  name="key"
                  value={localizationData.key}
                  onChange={handleOnChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
                />
              </div>
              <div className="flex flex-auto flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Value
                </label>
                <input
                  type="text"
                  name="value"
                  value={localizationData.value}
                  onChange={handleOnChange}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
                />
              </div>
            </div>
            <div className="flex flex-auto flex-col items-start">
              {errors.map((error) => (
                <p key={Math.random()} className="text-sm text-red-500">
                  {error}
                </p>
              ))}
            </div>
          </div>
        </ConfirmationModal>
      ) : null}


      <div className="w-full h-full">
        <div className="flex justify-end p-2 items-center gap-2">
          <div className="w-auto">
            <button
              disabled={loading}
              onClick={removeAllFilters}
              className={`inline-flex justify-center items-center w-auto max-w-40 rounded-lg border-[1px] border-dashed border-red-400 shadow-sm px-2 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 hover:border-red-600 focus:outline-none focus:ring-2 focus:ring-red-500`}
              type="button"
            >
              <MdFilterAltOff className='w-4 h-4' />
              {"Clear Filters"}
            </button>
          </div>
          <div className="w-auto">
            <button
              disabled={loading}
              onClick={() => getFilteredLocalizations(true)}
              className={`inline-flex justify-center items-center w-auto max-w-40 rounded-lg border-[1px] border-dashed border-green-400 shadow-sm px-2 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 hover:border-green-600 focus:outline-none focus:ring-2 focus:ring-green-500`}
              type="button"
            >
              <CiFilter className='w-4 h-4' />
              {"Apply Filters"}
            </button>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-1 mb-1 bg-white border border-indigo-100 rounded-md">
          {/* Key, Value */}
          <div className="flex flex-col md:flex-row justify-between gap-2">
            <div className='w-full'>
              <input
                value={filters.key}
                onChange={(e) => handleOnChangeFilter('key', e.target.value)}
                type="text" name="key" placeholder="key"
                className="w-full h-full px-2 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>
            <div className='w-full'>
              <input
                value={filters.value}
                onChange={(e) => handleOnChangeFilter('value', e.target.value)}
                type="text" name="value" placeholder="value"
                className="w-full h-full px-2 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
            </div>
          </div>
        </div>
      </div>

      <LocalizationsTableCard
        localizations={localizations}
        onClickAddNew={onClickAddNew}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
      />
      <Pagination
        page={filters.page}
        setPage={(page) => setFilters(prev => ({ ...prev, page }))}
        limit={filters.limit}
        totalCount={localizationsCount}
      />
    </div>
  );
};

export default Localizations;
