import { setAlert } from "actions/alert/alert.actions";
import { LANGUAGE_STATUS } from "constants/language.constants";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertTypes } from "../../../reducers/alert/alert.reducer";
import ConfirmationModal from "../components/ConfirmationModal";
import HeadlessSelect from "../components/headless/select.headless";
import Localizations from "../localizations";
import FormFileUploader from "../components/fileUploader";
import convertToFormData from "utils/ConvertToFormData";
import { IMAGE_SIZES } from "constants/app.constants";
import { getLanguages } from "actions/languages/languages.actions";
import { FORM_TYPE } from "constants/app.constants";
import TabSelector from "../components/TabSelector";
import CardHeader from "../components/cardHeader";
import { MODELS } from "constants/activityLog.constants";
import ActivitylogsModal from "../components/activitylogsModal";

const initialMultiLangData = {
  name: "",
};

const initialLanguageData = {
  name: "",
  code: "",
  flag_image: "",
  isRTL: false,
  locale: "",
  status: LANGUAGE_STATUS.ACTIVE,
};

const LanguageForm = ({ formType, initialFormData, handleFormSubmit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { loading, languages, languageMultiLang } = useSelector(
    (state) => state.language
  );
  const [languageData, setLanguageData] = useState(initialLanguageData);
  const [multiLangData, setMultiLangData] = useState(initialMultiLangData);
  const [lang_id, setLang_id] = useState(null);
  const [showLogsModal, setShowLogsModal] = useState(false);

  useEffect(() => {
    if (!initialFormData) return;
    setLanguageData((prevData) => ({ ...prevData, ...initialFormData }));
  }, [initialFormData]);

  useEffect(() => {
    dispatch(getLanguages(1, 1000));
  }, []);

  useEffect(() => {
    if (!lang_id) return;
    const activeLang = languages.find((lang) => lang.id == lang_id);
    const langData = languageMultiLang?.[activeLang?.code];
    setMultiLangData({ ...initialMultiLangData, ...langData });
  }, [lang_id]);

  const onChangeFormData = (name, value) => {
    if (lang_id) {
      setMultiLangData({ ...multiLangData, [name]: value });
      return;
    }
    setLanguageData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    const errors = [];
    // validate data
    Object.entries(languageData).forEach(([key, value]) => {
      if (value === "") errors.push(`${key} is required`);
    });
    if (errors.length) {
      return errors.forEach((error) =>
        dispatch(setAlert(error, AlertTypes.ERROR))
      );
    }

    let payload = { ...languageData };
    // delete the properties from payload that are not allowed to send
    Object.keys(payload).forEach((key) => {
      if (initialLanguageData[key] == undefined) {
        delete payload[key];
      }
    });

    if (lang_id) payload = { ...multiLangData, lang_id };
    const formData = convertToFormData(payload);
    handleFormSubmit(formData);
  };

  return (
    <div className="h-full w-full bg-white p-4">
      {" "}
      {/* Activity logs modal */}
      {formType === FORM_TYPE.UPDATE && showLogsModal ? (
        <ActivitylogsModal
          entityName={MODELS.LANGUAGE}
          entityId={languageData.id}
          onBack={() => setShowLogsModal(false)}
        />
      ) : null}
      {showCancelModal ? (
        <ConfirmationModal
          onBack={() => setShowCancelModal(false)}
          onConfirm={() => navigate("/languages")}
          confirmBtnText=""
        >
          <div className="flex flex-col gap-4">
            <h1>Cancel Confirmation</h1>
            <p className="text-sm text-gray-700">
              All the changes will be lost and you'll be redirected to languages
              page
            </p>
          </div>
        </ConfirmationModal>
      ) : null}
      <CardHeader
        label={`${formType} Language`}
        buttons={[
          {
            text: "Back",
            onClick: () => navigate("/languages"),
            variant: "dark",
          },
          ...(formType === FORM_TYPE.UPDATE
            ? [
                {
                  text: "View Logs",
                  onClick: () => setShowLogsModal(true),
                  variant: "primary",
                },
              ]
            : []),
        ]}
      />
      {/* Add/Edit Language */}
      <div className="flex flex-col gap-3 divide-y-2">
        {/* Tabs */}
        {formType == FORM_TYPE.UPDATE ? (
          <div className="">
            <TabSelector
              selectors={[
                { name: "Standard", value: null },
                ...languages.map((lang) => ({
                  icon: lang.flag_image,
                  name: lang.name,
                  value: lang.id,
                })),
              ]}
              activeSelector={lang_id}
              setActiveSelector={(value) => setLang_id(value)}
            />
          </div>
        ) : null}

        <div className="flex flex-col gap-4">
          {!(formType == FORM_TYPE.UPDATE && lang_id) ? (
            <Fragment>
              {/* image */}
              <div className="flex h-full w-full flex-col items-start gap-1">
                <label
                  for="image"
                  className="mb-1 block text-base font-medium text-gray-800"
                >
                  Flag Image
                </label>
                <FormFileUploader
                  size={IMAGE_SIZES.LANGUAGE_FLAG}
                  image={languageData.flag_image}
                  handleUpload={(acceptedFiles) =>
                    onChangeFormData("flag_image", acceptedFiles[0])
                  }
                />
              </div>

              <div className="flex flex-col justify-between gap-2">
                <div className="flex flex-auto flex-col items-start">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="English"
                    value={languageData.name}
                    onChange={(e) => onChangeFormData("name", e.target.value)}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
                  />
                </div>
              </div>

              <div className="flex justify-between gap-2">
                <div className="flex flex-auto flex-col items-start">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Code
                  </label>
                  <input
                    type="text"
                    name="code"
                    placeholder="en"
                    value={languageData.code}
                    onChange={(e) => onChangeFormData("code", e.target.value)}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
                  />
                </div>
                <div className="flex flex-auto flex-col items-start">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Locale
                  </label>
                  <input
                    type="text"
                    name="locale"
                    placeholder="en-US"
                    value={languageData.locale}
                    onChange={(e) => onChangeFormData("locale", e.target.value)}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
                  />
                </div>
              </div>
              <div className="flex justify-between gap-2">
                <div className="flex flex-auto flex-col items-start">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Writing Stype
                  </label>
                  <HeadlessSelect
                    title={"Select Style"}
                    options={["RTL", "LTR"]}
                    selected={languageData.isRTL ? "RTL" : "LTR"}
                    onSelect={(value) =>
                      onChangeFormData("isRTL", value === "RTL")
                    }
                  />
                </div>
                <div className="flex flex-auto flex-col items-start">
                  <label className="mb-1 block text-base font-medium text-gray-800">
                    Status
                  </label>
                  <HeadlessSelect
                    title={"Select Status"}
                    options={[LANGUAGE_STATUS.ACTIVE, LANGUAGE_STATUS.INACTIVE]}
                    selected={languageData.status}
                    onSelect={(value) => onChangeFormData("status", value)}
                  />
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="flex flex-col justify-between gap-2">
              <div className="flex flex-auto flex-col items-start">
                <label className="mb-1 block text-base font-medium text-gray-800">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="English"
                  value={multiLangData.name}
                  onChange={(e) => onChangeFormData("name", e.target.value)}
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-green-700 focus:outline-none focus:ring-green-700"
                />
              </div>
            </div>
          )}

          <div className="flex justify-between">
            <button
              disabled={loading}
              type="button"
              onClick={() => handleSubmit()}
              className={`
                rounded-md px-6 py-2 font-semibold text-white focus:outline-none
                ${
                  loading
                    ? "bg-indigo-400 hover:bg-indigo-400"
                    : "bg-indigo-500 hover:bg-indigo-600"
                }
              `}
            >
              {loading ? "Saving..." : "Submit"}
            </button>
            <button
              type="button"
              onClick={() => setShowCancelModal(true)}
              className="rounded-md bg-gray-500 px-6 py-2 font-semibold text-white hover:bg-gray-800 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </div>
        {/* Localizations */}
        {initialFormData && initialFormData.id && !lang_id ? (
          <Localizations language_id={initialFormData.id} />
        ) : null}
      </div>
    </div>
  );
};

export default LanguageForm;
