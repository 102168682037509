import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  facilitiesCount: 0,
  facilities: [],
  facility: null,
  facilityMultiLang: {},
  loading: false,
};

export const facilityReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_FACILITIES:
      return {
        ...state,
        facilitiesCount: payload?.meta?.total_documents || 0,
        facilities: payload.facilities,
        loading: false,
      };
    case ActionTypes.SET_FACILITY:
      return {
        ...state,
        facility: payload.facility,
        facilityMultiLang: payload?.multiLangData || {},
        loading: false,
      };
    case ActionTypes.DELETE_FACILITY:
      return {
        ...state,
        facilitiesCount: state.facilitiesCount - 1,
        facilities: state.facilities.filter(
          (facility) => facility.id !== payload
        ),
        loading: false,
      };
    case ActionTypes.SET_FACILITY_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_FACILITY_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
