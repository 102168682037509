import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getConfiguration = () => {
  return RestClient.Get(`${API_URL.GET_CONFIGURATION}`);
};

const updateConfiguration = (payload) => {
  return RestClient.Put(`${API_URL.UPDATE_CONFIGURATION}`, payload);
};

const exportedObject = {
  getConfiguration,
  updateConfiguration,
};

export default exportedObject;
