import * as ActionTypes from "../../actions/actionTypes";

const INITIAL_STATE = {
  notificationsCount: 0,
  notifications: [],
  notification: null,
  loading: false,
};

export const pushNotificationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_PUSH_NOTIFICATIONS:
      return {
        ...state,
        notificationsCount: payload?.meta?.total_documents || 0,
        notifications: payload.pushNotifications,
        loading: false,
      };
    case ActionTypes.SET_PUSH_NOTIFICATION:
      return {
        ...state,
        notification: payload.pushNotification,
        loading: false,
      };
    case ActionTypes.SET_PUSH_NOTIFICATION_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.RESET_PUSH_NOTIFICATION_STATE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
