import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { MdCheck } from "react-icons/md";
import { HiOutlineChevronUpDown } from "react-icons/hi2";
import { Fragment, useEffect, useRef, useState } from "react";

export default function HeadlessSelectObject({
  multiSelect,
  disable,
  label,
  title,
  options,
  selected,
  onSelect,
  search = true,
}) {
  // console.log({ options });
  const getLabel = (value) => {
    const option = options.find((opt) => opt.value == value);
    return option?.name || "Select";
  };

  const isActive = (value) => {
    if (!multiSelect) return selected === value;
    return selected.includes(value);
  };

  const handleSelect = (value) => {
    if (!multiSelect) return onSelect(value);
    let temp_values = [];
    if (selected.includes(value))
      temp_values = selected.filter((val) => val !== value);
    else temp_values = [...selected, value];
    onSelect(temp_values);
  };

  const [searchQuery, setSearchQuery] = useState("");

  // Filtering Options
  const filteredOptions = options.filter((option) =>
    option?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  // console.log({ filteredOptions });

  // Scroll-to-Match
  const optionsRef = useRef(null);

  useEffect(() => {
    if (searchQuery) {
      const firstMatch = optionsRef.current?.querySelector(
        `.block.truncate:first-child` // Adjust selector as needed
      );
      if (firstMatch) {
        firstMatch.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [searchQuery]);

  return (
    <div className="w-full">
      <Listbox value={selected} onChange={handleSelect}>
        {label ? (
          <div className="mb-1 block text-base font-medium text-gray-800">
            <Listbox.Label>{label}</Listbox.Label>
          </div>
        ) : null}
        <div className="relative w-full rounded-md border border-gray-300">
          <ListboxButton
            disabled={disable}
            className={`w-full rounded-md border-[0px] ${
              disable ? "border-gray-50" : "border-gray-300"
            } px-2 py-2 text-sm focus:border-gray-500 focus:outline-none`}
          >
            <span
              className={`thin-scrollbar flex w-full max-w-full items-center overflow-x-auto text-gray-700 ${
                (multiSelect && multiSelect.length) ||
                (!multiSelect && selected)
                  ? "text-gray-900"
                  : ""
              }`}
            >
              {multiSelect && selected?.length ? (
                <div className="flex w-full items-center justify-start">
                  {selected.map((value, index) => (
                    <p key={Math.random()} className="flex w-auto text-nowrap">
                      {(index > 0 ? ", " : "") + getLabel(value)}
                    </p>
                  ))}
                </div>
              ) : (
                <p className="flex w-full flex-none text-nowrap">
                  {!multiSelect && selected ? getLabel(selected) : title}
                </p>
              )}
            </span>
            <span className="w-10" />
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <HiOutlineChevronUpDown
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <ListboxOptions className="thin-scrollbar ring-black/5 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white p-[2px] text-sm ring-1 focus:outline-none">
            {search && (
              <div className="sticky top-0 z-10  bg-white p-1">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className=" w-full rounded-md  border-[1px] border-gray-300 px-[6px] py-[6px] focus:border-indigo-500 focus:outline-none"
                />
              </div>
            )}

            {filteredOptions.map((option, index) => (
              <ListboxOption
                transition
                key={index}
                className="relative cursor-default select-none py-2 pl-10 pr-4  text-gray-900 hover:bg-gray-100"
                value={option.value}
              >
                <>
                  <span
                    className={`block truncate ${
                      isActive(option.value) ? "font-medium" : "font-normal"
                    }`}
                  >
                    {option.name}
                  </span>
                  {isActive(option.value) ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                      <MdCheck className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              </ListboxOption>
            ))}
          </ListboxOptions>
          {/* </Transition> */}
        </div>
      </Listbox>
    </div>
  );
}
