import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "../components/cardHeader";
import { updateTicket } from "actions/tickets/tickets.action";
import Loader from "components/loaderSpinner/Loader";
import { getCustomerClassification } from "actions/customerClassification/customerClassification.actions";
import ClassificationForm from "./classificationForm";
import { updateClassification } from "actions/customerClassification/customerClassification.actions";
import { FORM_TYPE } from "constants/app.constants";
import { updateMultiLangClassification } from "actions/customerClassification/customerClassification.actions";

const UpdateClassification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { classificationId } = useParams();
  const { customerClassification, loading } = useSelector(
    (state) => state.customerClassification
  );

  useEffect(() => {
    dispatch(getCustomerClassification(classificationId));
  }, [classificationId]);

  const handleSubmit = (formData) => {
    if (formData.get('lang_id')) {
      dispatch(updateMultiLangClassification(classificationId, formData, navigate))
      return
    }
    dispatch(updateClassification(classificationId, formData, navigate))
  }

  if (!classificationId || customerClassification?.id !== classificationId)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="h-full w-full">
      <ClassificationForm
        formType={FORM_TYPE.UPDATE}
        initialFormData={customerClassification}
        handleFormSubmit={handleSubmit}
      />
    </div>
  );
};

export default UpdateClassification;
