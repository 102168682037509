import { API_URL } from "utils/ApiRoutes";
import RestClient from "../RestClient";

const getFaqs = (page = 1, limit = 50) => {
  return RestClient.Get(`${API_URL.GET_FAQS}?page=${page}&limit=${limit}`);
};

const getFaq = (faqId) => {
  return RestClient.Get(`${API_URL.GET_FAQ}/${faqId}`);
};

const createFaq = (payload) => {
  return RestClient.Post(API_URL.CREATE_FAQ, payload);
};

const updateFaq = (faqId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_FAQ}/${faqId}`, payload);
};

const updateMultiLangFaq = (faqId, payload) => {
  return RestClient.Put(`${API_URL.UPDATE_MULTI_LANG_FAQ}/${faqId}`, payload);
};

const deleteFaq = (faqId) => {
  return RestClient.Delete(`${API_URL.DELETE_FAQ}/${faqId}`);
};

const exportedObject = {
  getFaqs,
  getFaq,
  createFaq,
  updateFaq,
  updateMultiLangFaq,
  deleteFaq,
};

export default exportedObject;
